import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import {
  generateStateToProps,
  generateDispatchToProps,
} from 'flux/utils/orderFluxGenerator'

//** component
import Component from './index'

const mapStateToProps = state => ({
  ...generateStateToProps(state),
})

const mapDispatchToProps = dispatch => ({
  ...generateDispatchToProps(dispatch),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Component),
)
