import moment from 'moment'
import 'moment/locale/ru'

moment.locale('ru', {
    months: {
        format: 'Января_Февраля_Марта_Апреля_Мая_Июня_Июля_Августа_Сентября_Октября_Ноября_Декабря'.split('_'),
        standalone: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split('_'),
    },
    monthsShort: {
        format: 'Янв._Февр._Мар._Апр._Мая_Июня_Июля_Авг._Сент._Окт._Нояб._Дек.'.split('_'),
        standalone: 'Янв._Февр._Март_Апр._Май_Июнь_Июль_Авг._Сент._Окт._Нояб._Дек.'.split('_'),
    },
    weekdays: 'Воскресенье_Понедельник_Вторник_Среда_Четверг_Пятница_Суббота'.split('_'),
    weekdaysShort: 'Вс_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
    weekdaysMin: 'Вс_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
})

export default moment


const dayweek_conjugation = {
    'понедельник': 'понедельник',
    'вторник': 'вторник',
    'среда': 'среду',
    'четверг': 'четверг',
    'пятница': 'пятницу',
    'суббота': 'субботу',
    'воскресенье': 'воскресенье',
}
export {dayweek_conjugation}

const START_WORK_HOURS = 8
const START_WORK_MINUTES = 0
const END_WORK_HOURS = 23
const END_WORK_MINUTES = 0

export function getMinTimeForPreOrder(sd = moment()) {
    const dt = moment().add(1, 'd')

    dt.seconds(0)
    dt.milliseconds(0)

    const minutes = dt.minutes()
    
    dt.hour(START_WORK_HOURS)
    dt.minutes(START_WORK_MINUTES)

/*    const isNotThisDay = sd.year() !== dt.year() || sd.month() !== dt.month() || sd.date() !== dt.date()
    if (isNotThisDay) {
        dt.hour(START_WORK_HOURS)
        dt.minutes(START_WORK_MINUTES)

    } else {
        if (minutes > 30) {
            dt.minute(0)
            dt.hour(dt.hour() + 1)
        } else if (minutes < 30) {
            dt.minute(30)
        }
    }*/

    return dt
}

export function getMaxTimeForPreOrder(sd) {
    const dt = moment(sd)

    dt.hours(END_WORK_HOURS)
    dt.minutes(END_WORK_MINUTES)
    dt.seconds(0)
    dt.milliseconds(0)

    return dt
}
