import React, {useEffect} from 'react'

import Footer from 'app/Elements/Footer'
import ParagraphBlock from 'app/Elements/ParagraphBlock'
import SmartLink from 'app/Elements/SmartLink'
import Button from 'app/Elements/Button'

import css from './index.styl'

const p1Title = 'ОСНОВЫ ТАРИФНОЙ ПОЛИТИКИ ЕЖЭК'
const p1Items = [
  'ЕЖЭК занимает нишу мелких бытовых заказов «для любого кошелька», которые раньше не размещались или не выполнялись из-за сложности путей размещения, неудобных сроков или времени исполнения, низкого качества работ или дороговизны.',
  'Поэтому стоимость минимального заказа составляет всего 1 500 рублей – дешевле маникюра, букета роз или бака бензина.',
  'Чтобы прибыльно выполнять <u>мелкие заказы с высоким качеством и экспертной поддержкой</u> мастеров и заказчиков, ЕЖЭК необходимо обслуживать большой объём таких заказов, а именно, тысячи заказов в день.',
  'Чтобы успевать выполнить необходимый объем заказов с учетом транспортных реалий такого крупного населенного пункта, как г. Москва (большие расстояния, пробки, нерегулярное хождение транспорта) и вытекающей из этого непредсказуемости времени прибытия Мастера к месту выполнения заказа с удаленной территории, в основе бизнеса ЕЖЭК лежат <u>срочные заказы</u>, выполняемые Мастерами, прибывающими с соседней территории в течение дня, когда был размещён заказ.',
  '<u>Предварительная запись</u> по желанию Заказчика тоже возможна на любое время, начиная с утра дня, следующего за днем размещения заказа. В настоящее время стоимость заказа по предварительной записи не отличается от стоимости срочного заказа.',
  'В случае перегрузки Мастеров из-за большого числа одновременно размещаемых заказов и отсутствия свободного Мастера для прибытия на очередной срочный заказ в течение дня, этот заказ может вынужденно быть переведен в статус предварительного заказа. В этом случае система предложит Заказчику выбрать время, наиболее удобное для выполнения заказа. При отсутствии свободного Мастера в нужное время, заказ перейдет в статус отложенного и будет передан для мониторинга Контактному центру ЕЖЭК.',
  'ЕЖЭК гарантирует <u>высокое качество услуг</u>. В исключительном случае, если Заказчик недоволен качеством полученной услуги, Мастер в срочном порядке <u>бесплатно устранит недостатки</u>.',
  'ЕЖЭК гарантирует <u>безопасность</u> Заказчикам и Мастерам, так как мастера проходят тщательный отбор. В случае возникновения спорной или конфликтной ситуации в ходе оказания услуг, в нее немедленно вмешается <u>контактный центр ЕЖЭК</u>, который обеспечит урегулирование конфликта оптимальным образом.',
  'При возникновении такой исключительной ситуации Мастер или Заказчик могут связаться с Контактным центром ЕЖЭК через приложение, или по телефону, указанному на сайте и в приложении в разделе Контакты.',
  'Залогом устойчивого развития ЕЖЭК является системное построение сообщества Заказчиков и Мастеров. Поэтому для нас очень важна <u>личная рекомендация</u> сервиса уже подключившихся пользователей новым Заказчикам и Мастерам.',
  'Услуги и тарифная политика ЕЖЭК описаны более подробно в последующих разделах Кодекса ЕЖЭК.',
]

export default function CodexTariffsPolicies(props) {
  useEffect(() => {
    document.title = 'ОСНОВЫ ТАРИФНОЙ ПОЛИТИКИ ЕЖЭК'
  }, [props.title])

  return (
    <div className={css.wrap}>
      <div className={css.content}>
        <ParagraphBlock title={p1Title} items={p1Items} className={css.p1} />
      </div>
      <Footer />
    </div>
  )
}
