import fetch from 'fetch'

export default ({ id, token, body }) => {
  return fetch({
    url: `/client/order/${id}/confirm-master/`,
    method: 'POST',
    body: { ...body },
    token,
  })
}
