import React, {Component} from 'react'

import Icon from 'app/Elements/Icon'
import SmartLink from 'app/Elements/SmartLink'

import cx from 'classnames'
import css from './index.styl'

export default class MobileMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {isOpen: false}
  }

  toggleOpen = () => {
    const nextState = !this.state.isOpen
    if (nextState) {
      document.getElementById('app').style.overflow = 'hidden'
    } else {
      document.getElementById('app').style.overflow = ''
    }
    this.setState({isOpen: nextState})
  }

  render() {
    const {pathname, size} = this.props
    const {isOpen} = this.state

    return (
      <div>
        <Icon
          name="menu"
          size={size || 36}
          onClick={this.toggleOpen}
        />
        <div className={cx(css.overlay, {[css.opened]: isOpen})} onClick={this.toggleOpen}>
          <div className={css.menuItems}>
            <div className={css.menuButtonWrap}>
              <div className={css.menuButton}>
                <Icon
                  name="cross"
                  size={24}
                  className={css.closeIcon}
                  onClick={this.toggleOpen}
                />
              </div>
            </div>
            <SmartLink
              className={css.menuButtonWrap}
              to={`/`}
            >
              <div className={cx(css.menuButton, {[css.active]: pathname === '/'})}>ЗАКАЗ МАСТЕРА</div>
            </SmartLink>
            <SmartLink
              className={css.menuButtonWrap}
              to={'/about/'}
            >
              <div className={cx(css.menuButton, {[css.active]: pathname === '/about/'})}>О НАС</div>
            </SmartLink>
            <SmartLink
              className={css.menuButtonWrap}
              to={'/customers/'}
            >
              <div className={cx(css.menuButton, {[css.active]: pathname === '/customers/'})}>ЗАКАЗЧИКАМ</div>
            </SmartLink>
            <SmartLink
              className={css.menuButtonWrap}
              to={'/masters/'}
            >
              <div className={cx(css.menuButton, {[css.active]: pathname === '/masters/'})}>МАСТЕРАМ</div>
            </SmartLink>
            <SmartLink
              className={css.menuButtonWrap}
              to={'/faq/'}
            >
              <div className={cx(css.menuButton, {[css.active]: pathname === '/faq/'})}>FAQ</div>
            </SmartLink>
            <SmartLink
              className={css.menuButtonWrap}
              to={'/codex/'}
            >
              <div className={cx(css.menuButton, {[css.active]: pathname === '/codex/'})}>КОДЕКС</div>
            </SmartLink>
            <SmartLink
              className={css.menuButtonWrap}
              to={'/contacts/'}
            >
              <div className={cx(css.menuButton, {[css.active]: pathname === '/contacts/'})}>КОНТАКТЫ</div>
            </SmartLink>
          </div>
        </div>
      </div>
    )
  }
}
