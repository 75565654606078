import fetch from 'fetch'

export default (token) => {
  return fetch({
    url: '/client/auth/fast/',
    method: 'POST',
    body: {
      token,
    }
  })
}
