import React from 'react'
import T from 'prop-types'

import Icon from 'app/Elements/Icon'

import cx from 'classnames'
import css from './index.styl'

function handleChange(e, props) {
  const { checked } = e.target
  const { handleChange } = props

  handleChange(checked)
}

export default function Checkbox(props) {
  const { id, name, className, checked, disabled, label, error } = props

  const _id = id || name || ''

  return (
    <div className={cx(css.container, className)}>
      <input
        type={'checkbox'}
        checked={checked ? 'checked' : ''}
        disabled={disabled}
        onChange={(e) => handleChange(e, props)}
        className={cx(css.input, disabled && css.disabled)}
        id={_id}
        name={name || ''}
      />

      <div className={cx(css.iconWrap, error && !checked && css.iconWrapError)}>
        <Icon name={'checkmark'} size={14} className={css.icon} />
      </div>

      {label && (
        <label htmlFor={_id} className={css.label}>
          {label}
        </label>
      )}
    </div>
  )
}

Checkbox.propTypes = {
  name: T.string.isRequired,
  id: T.oneOfType([T.string, T.number]),
  className: T.string,
  handleChange: T.func,
  checked: T.bool,
  label: T.oneOfType([T.string, T.node]),
}
Checkbox.defaultProps = {
  handleChange: () => {},
  checked: false,
  className: '',
  label: '',
}
