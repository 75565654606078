import React, { PureComponent } from 'react'
import T from 'prop-types'
import NotificationsContainer from 'react-notification-system-redux'
import Icon from 'app/Elements/Icon'
import cx from 'classnames'
import css from './Notifications.styl'

function completeNotification(notification) {
  const { title, message, level } = notification

  let iconName = null
  switch (level) {
    case 'error':
      iconName = 'error'
      break
    case 'success':
      iconName = 'tick'
      break
    case 'warning':
      iconName = 'warning'
      break
    case 'info':
      iconName = 'info'
      break
  }

  const classLevelIcon = cx({
    [css.levelIcon]: true,
    [css.dark]: level === 'warning',
  })

  let notificationMessage = `${title + (message ? `. ${message}` : '')}`

  const children = (
    <div className={css.customNotification}>
      {iconName && <Icon className={classLevelIcon} size={28} name={iconName} />}
      <p className={css.notificationMessage}>{notificationMessage}</p>
      <Icon className={css.closeIcon} size={28} name="close" />
    </div>
  )
  return {
    ...notification,
    title: null,
    message: null,
    children,
  }
}

export default class Notifications extends PureComponent {
  static propTypes = {
    notifications: T.array.isRequired,
  }

  render() {
    const { notifications } = this.props

    return <NotificationsContainer notifications={notifications.map((n) => completeNotification(n))} />
  }
}
