import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {Link} from 'react-router-dom'
import {HashLink} from 'react-router-hash-link'

//** helpers
import {scrollToElement} from 'helpers/scrollTo'

//** styles
import cx from 'classnames'
import css from './index.styl'

const isExternal = to => {
  return (
    to &&
    (to.startsWith('http') || to.startsWith('mailto') || to.startsWith('tel') || to.startsWith('sms') || to.startsWith('fb-messenger') || to.startsWith('viber'))
  )
}

const isHash = to => {
  return to && to.includes('#')
}

const isToHttp = to => {
  return to && to.startsWith('http')
}

export default class SmartLink extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    to: PropTypes.string,
    onClick: PropTypes.func,
    theme: PropTypes.oneOf(['orange', 'green', 'dark', 'white']),
    newTab: PropTypes.bool,
    disabled: PropTypes.bool,
    isUnderlined: PropTypes.bool,
  }

  static defaultProps = {
    onClick: () => {},
    newTab: false,
    theme: 'orange',
    isUnderlined: false,
  }

  render() {
    const {
      children,
      className,
      to,
      onClick,
      newTab,
      disabled,
      isUnderlined,
      theme,
      ...otherProps
    } = this.props

    if (newTab) {
      otherProps.target = '_blank'
    }

    const cl = cx({
      [css.link]: true,
      [css.underlined]: isUnderlined,
      [css[theme]]: theme,
      [className]: className,
    })

    let Element = 'div'

    if (!!to) {
      Element = isExternal(to)
        ? ExternalLink
        : isHash(to)
          ? (props) => <HashLink
              scroll={ (el) => scrollToElement(el) } { ...props } />
          : Link
    }

    return disabled ? (
      <span className={cl} {...otherProps}>
        {children}
      </span>
    ) : (
      to ? (
        <Element to={to} className={cl} {...otherProps}>
          {children}
        </Element>
      ) : (
        <Element onClick={onClick} className={cl} {...otherProps}>
          {children}
        </Element>
      )
    )
  }
}

class ExternalLink extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
    rel: PropTypes.string,
    className: PropTypes.string,
  }

  static defaultProps = {
    rel: 'noopener noreferrer',
  }

  render() {
    const {
      children,
      to,
      className,
      ...otherProps
    } = this.props

    if (isToHttp(to)) {
      otherProps.target = '_blank'
    }

    const cl = cx({
      [css.link]: true,
      [className]: className,
    })

    return (
      <a href={to} {...otherProps} className={cl}>
        {children}
      </a>
    )
  }
}


export class LinkAnchor extends Component {
  static propTypes = {
    number: PropTypes.number,
    text: PropTypes.node,
    id: PropTypes.string,
  }

  render() {
    const {id, text, number} = this.props

    return (
      <div className={css.anchor} id={id}>
        <span className={css.number}>{number}</span>
        <span className={css.text}>{text}</span>
      </div>
    )
  }
}