import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { clearDataOrder } from 'flux/actions/order'

import { generateStateToProps, generateDispatchToProps } from 'flux/utils/orderFluxGenerator'

import { getMasterGeoMarkers } from 'flux/actions/order'

import {selectIsMobile, selectIsTablet} from 'flux/selectors/responsive'

//** component
import Component from './index'

const mapStateToProps = (state) => ({
  ...generateStateToProps(state),
  isMobile: selectIsMobile(state),
  isTablet: selectIsTablet(state),
})

const mapDispatchToProps = (dispatch) => ({
  ...generateDispatchToProps(dispatch),
  clearDataOrder: () => dispatch(clearDataOrder()),
  getMasterGeoMarkers: () => dispatch(getMasterGeoMarkers({getAll: true})),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
)
