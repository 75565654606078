import React from 'react'

import Footer from 'app/Elements/Footer'
import ParagraphBlock from 'app/Elements/ParagraphBlock'

import ReviewFeed from 'app/Components/ReviewFeed/index.connector'

import css from './index.styl'


const p1Highlighted = 'Завал на работе, а вечером нужно навести порядок в доме и проверить уроки у детей? А в выходные заняться ремонтом квартиры и поливом сада на даче, и еще помочь родителям? Быт заедает? Постоянно стресс?'
const p1Items = [
  '<b>Тогда ЕЖЭК именно для вас! Цель его создания – помочь вам сэкономить время, энергию и деньги, которые вы теряете из-за неэффективности процесса заказа и оказания бытовых услуг. Это позволит посвятить освободившиеся ресурсы своим близким, творчеству и приключениям, и существенно снизит уровень стресса в вашей повседневной жизни.</b>',
  'Сейчас заказчики размещают заказы на веб-странице ЕЖЭК. Все деньги, полученные нами от заказов, направляются на разработку мобильного приложения ЕЖЭК ДЛЯ ЗАКАЗЧИКОВ, чтобы сделать использование сервиса еще удобнее. Дизайн заказа на веб-странице максимально приближен к его виду в будущем мобильном приложении, чтобы переход на приложение произошёл как можно легче.',
]

const p2Title = 'Наши услуги для вас, если:'
const p2Items = [
  'Вы хотите налаженный быт в доме, но не можете сделать это самостоятельно.',
  'Вы хотите вызывать мастера так же просто, как обычно заказываете такси.',
  'Вы не хотите искать мастера сами, а хотите, чтобы за вас это сделали профессионалы — все мастера ЕЖЭК прошли отбор и тестирование.',
  'Вы — за то, чтобы дать возможность мастерам легально заработать, и за честный труд по справедливым расценкам.',
]

const p3Title = 'Вы можете быть уверены в том, что:'
const p3Items = [
  'Мастера ЕЖЭК находятся с вами по соседству, и они оперативно прибудут на срочный заказ.',
  'Все наши мастера прошли проверку документов, умений и личных качеств, а также одно или несколько практических тестирований.',
  'Мы пристально следим за качеством услуг и активно участвуем в устранении недостатков в случае претензий.',
  'Мы позаботимся о вашей безопасности путем пристального мониторинга действий как мастеров, так и заказчиков.',
]

const p4Title = 'Как это работает?'
const p4Items = [
  'Вы формулируете и размещаете заказ. Обычно — на ближайшее время, как заказ такси. Если вы хотите спланировать свое время на будущее, можете указать другой день и час',
  'Система определяет специализацию мастера и выбирает лучшего из ближайших свободных мастеров с данной специализацией',
  'Вы можете отслеживать перемещения назначенного мастера на веб странице ЕЖЭК',
  'Мастер прибывает к вам, проводит диагностику, начинает работу или отправляется за необходимыми материалами',
  'По окончании работы вы оплачиваете время, фактически потраченное мастером на работу и покупку материалов',
  'Вы выставляете оценку мастеру, которая поможет нам мониторить качество услуг',
]

const p5Title = 'Не верьте нам на слово:'
const p5Items = [
  'Читайте отзывы заказчиков, которым мастера ЕЖЭК уже подарили свободное время и хорошее настроение:',
]

export default function Customers(props) {

  return (
    <div className={css.wrap}>
      <div className={css.content}>
        <ParagraphBlock highlighted={p1Highlighted} items={p1Items} className={css.p1}/>
        <ParagraphBlock title={p2Title} items={p2Items}/>
        <ParagraphBlock title={p3Title} items={p3Items}/>

        <ParagraphBlock title={p5Title} items={p5Items}/>
        <ParagraphBlock items={[<ReviewFeed clients/>]} itemsNoInner/>

        <ParagraphBlock title={p4Title} items={p4Items} itemsByList/>

        <iframe className={css.video} src="https://www.youtube.com/embed/Kkvo2-WJutk" frameBorder="0" allowFullScreen/>
      </div>
      <Footer/>
    </div>
  )
}