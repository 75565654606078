import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { selectStatus } from 'flux/selectors/order'

//** component
import Component from './index'

const mapStateToProps = (state) => ({
  status: selectStatus(state),
})

const mapDispatchToProps = () => ({})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
)
