import React, {Fragment} from 'react'
import T from 'prop-types'
import renderRoutes from 'react-router-config/renderRoutes'
import { matchRoutes } from 'react-router-config'

import SmartLink from 'app/Elements/SmartLink'
import MobileMenu from 'app/Elements/MobileMenu'
import Footer from 'app/Elements/Footer'

import footer from 'app/Assets/footer.png'

import css from './index.styl'
import cx from 'classnames'

export const buttons = [
  {
    iconName: 'settings',
    type: 'settings',
    title: 'Починить',
    isDefault: true,
  },
  {
    iconName: 'shower',
    type: 'shower',
    title: 'Установить',
  },
  {
    iconName: 'shelve',
    type: 'shelve',
    title: 'Собрать',
  },
  {
    iconName: 'cargo',
    type: 'cargo',
    title: 'Переставить',
  },
  {
    iconName: 'hover',
    type: 'hover',
    title: 'Убрать',
  },
  {
    iconName: 'plant',
    type: 'plant',
    title: 'Посадить',
  },
]

import pageConnector from 'app/Pages/utils/pageConnector'
import clientMetadataLoader from 'flux/loaders/clientMetadata'
import newOrderSlugs from 'app/Layout/OrderInfoWrap/slugs'

export const defineTitle = (type) => {
  const foundButton = buttons.find((button) => button.type === type)
  if (foundButton) return foundButton

  return buttons.find((button) => button.isDefault)
}

function OrderWrap(props) {
  const { route, elem: Element, location: { pathname } = {}, ...restProps } = props

  let shouldRenderImage = true
  let shouldRenderTopMenu = false
  let wrapMobile = false

  const newOrderCustom = pathname.match(/\/order\/custom\/(.+?)(\/|$)/)

  const newOrderLabels = (newOrderCustom && newOrderSlugs.find(s => newOrderCustom[1] == s.slug)) || {
    slogan: ' — онлайн вызов мастеров и помощников по дому'
  }

  const matchedRoute = matchRoutes(route.routes, pathname) || []

  if (matchedRoute.length > 0) {
    if (typeof (matchedRoute[0].route || {}).shouldRenderImage === 'boolean') {
      shouldRenderImage = (matchedRoute[0].route || {}).shouldRenderImage
    }
    if (typeof (matchedRoute[0].route || {}).shouldRenderTopMenu === 'boolean') {
      shouldRenderTopMenu = (matchedRoute[0].route || {}).shouldRenderTopMenu
    }
    if (typeof (matchedRoute[0].route || {}).wrapMobile === 'boolean') {
      wrapMobile = (matchedRoute[0].route || {}).wrapMobile
    }
  }

  return (
    <Element className={cx(css.main, shouldRenderTopMenu ? css.mainFull : null)}>
      {!shouldRenderTopMenu && <div className={css.topline} />}
      {shouldRenderTopMenu && (
        <Fragment>
          <div className={css.topMenu}>
            <div className={css.leftPart}>
              <SmartLink
                className={css.logoWrap}
                to={'/'}
              >
                <img src="/assets/logo.svg" alt={'ezhek-logo'} className={css.logo} />
              </SmartLink>
              <div className={css.logoText}>
                {newOrderLabels.slogan || ' — онлайн вызов мастеров и помощников по дому'}
                <SmartLink to={`tel:${'+74951985495'}`} className={css.logoTextPhone}>{'+7 (495) 1985 495'}</SmartLink>
              </div>
            </div>
            <div className={css.rightPart}>
              <SmartLink
                className={css.menuButtonWrap}
                to={'/'}
              >
                <div className={cx(css.menuButton, {[css.active]: pathname === '/'})}>ЗАКАЗ МАСТЕРА</div>
              </SmartLink>
              <SmartLink
                className={css.menuButtonWrap}
                to={'/about/'}
              >
                <div className={cx(css.menuButton, {[css.active]: pathname === '/about/'})}>О НАС</div>
                </SmartLink>
              <SmartLink
                className={css.menuButtonWrap}
                to={'/customers/'}
              >
                <div className={cx(css.menuButton, {[css.active]: pathname === '/customers/'})}>ЗАКАЗЧИКАМ</div>
                </SmartLink>
              <SmartLink
                className={css.menuButtonWrap}
                to={'/masters/'}
              >
                <div className={cx(css.menuButton, {[css.active]: pathname.includes('/masters/')})}>МАСТЕРАМ</div>
                </SmartLink>
              <SmartLink
                className={css.menuButtonWrap}
                to={'/faq/'}
              >
                <div className={cx(css.menuButton, {[css.active]: pathname === '/faq/'})}>FAQ</div>
                </SmartLink>
              <SmartLink
                className={css.menuButtonWrap}
                to={'/codex/'}
              >
                <div className={cx(css.menuButton, {[css.active]: pathname === '/codex/'})}>КОДЕКС</div>
                </SmartLink>
              <SmartLink
                className={css.menuButtonWrap}
                to={'/contacts/'}
              >
                <div className={cx(css.menuButton, {[css.active]: pathname === '/contacts/'})}>КОНТАКТЫ</div>
                </SmartLink>
            </div>
            <div className={css.menuPart}>
              <MobileMenu pathname={pathname} />
            </div>
          </div>
          <div className={css.topMenuLine} />
        </Fragment>
      )}
      <div className={(!shouldRenderTopMenu || wrapMobile) ? css.wrap : css.wrapFull}>
        {renderRoutes(route.routes, { className: css.content, ...restProps })}
      </div>
      {shouldRenderImage && <Footer />}
    </Element>
  )
}

OrderWrap.propTypes = {
  route: T.object.isRequired,
  elem: T.string,
}
OrderWrap.defaultProps = {
  elem: 'div',
}

export default pageConnector(OrderWrap, {
  asyncLoaders: [clientMetadataLoader],
  withRouter: true,
})
