import React, { Fragment, PureComponent } from 'react'
import T from 'prop-types'
import omit from 'lodash/omit'

import { isDigitNumber, isWholeDigitNumber } from 'helpers/numbers'

import cx from 'classnames'
import css from './index.styl'

export default class Input extends PureComponent {
  static propTypes = {
    className: T.string,
    inputClassName: T.string,
    name: T.string,
    hint: T.node,
    handleInput: T.func,
    value: T.string.isRequired,
    label: T.node,
    textArea: T.bool,
    bare: T.bool,
    disabled: T.bool,
    readonly: T.bool,
    isNumberOnly: T.bool,
    error: T.string,
    element: T.oneOfType([T.string, T.func]),
  }

  static defaultProps = {
    value: '',
    label: '',
    element: 'input',
    disabled: false,
    readonly: false,
    handleInput: () => {}
  }

  handleInput = (event) => {
    const { value } = event.target
    const { handleInput, isNumberOnly } = this.props

    if (isNumberOnly) {
      if (isDigitNumber(value) && isWholeDigitNumber(value)) {
        return handleInput(value)
      } else if (!value) {
        return handleInput('')
      } else {
        return
      }
    }

    handleInput(value)
  }

  render() {
    const {
      className,
      inputClassName,
      label,
      isRequiredLabel,
      hint,
      textArea,
      element,
      error,
      bare,
      disabled,
      readonly,
      isNumberOnly,
      ...restProps
    } = this.props
    const Element = textArea ? 'textarea' : element
    const { value = '' } = restProps
    const rest = omit(restProps, ['handleInput'])
    const inputCl = cx(css.input, inputClassName, { [css.textarea]: textArea })
    const inputErr = textArea ? css.textareaError : css.inputError

    return (
      <main className={cx(css.main, className)}>
        <Fragment>
          {label && (
            <div className={cx(css.label, error && css.labelError)}>
              {isRequiredLabel && <div className={css.labelRequired}>{`* `}</div>}
              {label}
            </div>
          )}
          {!disabled ? (
            <Element
              className={cx(inputCl, error && inputErr)}
              onChange={this.handleInput}
              readOnly={readonly}
              value={value + ''}
              {...rest}
            />
          ) : (
            <div className={cx(inputCl, css.inputDisabled, error && inputErr)}>
              {value ? value : <span>&nbsp;</span>}
            </div>
          )}
        </Fragment>

        {!bare && hint && <p className={css.hint}>{hint}</p>}
        {/* {!bare && error && <p className={css.error}>{error}</p>} */}
      </main>
    )
  }
}
