import React from 'react'


import pageConnector from 'app/Pages/utils/pageConnector'
import {selectOrderField} from 'flux/selectors/order'

import {toPhonePattern} from 'helpers/form/mask'

import SmartLink from 'app/Elements/SmartLink'
import Icon from 'app/Elements/Icon'

import cx from 'classnames'
import css from './index.styl'

function Footer(props) {
  const {
    client_metadata: {
      service_phone,
    } = {},
    className,
  } = props

  return (
    <div className={cx(css.wrap, className)}>
      <div className={css.content}>
        <span>Остались вопросы?</span>
        <SmartLink to={`tel:${service_phone}`} className={css.phoneLink}>{toPhonePattern(service_phone)}</SmartLink>
        <div className={css.socialButtons}>
          <SmartLink to="https://api.whatsapp.com/send/?phone=79099091840" className={css.socialBtnLink}>
            <Icon className={css.socialBtn} name="whatsapp_landing" size={24}/>
          </SmartLink>
          <SmartLink to="https://t.me/Ezheklive" className={css.socialBtnLink}>
            <Icon className={css.socialBtn} name="telegram_landing" size={24}/>
          </SmartLink>
          <SmartLink to="https://vk.com/public204932966" className={css.socialBtnLink}>
            <Icon className={css.socialBtn} name="vk_landing" size={24}/>
          </SmartLink>
          <SmartLink to="https://www.youtube.com/channel/UCJOkjTcW3PUTomp44Eh7gdw" className={css.socialBtnLink}>
            <Icon className={css.socialBtn} name="youtube_landing" size={24}/>
          </SmartLink>
          <SmartLink to="https://zen.yandex.ru/id/60b6679321271b424a775392?country_code=ru&lang=ru&clid=2314040&device_id=DF7F8641-DD45-4B6A-8B93-60DF6D00279B&uuid=acb02fc6f0da472c956c2a5e7eeac726&from_page=other_page" className={css.socialBtnLink}>
            <Icon className={css.socialBtn} name="yandex_zen_landing" size={24}/>
          </SmartLink>
          <SmartLink to="https://instagram.com/ezhek.ru?igshid=1n0k4e3b5o1rm" className={css.socialBtnLink}>
            <Icon className={css.socialBtn} name="insta_landing" size={24}/>
          </SmartLink>
          <SmartLink to="https://www.facebook.com/2780870948621354?referrer=whatsapp" className={css.socialBtnLink}>
            <Icon className={css.socialBtn} name="fb_landing" size={24}/>
          </SmartLink>  
        </div>
        <span>{`© ${new Date().getFullYear()}`} г.</span>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  client_metadata: selectOrderField('client_metadata', state),
})

export default pageConnector(Footer, {
  mapStateToProps,
})
