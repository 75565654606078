import React from 'react'
import T from 'prop-types'
import cx from 'classnames'

import css from './index.styl'


export default function ListGroup(props) {
    const {tag: Tag, border, className, children} = props

    return (
        <Tag
            className={cx(css.listgroup, className, !border && css.noborder)}
        >
            {children}
        </Tag>
    )
}

ListGroup.propTypes = {
  className: T.string,
  tag: T.string,
  border: T.bool,
}
ListGroup.defaultProps = {
  className: '',
  tag: 'ul',
  border:  false,
}
