import React, {useEffect} from 'react'

import Footer from 'app/Elements/Footer'
import ParagraphBlock from 'app/Elements/ParagraphBlock'

import css from './index.styl'

const p1Title = 'ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ'
const p1Items = [
  '',
]

const p2Title = 'I. Что регулирует настоящая политика конфиденциальности'
const p2Items = [
  'Настоящая политика конфиденциальности (далее — Политика) действует в отношении всей информации (далее - «Информация»), включая персональные данные в понимании Федерального закона от 27.07.2006 № 152-ФЗ "О персональных данных" (далее — «Персональные Данные»), а также иные данные, не являющиеся Персональными Данными (далее - «Иные Данные») которые ООО «ЕЖЭК» (далее — «ЕЖЭК») может получить от Вас (далее - «Пользователь») в процессе использования сервиса ЕЖЭК, информацию о котором Вы можете найти на сайте <a href="https://ezhek.ru">https://ezhek.ru</a> (далее — «Сервис»), а также в ходе исполнения ЕЖЭКом любых соглашений и договоров, заключенных с Вами в связи с использованием Вами Сервиса.',
  'Данная Политика определяет состав, цели и общие принципы обработки Информации, а также реализуемые ЕЖЭК меры защиты Информации и Ваших прав.',
]

const p3Title = 'II. Состав Информации и порядок ее получения и обработки'
const p3Items = [
  'В таблице ниже описана Информация, которую ЕЖЭК может получить от Вас в ходе использования Вами Сервиса, а также в ходе исполнения ЕЖЭКом любых соглашений и договоров, заключенных с Вами в связи с использованием Вами Сервиса, а также порядок ее получения и обработки.',
  `<table>
	  <tbody>
    <tr>
      <th>Собираемая Информация</th>
      <th>Порядок получения и обработки</th>
    </tr>
    <tr>
        <th colspan="2">Общее для всех платформ (вэб-версия, приложения для Android и iOS)</th>
    </tr>
		<tr>
			<td>
        Учетные данные устройства (Иные Данные, НЕ Персональные Данные), с которого Пользователь, входит в Сервис:
        <br/>
        1) Номер телефона и проверочный код, направленный на устройство автоматическим способом через СМС.
			</td>
			<td>
			  Исполнение соглашения с Пользователем по предоставлению Сервиса. В частности, для подтверждения принадлежности Заказа или лицевого счета заявителю, обратившемуся с запросом о восстановлении доступа к нему. Обработка осуществляется автоматически без участия сотрудников или подрядчиков ЕЖЭК.
      </td>
		</tr>
		<tr>
			<td>
			  Информация о спросе и предложении на жилищно-бытовые услуги (Иные Данные, НЕ Персональные Данные), самостоятельно размещаемые Пользователем в Сервисе, а именно:
			  <br/>
        2) Спрос: описание содержания услуг, адрес места оказания услуг, фотография или видео с места оказания услуг, желаемое время оказания услуг.
        <br/>
        3) Предложение: описание профессии, образования, специализации, стажа, квалификационных удостоверений другой информации об опыте оказания жилищно-бытовых услуг Пользователем.
      </td>
			<td>
			  Исполнение соглашения с Пользователем по предоставлению Сервиса. В частности, для сопоставления отдельных запросов о спросе и предложении на жилищно-бытовые услуги и выявления наиболее близких совпадений их параметров.
        Обработка осуществляется автоматически без участия сотрудников или подрядчиков ЕЖЭК.
      </td>
		</tr>
		<tr>
			<td>
        Информация и файлы Пользователей (Иные Данные, НЕ Персональные Данные):
        <br/>
        4) Самостоятельно размещаемые, сохраняемые или распространяемые  Пользователем при помощи Сервиса информация и файлы.
      </td>
			<td>Исполнение соглашения с Пользователем по предоставлению Сервиса. Обработка ЕЖЭК не осуществляется.</td>
		</tr>
		<tr>
			<th colspan="2">Вэб-версия сервиса Ezhek.ru</th>
		</tr>
		<tr>
			<td>
			  Иные Данные, а именно:
			  <br/>
        5) Данные, которые становятся доступными ЕЖЭК в процессе использования Пользователем Сервиса (ip-адрес, сведения о браузере Пользователя (в том числе язык интерфейса, размеры экрана, User Agent браузера и пр.), часовой пояс, язык системы, сведения об операционной системе.
        <br/>
        6) Информация в файлах cookie.
      </td>
			<td>Исполнение соглашения с Пользователем по предоставлению Сервиса. В частности, для целей внутреннего контроля, улучшения работы Сервиса, предотвращения ошибок, которые могут возникнуть при использовании Сервиса, уведомления Пользователя об изменениях в Сервисе.</td>
		</tr>
		<tr>
			<th colspan="2">Приложение для Android</th>
		</tr>
		<tr>
			<td>
			  Иные Данные, а именно:
			  <br/>
			  7) Данные, которые становятся доступными ЕЖЭК в процессе использования Пользователем приложения, в том числе GPS-треки, тип устройства, имя устройства, GAID, OpenUDID, версия ОС, модель устройства, часовой пояс, предпочитаемый язык, Device ID For Vendor, название Wi-Fi сети, MAC точки доступа Wi-Fi, наименование оператора сотовой связи, код сети оператора (MNC), User ID, имя ОС, версия приложения, год выпуска мобильного устройства Пользователя, тип интернет соединения, Apps Flyer UID.
      </td>
			<td>Исполнение соглашения с Пользователем по предоставлению Сервиса. В частности для улучшения работы Сервиса, предотвращения ошибок, которые могут возникнуть при использовании Сервиса, уведомления Пользователя об изменениях в Сервисе.</td>
		</tr>
		<tr>
			<th colspan="2">Приложение для iOS</th>
		</tr>
		<tr>
			<td>
        Иные Данные, а именно:
        <br/>
        8) Данные, которые становятся доступными ЕЖЭК в процессе использования Пользователем Приложения, в том числе GPS-треки, тип устройства, имя устройства, IDFA, Open UDID, Apps Flyer UID, версия ОС, модель устройства, часовой пояс, предпочитаемый язык, Device ID For Vendor, гео, название Wi-Fi сети, MAC точки доступа Wi-Fi, название оператора сотовой связи, код сети оператора (MNC).
      </td>
			<td>Исполнение соглашения с Пользователем по предоставлению Сервиса. В частности для улучшения работы Сервиса, предотвращения ошибок, которые могут возникнуть при использовании Сервиса, уведомления Пользователя об изменениях в Сервисе.</td>
		</tr>
		<tr>
			<th colspan="2">Без средств автоматизации</th>
		</tr>
		<tr>
			<td>
			  Учетные данные (Персональные Данные), предоставляемые Пользователем для заключения договоров с ЕЖЭК, выгодоприобретателем по которым является Пользователь, для идентификации лица в соответствии с законодательством РФ о противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма и для автоматизированного формирования чеков на оплату услуг в соответствии с законодательством РФ о применении контрольно-кассовой техники при расчетах за услуги, а именно:
			  <br/>
        9) Данные Пользователя, например, фамилия, имя, отчество, дата рождения, пол, адрес электронной почты, адрес регистрации, адрес проживания, паспортные данные, ИНН, СНИЛС.
        <br/>
        10) Копии документов Пользователя, например, копия удостоверения личности, копия документа, разрешающего пребывание и работу в РФ, подтверждающего адрес регистрации, копия патента, реквизиты банковского счета.
      </td>
			<td>
			  Исполнение соглашения с Пользователем по предоставлению Сервиса. В частности, для подтверждения принадлежности учетной записи заявителю, обратившемуся с запросом о восстановлении доступа к учетной записи, для управления и администрирования Сервиса и другое.
        Предоставление и обработка данных осуществляется исключительно без использования средств автоматизации.
      </td>
		</tr>
	</tbody>
  </table>`,
  'ЕЖЭК не осуществляет модерацию, просмотр, проверку, редактирование Информации, в частности, самостоятельно размещаемых, сохраняемых или распространяемых Пользователями в рамках Сервиса данных и файлов, а также контроль над ней, и не может гарантировать, что размещенная Пользователем в рамках Сервиса Информация не нарушает положений настоящей Политики, правовых актов, иных документов. Пользователь должен самостоятельно оценивать все риски, связанные с использованием Информации, размещенной в Сервисе, включая оценку надежности, полноты, безопасности, законности, достоверности, актуальности или полезности этой Информации.',
  'В рамках использования Сервиса от Вас не требуется предоставлять чувствительную информацию (такую как расовая, национальная принадлежность, политические взгляды, религиозные или философские убеждения, информация о здоровье и интимной жизни, биометрические данные). ЕЖЭК просит Вас не размещать в Сервисе такую информацию. Если Вы невольно или намерено разместите такую чувствительную информацию в Сервисе, ЕЖЭК будет обеспечивать ее конфиденциальность наравне с Информацией.',
]

const p4Title = 'III. Как ЕЖЭК защищает Вашу Информацию'
const p4Items = [
  'В случае автоматизированной обработки Информации, это происходит без доступа к ней кого-либо из сотрудников или подрядчиков ЕЖЭКа. В случае если такой доступ понадобится, например, в связи с технической неисправностью Сервиса, либо в отношении Учетных Данных, обрабатываемых исключительно без использования средств автоматизации, то он может быть предоставлен только тем сотрудникам или подрядчикам ЕЖЭКа, которые нуждаются в этом для выполнения своих задач. Для защиты и обеспечения конфиденциальности данных все такие сотрудники и подрядчики соблюдают внутренние правила и процедуры в отношении обработки Информации и связаны соглашениями о конфиденциальности информации.',
  'ЕЖЭК внедрил достаточные технические и организационные меры для защиты Информации от несанкционированного, случайного или преднамеренного незаконного уничтожения, потери, изменения, недобросовестного использования, раскрытия или доступа, а также иных незаконных форм обработки. Данные меры безопасности были реализованы с учетом современного уровня техники, стоимости их реализации, рисков, связанных с обработкой и характером Информации.',
]

const p5Title = 'IV. Кто еще имеет доступ к Вашей Персональной информации, и кому она может быть передана'
const p5Items = [
  '1. Другие Пользователи',
  'ЕЖЭК не передает Вашу Информацию другим Пользователям. Пользователь может самостоятельно, по собственному выбору и на свой страх и риск передать Информацию другим Пользователям с целью предоставления/получения услуги. ЕЖЭК не несет ответственности за последствия такой передачи Информации.',
  '2. Третьи лица',
  'ЕЖЭК может передавать Информацию третьим лицам для выполнения соглашений с Пользователями по предоставлению Сервиса.',
  'К таким третьим лицам могут относиться:',
  '(i) Партнеры, такие как владельцы сайтов и приложений, рекламные сети и другие партнеры, предоставляющие ЕЖЭКу услуги, связанные с размещением и отображением рекламы на сайтах, в программах, продуктах или сервисах, которые принадлежат таким партнерам или контролируются ими. Информация передается партнерам исключительно в агрегированном обезличенном виде;',
  '(ii) Поставщики информационных сервисов или консультанты, заключившие договора с ЕЖЭК с положениями о конфиденциальности, аналогичными предусмотренным в данной Политике;',
  '(iii) Третьим лицам, в отношении которых Пользователем произведена уступка прав или обязанностей, или новация по соответствующему соглашению о предоставлении Сервиса;',
  '(iv) Любому уполномоченному контролирующему органу, правоохранительным органам, центральным или местным исполнительным органам власти, другим официальным или государственным органам, или судам, которым ЕЖЭК обязан предоставлять Информацию в соответствии с применимым законодательством по соответствующему запросу, оформленному строго в соответствии с законодательными требованиями;',
  '(v) Третьим лицам, в случае если Вы выразили согласие на передачу Вашей Информации, либо передача Информации требуется для предоставления Сервиса или выполнения определенного соглашения или договора, заключенного с Вами;',
  '(vi) Любому третьему лицу в целях обеспечения правовой защиты ЕЖЭКа или третьих лиц при нарушении Вами Соглашения об условиях использования сервиса ЕЖЭК, размещенного по адресу <a href="https://ezhek.ru/сodex">https://ezhek.ru/сodex</a>, настоящей Политики, либо в ситуации, когда существует угроза такого нарушения.',
]

const p6Title = 'V. Где хранится Ваша Информация'
const p6Items = [
  'Ваша Информация хранится на территории Российской Федерации.',
]

const p7Title = 'VI. Как долго хранится Ваша Информация'
const p7Items = [
  'ЕЖЭК будет хранить Ваши Персональные Данные столько времени, сколько это необходимо для достижения цели, для которой они были собраны, или для соблюдения требований законодательства и нормативных актов.',
  'Если иное не требуется по закону или соглашению с Вами, Иные Данные, которые Вы храните в Сервисе, будут храниться до тех пор, пока у Вас заключен и действует договор с ЕЖЭК о предоставлении доступа к Сервису, но они могут быть удалены Вами в любое время.',
  'Вы можете самостоятельно удалить любые Иные Данные через интерфейс Сервиса, воспользовавшись формой, расположенной в сети Интернет по адресу <a href="https://ezhek.ru/account_terminate">https://ezhek.ru/account_terminate</a> . Обращаем Ваше внимание, что удаление Иных Данных, необходимых для предоставления Сервиса, может привести к невозможности предоставления Вам доступа к Сервису.',
]

const p8Title = 'VII. Ваши права'
const p8Items = [
  'Пользователи вправе:',
  '1. Получать бесплатный доступ к своей Информации через интерфейс Сервиса или при помощи обращения в ЕЖЭК;',
  '2. Самостоятельно вносить изменения и исправления в Информацию, воспользовавшись формой, расположенной в сети Интернет по адресу <a href="https://ezhek.ru/account_terminate">https://ezhek.ru/account_terminate</a>, при условии, что такие изменения и исправления актуальны и достоверны;',
  '3. Самостоятельно удалять свою Информацию, воспользовавшись формой, расположенной в сети Интернет по адресу <a href="https://ezhek.ru/account_terminate">https://ezhek.ru/account_terminate</a>; при этом удаление Пользователем определенной Информации может привести к невозможности предоставления Пользователю доступа к Сервису;',
  '4. Требовать от ЕЖЭК уточнения Информации Пользователя или ее уничтожения в случае, если по тем или иным причинам Пользователь не может это сделать самостоятельно;',
  '5. На основании запроса получать от ЕЖЭК информацию, касающуюся обработки Информации Пользователя;',
  '6. Отказаться от обработки Персональных Данных путем направления ЕЖЭК соответствующего заявления в порядке, предусмотренном настоящей Политикой.',
  'Как Вы можете реализовать свои права:',
  'Для осуществления вышеуказанных прав в отношении Иных Данных, пожалуйста, войдите в интерфейс Сервиса, используя учетные данные своего устройства.',
  'Для осуществления вышеуказанных прав в отношении Персональных Данных, пожалуйста, обратитесь в службу поддержки ЕЖЭК (см. раздел XII настоящей Политики).',
  'Если Вы не удовлетворены тем, как ЕЖЭК обращается с Вашей Информацией, пожалуйста, сообщите нам, и мы рассмотрим Вашу претензию.',
  'Обращения Пользователей:',
  '1. Сведения об обрабатываемых ЕЖЭК данных, в том числе Персональных Данных Пользователя, в связи с использованием им Сервиса предоставляются Пользователю или его представителю при обращении (запросе).',
  '2. Запросы направляются в письменной форме по адресу места нахождения ЕЖЭК или в иной форме, предусмотренной действующим законодательством Российской Федерации.',
  '3. Пользователь вправе отозвать согласие на обработку своих Персональных Данных путем направления ЕЖЭК письменного заявления по месту нахождения Компании в соответствии с требованиями действующего законодательства.',
]

const p9Title = 'VIII. Ограничение ответственности ЕЖЭК'
const p9Items = [
  'ЕЖЭК не несет ответственности за разглашение и распространение Информации о Пользователе другими Пользователями Сервиса или другими пользователями сети Интернет в случае, если такие лица получили доступ к указанной Информации в случае нарушения Пользователем сохранности доступа к его устройству, с которого осуществляется доступ в Сервис.',
]

const p10Title = 'IX. Изменения и дополнения настоящей Политики'
const p10Items = [
  '1. Настоящая Политика может быть изменена ЕЖЭК, например, в связи с усовершенствованием Сервиса и мер по его безопасности, с уведомлением Пользователя, в том числе путем размещения новой редакции Политики на сайте. Изменения в Политике, внесенные ЕЖЭК, вступают в силу в день, следующий за днем размещения на сайте новой редакции Политики. Пользователь обязуется самостоятельно отслеживать внесение изменений в настоящую Политику и своевременно ознакамливаться с ними. Неосуществление Пользователем действий по ознакомлению не может служить основанием для неисполнения Пользователем своих обязательств и несоблюдения Пользователем ограничений, установленных настоящей Политикой.',
  '2. Пользователь вправе отказаться от принятия изменений и дополнений в настоящую Политику, что означает отказ Пользователя от использования Сервиса и всех предоставленных ему ранее прав.',
  '3. Продолжение использования Сервиса после внесения изменений в Политику подтверждает согласие Пользователя с такими изменениями, если получение отдельного согласия не требуется законом.',
  '4. Настоящая Политика регулируется и толкуется в соответствии с законодательством Российской Федерации. Вопросы, не урегулированные настоящей Политикой, подлежат разрешению в соответствии с законодательством Российской Федерации.',
]

const p11Title = 'X. Как мы используем файлы cookie и другие подобные технологии на Сайте или при использовании Вами Сервиса'
const p11Items = [
  'Что такое файлы cookie и как они используются Сервисом:',
  'Сервис использует только строго необходимые технические файлы cookie. Файлы cookie — это небольшие текстовые файлы, размещенные на Вашем устройстве, которое Вы используете для доступа к Сервису. Ни сами эти файлы, ни записанная в них информация не передаются в ЕЖЭК. В эти файлы записывается информация о типе Вашего браузера, аппаратном и программном обеспечении, языке, используемом на устройстве, местоположении, а также информация, которую Вы вводите на Вашем устройстве при использовании Сервиса.',
  'Как долго файлы cookie хранятся на Вашем устройстве:',
  'Собранные в файлы cookie данные будут храниться на Вашем устройстве пока Вы не удалите их через настройки браузера. Обращаем Ваше внимание, что, если Вы удалите файлы cookie, некоторые функции Сервиса будут Вам недоступны.',
  'Кто имеет доступ к информации, содержащейся в файлах cookie:',
  'К информации, содержащейся в файлах cookie, имеете доступ только Вы через Ваше устройство. Эти файлы не передаются ЕЖЭК или третьим лицам.',
  'Начало использования Сервиса обозначает Ваше согласие на использование файлов cookie, описанных в данной Политике. Отдельно такое согласие не запрашивается. Если после того, как Вы согласились с использованием файлов cookie путем начала использования Сервиса, Вы захотите изменить свое решение, Вы сможете сделать это, удалив файлы cookie, хранящиеся в Вашем браузере через его настройки. Если Вы откажетесь от использования файлов cookie, некоторые функции Сервиса будут Вам недоступны. Вы также можете изменить настройки Вашего браузера, чтобы принимать или отклонять по умолчанию все файлы cookie или файлы cookie с определенных сайтов, включая сайт ЕЖЭК.',
]

const p12Title = 'XI. Согласие с Политикой '
const p12Items = [
  'Настоящим я даю согласие OOO «ЕЖЭК» и его уполномоченным представителям на обработку в соответствии с данной Политикой моей Информации, в частности, моих Иных Данных - с использованием средств автоматизации, в том числе в информационно-телекоммуникационных сетях, и моих Персональных Данных - без использования таких средств.',
  'Настоящим я подтверждаю, что Информация, переданная мной в ООО «ЕЖЭК», является достоверной. Я даю свое согласие на то, что Информация может обрабатываться ООО «ЕЖЭК» и его уполномоченными представителями в рекламно-информационных целях только в обезличенном агрегированном виде. ',
  'Настоящим я даю свое согласие ООО «ЕЖЭК» направлять информационные сообщения на указанный мной номер мобильного телефона, включающие информацию о проводимых ООО «ЕЖЭК» и его партнерами рекламных акциях и сообщения иного информационного характера.',
  'Данное согласие дано мной на неопределенный срок и может быть в любой момент мной отозвано путем направления письменного уведомления в ООО «ЕЖЭК» по адресу, указанному на сайте <a href="https://ezhek.ru">https://ezhek.ru</a> в разделе Контакты.',
]


const p13Title = 'XII. Вопросы и предложения'
const p13Items = [
  'ЕЖЭК приветствует Ваши вопросы и предложения, касающиеся исполнения или изменения настоящей Политики. Пожалуйста, воспользуйтесь нашей формой обратной связи, размещенной по адресу <a href="https://ezhek.ru/contacts">https://ezhek.ru/contacts</a> . Вы также можете воспользоваться этим адресом для направления запросов о реализации Ваших прав.',
  'Дата публикации: 13.08.2023 г.',
  'Текущая версия доступна по адресу: <a href="https://ezhek.ru/сodex">https://ezhek.ru/сodex</a>',
]

export default function CodexTariffs(props) {
  useEffect(() => {
    document.title = 'ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ'
  }, [props.title])

  return (
    <div className={css.wrap}>
      <div className={css.content}>
        <ParagraphBlock title={p1Title} items={p1Items} className={css.p1}/>
        <ParagraphBlock title={p2Title} items={p2Items}/>
        <ParagraphBlock title={p3Title} items={p3Items}/>
        <ParagraphBlock title={p4Title} items={p4Items}/>
        <ParagraphBlock title={p5Title} items={p5Items}/>
        <ParagraphBlock title={p6Title} items={p6Items}/>
        <ParagraphBlock title={p7Title} items={p7Items}/>
        <ParagraphBlock title={p8Title} items={p8Items}/>
        <ParagraphBlock title={p9Title} items={p9Items}/>
        <ParagraphBlock title={p10Title} items={p10Items}/>
        <ParagraphBlock title={p11Title} items={p11Items}/>
        <ParagraphBlock title={p12Title} items={p12Items}/>
        <ParagraphBlock title={p13Title} items={p13Items}/>
      </div>
      <Footer/>
    </div>
  )
}
