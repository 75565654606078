import {createSelector} from 'reselect'

export const selectStore = ({fetch: {authConfirm = {}} = {}} = {}) => authConfirm

export const selectAuthConfirmData = createSelector(
  selectStore,
  ({data}) => data, // may be null
)

export const selectAuthToken = createSelector(
  selectAuthConfirmData,
  (data) => {
    const {token} = data || {}
    return token
  }
)

export const selectAuthConfirmError = createSelector(
  selectStore,
  ({error}) => error, // may be null
)

export const selectAuthConfirmIsError = createSelector(
  selectAuthConfirmError,
  (error) => !!error,
)

export const selectAuthConfirmIsFetching = createSelector(
  selectStore,
  ({fetching}) => fetching,
)

