import React from 'react'
import T from 'prop-types'
import cx from 'classnames'

import css from './index.styl'


export default function ListGroupItem(props) {
    const {tag: Tag, className, children} = props;

    return (<Tag className={cx(className, css.listgroupitem)}>
        {children}
    </Tag>)
}

ListGroupItem.propTypes = {
    tag: T.string,
    className: T.string,
}
ListGroupItem.defaultProps = {
    tag: 'li'
}


