import React, { Fragment, useEffect, useState } from 'react'
import OrderHot from 'app/Pages/Order/OrderHot/index.connector'
import OrderClose from 'app/Pages/Order/OrderDone/index.connector'
import PreOrder from 'app/Pages/Order/OrderNormal/index.connector'

import Page404 from 'app/PagesXXX/404/index.connector'

import PaymentFail from 'app/Components/PaymentFail'

import ModalCanceled from 'app/Modals/ModalCanceled'
import ModalToDelayed from 'app/Modals/ModalToDelayed'
import ModalMasterNotFound from 'app/Modals/ModalMasterNotFound'

import isServer from 'helpers/isServer'

export default function OrderPage(props) {
  const {
    type,
    status,
    paymentStatus,
    isNormalStarted,
    formUrl,
    setOrderToDelayed,
    setOrderToNormal,
    rePlaceOrder,
    orderError,
    authReset,
    orderReset,
  } = props

  const httpStatus = (orderError || {}).status

  const [isModalCanceledOpen, handleIsModalCanceledOpen] = useState(false)
  const [isModalToDelayedOpen, handleIsModalToDelayedOpen] = useState(false)
  const [isModalMasterNotFoundOpened, handleMasterNotFoundOpened] = useState(false)
  const [is404, handle404] = useState(false)

  if (formUrl && !isServer) {
    return window.location.href = formUrl
  }

  useEffect(() => {
    if (httpStatus === undefined) {
      handle404(false)
    }
    if (httpStatus === 403) {
      orderReset()
      authReset()
    }
    if (httpStatus === 404) {
      handle404(true)
    }
  }, [httpStatus])

  useEffect(() => {
    if (status === 'CANCELED') {
      handleIsModalCanceledOpen(true)
    }
    if (status === 'MASTER_NOT_FOUND') {
      handleMasterNotFoundOpened(true)
    }
  }, [status])

  useEffect(() => {
    if (type === 'DELAYED' && status !== 'CANCELED') {
      handleIsModalToDelayedOpen(true)
    }
  }, [type])

  if (is404) {
    return <Page404 />
  }

  if (type !== 'DELAYED' && (paymentStatus === 'PREORDER_FAIL' || paymentStatus === 'PREORDER_ERROR')) {
    return <PaymentFail setOrderToDelayed={setOrderToDelayed} rePlaceOrder={rePlaceOrder} />
  }

  if (status === 'DONE') {
    return <OrderClose />
  }

  return (
    <Fragment>
      {type === 'NORMAL' && !isNormalStarted && <PreOrder />}

      {type === 'NORMAL' && isNormalStarted && <OrderHot />}

      {type === 'HOT' && <OrderHot />}

      {type === 'HOT' && isModalMasterNotFoundOpened && (
        <ModalMasterNotFound
          setOrderToNormal={setOrderToNormal}
          setOrderToDelayed={setOrderToDelayed}
          handleMasterNotFoundOpened={handleMasterNotFoundOpened}
        />
      )}

      {isModalCanceledOpen && (
        <ModalCanceled
          type={type}
          setOrderToNormal={setOrderToNormal}
          handleIsModalCanceledOpen={handleIsModalCanceledOpen}
        />
      )}

      {isModalToDelayedOpen && (
        <ModalToDelayed />
      )}
    </Fragment>
  )
}
