import React from 'react'
import T from 'prop-types'
import cx from 'classnames'

import css from './index.styl'


export default function Card(props) {
  const {tag: Tag, className, children} = props

  return (<Tag className={cx(className, css.card)}>
    {children}
  </Tag>)
}

Card.propTypes = {
  className: T.string,
  tag: T.string.isRequired,
}

Card.defaultProps = {
  className: '',
  tag: 'div',
}

export function CardHeader(props) {
  const {tag: Tag, className, children} = props

  return (<Tag className={cx(className, css.cardHeader)}>
    {children}
  </Tag>)
}

CardHeader.propTypes = {
  className: T.string,
  tag: T.string.isRequired,
}

CardHeader.defaultProps = {
  className: '',
  tag: 'div',
}

export function CardContent(props) {
  const {tag: Tag, className, children} = props

  return (<Tag className={cx(className, css.cardContent)}>
    {children}
  </Tag>)
}

CardContent.propTypes = {
  className: T.string,
  tag: T.string.isRequired,
}

CardContent.defaultProps = {
  className: '',
  tag: 'div',
}

export function CardFooter(props) {
  const {tag: Tag, className, children} = props

  return (<Tag className={cx(className, css.cardFooter)}>
    {children}
  </Tag>)
}

CardFooter.propTypes = {
  className: T.string,
  tag: T.string.isRequired,
}

CardFooter.defaultProps = {
  className: '',
  tag: 'div',
}

