import React from 'react'
import T from 'prop-types'

import Icon from 'app/Elements/Icon'
import ListActiveIcon from 'app/Assets/listActive'

import cx from 'classnames'
import css from './index.styl'

export const icons = [
  {
    iconName: 'listActive',
    IconElem: ListActiveIcon,
    title: 'Заказ принят',
    status: ['PLACED', 'CONFIRMED', 'IN_POOL'],
  },
  {
    iconName: 'personActive',
    title: 'Мастер назначен',
    status: 'MASTER_FOUND',
  },
  {
    iconName: 'door',
    title: 'Мастер прибыл',
  },
  {
    iconName: 'settings',
    title: 'Заказ выполняется',
    status: 'IN_WORK',
  },
  {
    iconName: 'cart',
    title: 'Покупка материалов',
    status: 'AWAITING_MASTER',
  },
  {
    iconName: 'checkmark',
    title: 'Заказ выполнен',
    status: 'DONE',
  },
]

function changeStatusIcons(arrayStatus) {
  const newIconsState = icons.map((item) => ({ ...item, active: false }))

  arrayStatus.map((indexStatus) => {
    return (newIconsState[indexStatus].active = true)
  })
  return newIconsState
}

function getNewIcons(statusOrder) {
  switch (statusOrder) {
    case 'PLACED': //Размещен
    case 'CONFIRMED': //Подтвержден
    case 'IN_POOL': //В пуле
    case 'MASTER_NOT_FOUND': //Мастер не найден
      return changeStatusIcons([0])
    case 'MASTER_FOUND': //Мастер найден
      return changeStatusIcons([0, 1])
    case 'IN_PLACE': //Мастер прибыл к месту заказа
      return changeStatusIcons([0, 1, 2])
    case 'IN_WORK': //В работе
      return changeStatusIcons([0, 1, 2, 3])
    case 'SHOP_REQUEST': //Мастер отдалился от места заказа
      return changeStatusIcons([0, 1, 2, 3])
    case 'IN_SHOP': //Мастер подтвердил поход за материалом
      return changeStatusIcons([0, 1, 2, 4])
    case 'WORK_REQUEST': //Мастер приближатеся к месту заказа
      return changeStatusIcons([0, 1, 2, 4])
    case 'DONE': //Завершен
      return changeStatusIcons([0, 1, 2, 5])
    default:
      return changeStatusIcons([])
  }
}

export default function OrderStatusIcons(props) {
  const { className, status } = props
  const newIcons = getNewIcons(status)

  return (
    <div className={cx(css.icons, className)}>
      {newIcons.map(({ iconName, IconElem, title, active }, index) => {
        if (IconElem) {
          return (
            <div key={iconName} className={css.iconWrap}>
              <IconElem className={cx(css.icon, css.transparent, active && css.highlight)} size={22} />
              <span>{title}</span>
            </div>
          )
        }

        return (
          <div key={iconName} className={css.iconWrap}>
            <Icon name={iconName} size={22} className={cx(css.icon, active && css.highlight)} />
            <span>{title}</span>
          </div>
        )
      })}
    </div>
  )
}

OrderStatusIcons.propTypes = {
  className: T.string,
  status: T.string,
}
OrderStatusIcons.defaultProps = {
  className: '',
  status: null,
}
