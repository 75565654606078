import { createStore, combineReducers, applyMiddleware, compose } from 'redux'

import { routerReducer, routerMiddleware } from 'react-router-redux'

import { persistStore, persistReducer, getStoredState } from 'redux-persist'

import CookiesJS from 'cookies-js'
import { CookieStorage } from 'redux-persist-cookie-storage'
import { createFilter } from 'redux-persist-transform-filter';

import thunk from 'redux-thunk'
import { reducer as notifications } from 'react-notification-system-redux'

import devToolsExtension from './utils/devToolsExtension'

import notificationsMiddleware from './middlewares/notifications'

import isServer from 'helpers/isServer'
import isClient from 'helpers/isClient'

import reducers from 'flux/reducers'

export default async function create(history, initialState, cookies) {
  const combinedReducers = combineReducers({
    ...reducers,
    notifications,
    router: routerReducer,
  })

  const middleware = applyMiddleware(routerMiddleware(history), thunk, notificationsMiddleware)

  const enhances =
    GLOBALS.ENV_PRESET === 'development'
      ? compose(
          middleware,
          devToolsExtension()
        )
      : compose(middleware)

  if (isServer) {
    const persistConfig = {
      key: 'root',
      whitelist: ['fetch'],
      storage: new CookieStorage(cookies),
    }

    let preloadedState
    try {
      preloadedState = await getStoredState(persistConfig)
    } catch (e) {
      preloadedState = {}
    }
    const { fetch } = preloadedState || {}

    if (fetch) {
      initialState = { ...initialState, fetch }
    }

    const store = createStore(combinedReducers, initialState, enhances)
    return { store }
  }

  if (isClient) {
    CookiesJS.defaults.secure = GLOBALS.USE_SSL

    const persistConfig = {
      key: 'root',
      whitelist: ['fetch'],
      storage: new CookieStorage(CookiesJS),
    }

    const persistedReducer = persistReducer(persistConfig, combinedReducers)
    const store = createStore(persistedReducer, initialState, enhances)
    const persistor = persistStore(store, {
      transforms: [
        createFilter(`fetch.authConfirm`)
      ]
    })

    return { store, persistor }
  }
}
