import { show } from 'react-notification-system-redux'

export default ({ dispatch }) => (next) => (action) => {
  if (action.notification) {
    const notification = {
      level: 'info',
      position: 'tr', // as for bottom-center
      autoDismiss: 0,
      ...action.notification,
    }

    delete action.notification

    dispatch(show(notification, notification.level))
  }

  next(action)
}
