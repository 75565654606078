import React, { Fragment } from 'react'
import T from 'prop-types'

//** elements
import PageMeta from 'app/Layout/PageMeta'
import SmartLink from 'app/Elements/SmartLink'
import Icon from 'app/Elements/Icon'

import cx from 'classnames'
import css from './index.styl'

export default function Page404(props) {
  const {
    history: {
      goBack,
      length,
    } = {},
  } = props

  return (
    <PageMeta status={404}>
      <div className={css.wrapper}>
        <Icon className={css.icon} name="smileSad" size={80} />

        <h1 className={css.title}>
          <span className={css.titleHeading}>404</span>
          <span className={cx(css.desc, css.titleDesc)}>
            Страница не найдена!
          </span>
        </h1>

        <span className={css.desc}>
          Вы можете вернуться{' '}
          {length > 2 && (
            <Fragment>
              <SmartLink onClick={goBack} theme={'green'} isUnderlined>
                назад
              </SmartLink>
              <br />
              или перейти
            </Fragment>
          )}
          {' '}
          <SmartLink to={'/'} theme={'green'} isUnderlined>
            на главную
          </SmartLink>{' '}
          страницу
        </span>
      </div>
    </PageMeta>
  )
}

Page404.propTypes = {
  history: T.object.isRequired,
}
