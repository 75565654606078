import React, { useState, Fragment } from 'react'
import T from 'prop-types'

import Button from 'app/Elements/Button'
import SmartLink from 'app/Elements/SmartLink'

import css from './index.styl'

export default function PaymentFail(props) {
  const { setOrderToDelayed, rePlaceOrder } = props

  return (
    <Fragment>
      <div className={css.wrap}>
        <p className={css.message}>
          К сожалению, не удалось
          <br />
          зарезервировать необходимую сумму
        </p>
        <Button onClick={() => rePlaceOrder()} theme={'green'} type={'button'} className={css.button}>
          Попробовать снова
        </Button>
        <Button onClick={() => setOrderToDelayed()} theme={'orange'} type={'button'} className={css.button}>
          Сохранить заказ как отложенный
        </Button>
        <SmartLink to={'/'} theme={'green'} isUnderlined className={css.linkHome}>
          На главную
        </SmartLink>
      </div>
    </Fragment>
  )
}
