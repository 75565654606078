import React, {Fragment, useState} from 'react'
import DatePicker, {registerLocale} from 'react-datepicker'
import Dropdown from 'react-dropdown'
import ru from 'date-fns/locale/ru'
import {format, differenceInYears} from 'date-fns'

registerLocale('ru', ru)

import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'
import {toPhonePattern, phonePlaceholder} from 'helpers/form/mask'

import pageConnector from 'app/Pages/utils/pageConnector'
import masterMetadataLoader from 'flux/loaders/masterMetadata'
import {selectOrderField} from 'flux/selectors/order'

import Footer from 'app/Elements/Footer'
import ParagraphBlock from 'app/Elements/ParagraphBlock'
import Radio, {RadioGroup} from 'app/Elements/RadioButton'
import Checkbox from 'app/Elements/Checkbox'
import Input from 'app/Elements/Input'
import Button from 'app/Elements/Button'
import SmartLink from 'app/Elements/SmartLink'

import masterRegistrationRequest from 'fetch/requests/masterRegistration'

import {ModalWrapper} from 'app/Layout/Modal'

import css from './index.styl'

const Title = 'Вас приветствует служба аккредитации ЕЖЭК. Пожалуйста, заполните заявку:'
const TitleComplete = 'Ваша заявка принята, для дальнейшей работы и заврешения регистрации установите приложение ЕЖЭК на iOS или Android'

const dd_education = [
  'Среднее', 'Среднее специальное', 'Неоконченное высшее', 'Высшее'
]

const dd_experience = [
  'Меньше года',
  '1 год',
  '2 года',
  '3 года',
  '4 года',
  '5 лет',
  '6 лет',
  '7 лет',
  '8 лет',
  '9 лет',
  '10 лет',
  '11 лет',
  '12 лет',
  '13 лет',
  '14 лет',
  '15 лет',
  '16 лет',
  '17 лет',
  '18 лет',
  '19 лет',
  '20 лет',
  '21 год',
  '22 года',
  '23 года',
  '24 года',
  '25 лет',
  '26 лет',
  '27 лет',
  '28 лет',
  '29 лет',
  '30 лет',
  '31 год',
  '32 года',
  '33 года',
  '34 года',
  '35 лет',
  '36 лет',
  '37 лет',
  '38 лет',
  '39 лет',
  '40 лет',
  '41 год',
  '42 года',
  '43 года',
  '44 года',
  '45 лет',
  '46 лет',
  '47 лет',
  '48 лет',
  '49 лет',
  'Более 50ти лет'
]

function InputError({message}) {
  return message ? <div className={css.inputError}>{message}</div> : null
}

function MastersRequest(props) {
  const _formFields = {
    email: '',
    emailError: '',
    phone: '',
    phoneError: '',
    first_name: '',
    first_nameError: '',
    last_name: '',
    last_nameError: '',
    birth_date: '',
    birth_dateError: '',
    request_region: '',
    request_regionError: '',
    request_busy_type: 'FULL',
    request_busy_typeError: '',

    request_education: '',
    request_educationError: '',
    request_profession: '',
    request_professionError: '',
    request_experience: '',
    request_experienceError: '',
    request_about: '',
    request_aboutError: '',
    request_message: '',
    request_messageError: '',

    is_agree: false,
    is_agreeError: '',
  }

  const {
    master_metadata: {
      regions = [],
      codexLinks: {
        offer,
      } = {}
    } = {}
  } = props

  const dd_regions = regions.map(r => ({value: r.id, label: r.name}))

  const [isLoading, handleIsLoading] = useState(false)
  const [isModalOpen, handleIsModalOpen] = useState(false)
  const [IsVisibleComplete, handleIsVisibleComplete] = useState(false)
  const [formFields, handleFormFields] = useState(_formFields)

  function clearFormFields() {
    handleFormFields(_formFields)
  }

  function checkFields(fromSubmit) {
    let errors = {}

    if (!formFields.first_name) {
      errors.first_nameError = 'Введите имя'
    }

    if (!formFields.last_name) {
      errors.last_nameError = 'Введите фамилию'
    }

    if (!formFields.birth_date) {
      errors.birth_dateError = 'Укажите дату рождения'
    } else {
      const diff = differenceInYears(new Date(), formFields.birth_date)
      if (diff < 20 || diff > 65) {
        errors.birth_dateError = 'Пожалуйста, свяжитесь с нами по телефону'

        if (fromSubmit) {
          handleIsModalOpen(true)
        }
      }
    }

    if (!formFields.request_education) {
      errors.request_educationError = 'Укажите образование'
    }

    if (!formFields.request_profession) {
      errors.request_professionError = 'Укажите профессию'
    }

    if (!formFields.request_experience) {
      errors.request_experienceError = 'Укажите стаж'
    }

    if (!formFields.phone) {
      errors.phoneError = 'Введите номер телефона'
    }

    if (!formFields.email) {
      errors.emailError = 'Введите электронную почту'
    }

    if (!formFields.request_region) {
      errors.request_regionError = 'Выберите желаемый район'
    }

    if (!formFields.is_agree) {
      errors.is_agreeError = '\nЧтобы продолжить, поставьте галочку, если согласны с общими условиями деятельности ЕЖЭК и на обработку ваших персональных данных'
    }

    if (!isEmpty(errors)) {
      handleErrors(errors)
    }

    return isEmpty(errors)
  }

  function setErrors(errors) {
    if (!isEmpty(errors)) {
      handleErrors(errors)
    }
  }

  function handleSubmit(event) {
    event.preventDefault()

    if (checkFields(true)) {
      handleIsLoading(true)

      const body = pickBy(formFields, (value, fieldName) => !fieldName.includes('Error'))
      body.birth_date = format(formFields.birth_date, 'dd.MM.yyyy')

      masterRegistrationRequest(body)
        .then(data => {
          handleIsVisibleComplete(true)
          handleIsLoading(false)
        })
        .catch(error => {
          const {extra} = error
          const parsedErrors = {}

          if (parsedErrors.detail) {
            parsedErrors['is_agreeError'] = '\n' + parsedErrors.detail
          } else {
            const errorFields = Object.keys(extra)
            errorFields.map(key => parsedErrors[`${key}Error`] = extra[key][0])
            parsedErrors['is_agreeError'] = '\n' + extra[errorFields[0]][0]
          }

          setErrors(parsedErrors)

          handleIsLoading(false)
        })
    }
  }

  function handleErrors(errors) {
    handleFormFields({
      ...formFields,
      ...errors,
    })
  }

  function handleInput(value, field, isError) {
    checkFields()
    handleFormFields({
      ...formFields,
      [field]: !isError ? value : formFields[field],
      [`${field}Error`]: isError ? value : '',
    })
  }

  return (
    <div className={css.wrap}>
      {IsVisibleComplete ? (
        <div className={css.content}>
          <ParagraphBlock title={TitleComplete} className={css.p1}/>
        </div>
      ) : (
        <div className={css.content}>
          <ParagraphBlock title={Title} className={css.p1}/>

          <form onSubmit={handleSubmit} className={css.form}>
            <div className={css.inputColumn}>
              <div className={css.inputRow}>
                <div className={css.inputLabel}>Имя Отчество *</div>
                <Input
                  className={css.inputWrap}
                  inputClassName={css.input}
                  handleInput={(value) => handleInput(value, 'first_name')}
                  value={formFields.first_name}
                  name={'first_name'}
                  id={'first_name'}
                />
                <InputError message={formFields.first_nameError}/>
              </div>

              <div className={css.inputRow}>
                <div className={css.inputLabel}>Фамилия *</div>
                <Input
                  className={css.inputWrap}
                  inputClassName={css.input}
                  handleInput={(value) => handleInput(value, 'last_name')}
                  value={formFields.last_name}
                  name={'last_name'}
                  id={'last_name'}
                />
                <InputError message={formFields.last_nameError}/>
              </div>

              <div className={css.inputRow}>
                <div className={css.inputLabel}>Дата рождения *</div>
                <DatePicker
                  customInput={
                    <Input
                      className={css.inputWrap}
                      inputClassName={css.input}
                      value={format(formFields.birth_date, 'dd.MM.yyyy')}
                      name={'birth_date'}
                      id={'birth_date'}
                    />
                  }
                  onChange={(value) => handleInput(value, 'birth_date')}
                  selected={formFields.birth_date}
                  locale='ru'
                  dateFormat='dd.MM.yyyy'
                  maxDate={new Date()}
                  placeholderText={'DD.MM.YYYY'}
                />
                <InputError message={formFields.birth_dateError}/>
              </div>

              <div className={css.inputRow}>
                <div className={css.inputTitle}>Образование *</div>
                <Dropdown
                  value={formFields.request_education}
                  placeholder="Выбрать"
                  options={dd_education}
                  onChange={item => handleInput(item.label, 'request_education')}
                />
                <InputError message={formFields.request_educationError}/>
              </div>

              <div className={css.inputRow}>
                <div className={css.inputLabel}>Профессия *</div>
                <Input
                  className={css.inputWrap}
                  inputClassName={css.input}
                  handleInput={(value) => handleInput(value, 'request_profession')}
                  value={formFields.request_profession}
                  name={'request_profession'}
                  id={'request_profession'}
                />
                <InputError message={formFields.request_professionError}/>
              </div>

              <div className={css.inputRow}>
                <div className={css.inputTitle}>Стаж *</div>
                <Dropdown
                  value={formFields.request_experience}
                  placeholder="Выбрать"
                  options={dd_experience}
                  onChange={item => handleInput(item.label, 'request_experience')}
                />
                <InputError message={formFields.request_experienceError}/>
              </div>

              <div className={css.inputRow}>
                <div className={css.inputTitle}>Тип занятости в ЕЖЭК</div>
                <RadioGroup
                  name="request_busy_type"
                  onChange={(value) => handleInput(value, 'request_busy_type')}
                  selectedValue={formFields.request_busy_type}
                >
                  <div className={css.radioButtons}>
                    <Radio value="FULL" label="Основная занятость"/>
                    <Radio value="PART" label="Подработка"/>
                  </div>
                </RadioGroup>
                <InputError message={formFields.request_busy_typeError}/>
              </div>
            </div>

            <div className={css.inputColumn}>
              <div className={css.inputRow}>
                <div className={css.inputLabel}>Номер телефона *</div>
                <Input
                  className={css.inputWrap}
                  placeholder={phonePlaceholder}
                  inputClassName={css.input}
                  handleInput={(value) => handleInput(toPhonePattern(value), 'phone')}
                  value={formFields.phone}
                  name={'phone'}
                  id={'phone'}
                />
                <InputError message={formFields.phoneError}/>
              </div>

              <div className={css.inputRow}>
                <div className={css.inputLabel}>Электроная почта *</div>
                <Input
                  className={css.inputWrap}
                  inputClassName={css.input}
                  handleInput={(value) => handleInput(value, 'email')}
                  value={formFields.email}
                  name={'email'}
                  id={'email'}
                />
                <InputError message={formFields.emailError}/>
              </div>

              <div className={css.inputRow}>
                <div className={css.inputTitle}>Желаемый район выполнения заказа *</div>
                <Dropdown
                  value={dd_regions.find(r => r.value === formFields.request_region) || formFields.request_region}
                  placeholder="Выбрать"
                  options={dd_regions}
                  onChange={item => handleInput(item.value, 'request_region')}
                />
                <InputError message={formFields.request_regionError}/>
              </div>

              <div className={css.inputRow}>
                <div className={css.inputLabel}>Краткая информация о себе</div>
                <Input
                  className={css.inputWrap}
                  inputClassName={css.textarea}
                  handleInput={(value) => handleInput(value, 'request_about')}
                  value={formFields.request_about}
                  name={'request_about'}
                  id={'request_about'}
                  textArea={true}
                  rows={3}
                />
                <InputError message={formFields.request_aboutError}/>
              </div>

              <div className={css.inputRow}>
                <div className={css.inputLabel} style={{fontWeight: 'bold'}}>Сообщение для администратора ЕЖЭК</div>
                <Input
                  className={css.inputWrap}
                  inputClassName={css.textarea}
                  handleInput={(value) => handleInput(value, 'request_message')}
                  value={formFields.request_message}
                  name={'request_message'}
                  id={'request_message'}
                  textArea={true}
                  rows={3}
                />
                <InputError message={formFields.request_messageError}/>
              </div>

              <div className={css.inputRow}>
                <Checkbox
                  name={'isAgreeWithTerms'}
                  checked={formFields.is_agree}
                  handleChange={(value) => handleInput(value, 'is_agree')}
                  className={css.isAgreeCheckbox}
                  label={
                    <span>
                      {'Я согласен с условиями '}
                      <SmartLink
                        to={offer}
                        theme={'green'}
                      >
                        Оферты
                      </SmartLink>
                      {' и обработкой моих персональных данных.'}
                    </span>
                  }
                />
                <InputError message={formFields.is_agreeError}/>
              </div>

              <div className={css.inputRow}>
                <Button
                  theme={'green'}
                  className={css.button}
                  contentClassName={css.buttonText}
                  isBusy={isLoading}
                  type="submit"
                >
                  Отправить
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}

      {isModalOpen && <OutOfAgeModal closeModal={() => handleIsModalOpen(false)}/>}
      <Footer/>
    </div>
  )
}

function OutOfAgeModal({closeModal}) {
  return (
    <ModalWrapper
      shouldUseOffset
      name={'outOfAgeRange'}
      onCloseClick={closeModal}
      title={'Пожалуйста, свяжитесь с нами по телефону'}
      iconClose
    >
      <div className={css.ageMessage}>Вы указали ваш возраст менее 20 лет или более 65</div>
      <div>
        <Button
          theme={'green'}
          onClick={closeModal}
          className={css.ageButton}
        >
          Понятно
        </Button>
      </div>
    </ModalWrapper>
  )
}

const mapStateToProps = (state) => ({
  master_metadata: selectOrderField('master_metadata', state),
})

export default pageConnector(MastersRequest, {
  asyncLoaders: [masterMetadataLoader],
  mapStateToProps,
  withRouter: true,
})
