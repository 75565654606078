import React from 'react'
import T from 'prop-types'

import css from './index.styl'

export default function AddressError({ isAddressError }) {
  return isAddressError && <p className={css.addressError}>Введенный адрес не найден</p>
}

AddressError.propTypes = {
  AddressError: T.bool,
}
AddressError.defaultProps = {
  AddressError: false,
}
