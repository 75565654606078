import React, {useEffect} from 'react'

import Footer from 'app/Elements/Footer'
import ParagraphBlock from 'app/Elements/ParagraphBlock'
import SmartLink from 'app/Elements/SmartLink'
import Button from 'app/Elements/Button'

import css from './index.styl'

const p1Title = 'СТАНДАРТЫ ЕЖЭК ПО ОБСЛУЖИВАНИЮ ЗАКАЗЧИКОВ'
const p1Items = [
  'При аккредитации Мастеров ЕЖЭК определяет стартовый рейтинг мастеров по следующим шести параметрам: приветливость, опрятность, оснащенность, техническая грамотность, стоимость, общая удовлетворенность заказом. По этим же параметрам Мастера оценивают Заказчики по итогам выполнения заказов. Последний параметр отражает субъективное мнение Заказчика, тогда как первые пять можно четко определить.',
  'Для того, чтобы быть допущенным для выполнения заказов ЕЖЭК, мастер обязан соответствовать нижеприведенным стандартам, определяющим указанные первые пять параметров. Кроме того, максимальное соблюдение данных стандартов необходимо для получения максимального рейтинга у заказчиков и, следовательно, получения самых прибыльных заказов.',
]

const p2Title = 'I. Приветливость:'
const p2Items = [
  'Мастер должен демонстрировать коммуникабельность, позитивный настрой и заинтересованность в успехе выполнения заказа с первой минуты встречи с заказчиком.',
  'При входе в квартиру заказчика, мастер проводит с ним вступительную беседу не более 2-3х минут, включая представление себя, сервиса ЕЖЭК, краткое обсуждение фронта работ и пожеланий заказчика.',
  'Все последующие разговоры с заказчиком ведутся только по теме работ и не более 5 минут. В случае выраженного желания заказчика пообщаться подольше, необходимо корректно напомнить ему, что время мастера – это деньги заказчика и разговоры увеличат стоимость выполнения заказа.',
  'Во время нахождения на заказе мастер не может пользоваться телефоном кроме случаев крайней необходимости. В последнем случае мастер должен выйти из квартиры для разговора.',
  'Мастер не должен вести никаких разговоров о других заказах или заказчиках в присутствии клиента в связи с необходимостью соблюдать конфиденциальность персональных данных других заказчиков.',
  'Мастер должен демонстрировать лояльность сервису ЕЖЭК и ни в коем случае не критиковать его в присутствии заказчика.',
  'В конце работы мастер должен спросить заказчика, чем еще он может быть полезен. Перед уходом мастер должен попрощаться, пожелать заказчику хорошего дня и сказать: «Я надеюсь, что оправдал Ваши ожидания и Вы обратитесь наш сервис в дальнейшем».',
  'В случае возникновения конфликтной ситуации на заказ вызывается диспетчер (комендант) ЕЖЭК или вахтовый эксперт, оценивает проведенные работы и помогает урегулировать конфликт.',
]

const p3Title = 'II. Опрятность:'
const p3Items = [
  'Приходя к заказчику, мастер должен быть опрятно одет, а также иметь инструменты, материалы и запчасти в чистом и опрятном виде, а именно:<br/>- Выглядеть презентабельно,<br/>- Не иметь неприятного запаха,<br/>- Снимать обувь при входе и использовать сменную обувь или бахилы, надеваемые на носки (с целью защиты носков от грязи, а пола от следов от носков).',
  'Мастер может быть одет или переодеться в подходящую спецодежду для работы, которая должна быть чистой.',
  'Перед началом выполнения заказа мастер при необходимости моет руки.',
  'Инструменты, материалы и запчасти должны переносится в опрятной сумке или рюкзаке и быть чистыми и разложенными в порядке по размеру и легко находимыми. Логотипы других профильных компаний на одежде, либо инструментах не допускаются.',
  'Выкладывая инструменты, мастер должен использовать чистую подстилку. Мастер должен спросить Заказчика, можно ли расположить инструменты на подстилке на предметах мебели или на полу.',
  'В процессе сверления и штробления мастер пользуется пылесосом для сбора появляющейся пыли.',
  'Закончив работу, мастер обязан тщательно убрать место работы, забрав с собой весь мусор, возникший в хоте ее реализации, оставив место работы в первозданном виде или чище, чем было до начала работы.',
  'Материалы и техника заказчика, использовавшаяся при выполнении заказа, должны остаться в том же виде и порядке, что до использования мастером (например, пылесос протирается и складывается и т.д.). В случае выявления просроченных средств или технически неисправной техники Заказчика мастер должен своевременно сообщить заказчику об этом. Использование таких средств и техники не допускается.',
]

const p4Title = 'III. Оснащенность:'
const p4Items = [
  'Мастер обязан иметь при себе комплект инструментов, расходных материалов и запчастей, рекомендованных ЕЖЭК.',
  'Указанные предметы должны находится в порядке и быть исправными.',
  'Специальные средства, в частности, чистящие средства, должны быть обязательно в оригинальной упаковке с не истекшим сроком годности. Использовать для работы средства в неоригинальных упаковках не допускается.',
  'Дополнительные инструменты, материалы и запчасти, необходимые для выполнения конкретного заказа, мастер может приобрести в одном из близлежащих магазинов. При наличии нескольких магазинов, среди которых есть магазины, рекомендованные ЕЖЭК, предпочтение отдается магазину, рекомендованному ЕЖЭК. Мастер обязан знать местонахождение магазинов и какой из них ближе всего находится к месту заказа, изучив этот вопрос заранее. По окончании выполнения работ, оплаты заказа и проведения соответствующих действий через приложение, мастер также должен получить компенсацию за использованные материалы и запчасти, передав заказчику документы, подтверждающие их стоимость.',
  'В случае, если выполнение заказа необходимо отложить из-за недостатка специальных инструментов, материалов или запчастей, которые нельзя купить в близлежащих хозяйственных магазинах, мастер рассказывает заказчику, какие именно предметы необходимо купить, рекомендует место приобретения или помогает их заказать через сеть Интернет.',
]

const p5Title = 'IV. Техническая грамотность:'
const p5Items = [
  'Мастер должен демонстрировать знание технологии выполнения заказа с первой минуты встречи с заказчиком. Технология проведения уборки отражена в Приложении №1 ниже.',
  'Перед началом работы мастер проводит визуальную диагностику предмета заказа, фронта работ и достаточности материалов, запчастей и инструментов для их выполнения.',
  'Затем он объясняет заказчику, что именно он планирует сделать и зачем. При этом оговариваются примерные сроки выполнения работы и их стоимость.',
  'Во время выполнения заказа мастер должен работать быстро, качественно, не отвлекаться и соблюдать технологию и безопасность труда.',
  'Перед окончанием заказа мастер должен сдать работу заказчику, показав и рассказав, что и как было сделано, а также проверить при заказчике результат выполненной работы.',
  'Мастер должен также проконсультировать заказчика по дальнейшей эксплуатации предмета заказа и как избежать его дальнейшей порчи.',
  'При выполнении сложных/скрытых работ необходимо сфотографировать объект заказа до и после проведённых работ.',
  'В случае возникновения технически сложной ситуации на заказ приходит диспетчер (комендант) ЕЖЭК или вахтовый эксперт, оценивает сложности и помогает их устранить.',
]

const p6Title = 'V. Стоимость:'
const p6Items = [
  'Мастер не может принимать от заказчика деньги за невыполненную работу, кроме аванса на недостающие материалы, запчасти или инструменты, которые мастер может купить в ближайшем магазине.',
  'Перекуры и перерывы во время выполнения заказа не допускаются.  У заказчика не должно остаться впечатление, что мастер каким-либо образом пытается растянуть время заказа.',
]

const p7Title = 'ПРИЛОЖЕНИЕ №1: ИНСТРУКЦИЯ ПО УБОРКЕ'
const p7Subtitle = 'ОБЩИЕ ПРИНЦИПЫ:'

const p8Title = 'I. До начала уборки:'
const p8Items = [
  'Выясняются ограничения и пожелания заказчика, например, аллергии и ограничения на использование отдельных средств, требования к уходу за специфическими поверхностями. Например, паркет, покрытый маслом, как и поверхности из натуральной кожи подлежат только сухой уборке, порядок взаимодействия с членами семьи, в особенности, с детьми, пожилыми людьми и домашними животными, присутствующими при уборке.',
  'Помещения осматриваются на предмет их размера, наличия явных повреждений и пятен на всех поверхностях и мягкой мебели. С заказчиком оговаривается то, что мастер не несёт ответственности за работу с повреждёнными предметами и при удалении пятен.',
  'С заказчиком также оговариваются ограничения по времени работы, имеющиеся со стороны заказчика и мастера, а также приоритетные части помещений для уборки в случае недостатка времени. Согласовывается подход, что при недостатке времени тщательно убираются приоритетные части квартиры, а задача убрать всю квартиру в ущерб качеству не преследуется.',
  'Мойка посуды, картин, не защищённых стеклом, хрупких и деликатных предметов, морозильной камеры холодильника, глажка, покупка продуктов, приготовление пищи, выемка книг и папок с документами из полок и оптимизация их расстановки, уборка одежды в гардеробе, документов на столах, в ящиках и секретерах, мытье обуви не включаются в состав уборки. Об этом Заказчику необходимо сообщить перед началом работы. За выполнение данной работы мастерами по личной договоренности с Заказчиком ЕЖЭК ответственности не несёт.',
  'Мастер обязан переодеться в сменную обувь и одежду, либо фартук. Переодевание осуществляется в согласованном с заказчиком месте в течение, максимум, пяти минут. Свои вещи оставляются на время работы в опрятном компактном виде в месте, согласованном с Заказчиком, в котором они не могут повредить или испачкать имущество заказчика.',
]

const p9Title = 'II. Во время уборки:'
const p9Items = [
  'Во время уборки мастер обязан следовать контрольному списку, приведенному ниже.',
  'При недостатке времени тщательно убираются приоритетные части квартиры, задача убрать всю квартиру в ущерб качеству не преследуется.',
  'Во время работы допустимо использовать только средства и приспособления (тряпки, щетки, губки, скребки) из списка рекомендованных ЕЖЭК средств и приспособлений в маркированной фирменной упаковке. Безымянные средства и приспособления, не упомянутые в списке рекомендованных ЕЖЭК средств и приспособлений, использовать категорически запрещается. Средства и приспособления заказчика для уборки отдельных специфических поверхностей используются под ответственность заказчика и с предельной осторожностью. При первом сомнении в последствиях их применения, например, излишняя абразивность средств, которая может привести к образованию царапин, их использование прекращается. Ни мастер, ни ЕЖЭК не несут ответственности за последствия применения средств и приспособлений заказчика.',
  'Использование техники заказчика, например, пылесоса, парогенератора и т.п., осуществляется под ответственность заказчика и также с особой осторожностью. При первом сомнении в работоспособности техники ее использование прекращается. Ни мастер, ни ЕЖЭК не несут ответственности за последствия применения техники заказчика.',
]

const p10Title = 'III. По окончании уборки:'
const p10Items = [
  'После окончания работы мастер оставляет место, где хранились его собственные вещи, в идеальном порядке.',
  'Средства, приспособления и техника заказчика возвращаются ему в первоначальном состоянии: средства передаются в чистых бутылках, приспособления передаются в выстиранном или иным образом промытом виде, щетка пылесоса передаётся в чистом виде, для чего ее рекомендуется пропылесосить трубкой без щетки, сам пылесос протерт влажной тряпкой.',
]

const p11Subtitle = 'КОНТРОЛЬНЫЙ СПИСОК:'
const p11Items = [
  `<table>
	<tbody>
		<tr>
			<th>ПОМЕЩЕНИЕ, № П/П</th>
			<th>ОБЪЕКТ УБОРКИ</th>
			<th>РАБОТЫ</th>
		</tr>
		<tr>
			<th colspan="3">1. КУХНЯ</th>
		</tr>
		<tr>
			<td>1.1.</td>
			<td>Сантехника, форсунки и пробки раковин</td>
			<td>Сантехника вымыта и вытерта насухо идеально без разводов и следов кальция, включая швы и соединения. Подъемные водные краны промыты в открытом и закрытом состоянии. Форсунки и пробки раковин как и особо заизвесткованные части сантехники очищены от кальция посредством замачивания в средстве от известкового налета или в столовом уксусе.</td>
		</tr>
		<tr>
			<td>1.2.</td>
			<td>Верх, низ (горизонтальные поверхности под верхними шкафами), фасады, столешница, низ свесов столешницы и бордюры (вертикальная поверхность под шкафами, закрывающая промежуток между шкафом и полом)  шкафов, боковые поверхности шкафов и электроприборов	Все указанные в графе «Объект уборки» поверхности тщательно вымыты и вытерты насухо идеально без разводов и следов грязи, жира и моющих средств. Для контроля качества уборки и отсутствия следов грязи, пыли и разводов следует изучить вымытые поверхности под различными углами зрения и при различном освещении.</td>
			<td>Все указанные в графе «Объект уборки» поверхности тщательно вымыты и вытерты насухо идеально без разводов и следов грязи, жира и моющих средств. Для контроля качества уборки и отсутствия следов грязи, пыли и разводов следует изучить вымытые поверхности под различными углами зрения и при различном освещении.</td>
		</tr>
		<tr>
			<td>1.3.</td>
			<td>Духовка</td>
			<td>Духовка снаружи и внутри вымыта и вытерта идеально без следов нагара, грязи, жира и кальция.</td>
		</tr>
		<tr>
			<td>1.4.</td>
			<td>Микроволновая печь</td>
			<td>Микроволновая печь снаружи и внутри вымыта и вытерта идеально без следов нагара, грязи, жира и кальция.</td>
		</tr>
		<tr>
			<td>1.5.</td>
			<td>Вытяжка и фильтры</td>
			<td>Вытяжка снаружи вымыта и вытерта насухо идеально без разводов грязи, жира и кальция; фильтры промыты, высушены и вставлены на место.</td>
		</tr>
		<tr>
			<td>1.6.</td>
			<td>Фартук – плитка или другое покрытие на стенах над столешницей кухни и над и по бокам плиты</td>
			<td>Фартук кухни вымыт идеально без малейших следов пригоревшей или иной грязи и жира и вытерт насухо идеально.</td>
		</tr>
		<tr>
			<td>1.7.</td>
			<td>Подоконники</td>
			<td>Подоконники вымыты и вытерты насухо без разводов и следов грязи и пыли.</td>
		</tr>
		<tr>
			<td>1.8.</td>
			<td>Холодильник снаружи (сверху, спереди и с боков) и внутри, включая все горизонтальные и вертикальные стенки, полки, балконы и лотки</td>
			<td>Холодильник снаружи (сверху, спереди и с боков) и внутри вымыт, включая все горизонтальные и вертикальные стенки, полки, балконы и лотки и вытерт насухо без разводов и следов грязи. Содержимое холодильника вынимается перед уборкой и после нее размещается обратно строго в первоначальном порядке. По согласованию с Заказчиком явно испорченные продукты выбрасываются. Внутренние детали холодильника вынимаются для мытья и правильно вставляются обратно после просушки. Мойка морозильной камеры не включается в состав уборки. Об этом Заказчику необходимо сообщить перед началом работы. За выполнение данной работы мастерами по личной договоренности с Заказчиком ЕЖЭК ответственности не несёт.</td>
		</tr>
		<tr>
			<td>1.9.</td>
			<td>Шкафы, включая все горизонтальные и вертикальные поверхности и дверцы</td>
			<td>Шкафы вымыты внутри, включая все горизонтальные и вертикальные поверхности и дверцы. Для уборки содержимое вынимается и при необходимости протирается и высушивается без следов грязи, жира и кальция, а после просушки расставляется обратно строго в том же порядке, как до уборки. По согласованию с Заказчиком расстановка содержимого шкафов может быть оптимизирована для порядка и экономии пространства.</td>
		</tr>
		<tr>
			<td>1.10.</td>
			<td>Трубы отопления и радиаторы</td>
			<td>Трубы отопления и радиаторы вымыты и вытерты снаружи и внутри со всех сторон (сверху, спереди, с боков, сзади и снизу) без следов грязи, пыли и разводов от кальция.</td>
		</tr>
		<tr>
			<td>1.11.</td>
			<td>Стулья, включая перекладины и нижнюю часть ножек</td>
			<td>Стулья вымыты и вытерты насухо, включая перекладины и нижнюю часть ножек без следов пыли. Подушки для уборки снимаются, а после уборки и просушки стульев возвращаются на место строго в первоначальном порядке. По желанию Заказчика подушки не возвращаются на стулья, а оставляются для стирки.</td>
		</tr>
		<tr>
			<td>1.12.</td>
			<td>Стекла в шкафах и дверях</td>
			<td>Стекла в шкафах и дверях вымыты и высушены идеально без следов грязи, жира и разводов, включая края и стыки.</td>
		</tr>
		<tr>
			<td>1.13.</td>
			<td>Окна</td>
			<td>Окна вымыты, включая рамы внутри и снаружи, а при наличии двойных окон и между ними тоже, откосы снаружи, боковые стенки окон внутри и снаружи и между двойными окнами идеально без следов грязи, включая стыки и края. Стекла вымыты и высушены идеально, предпочтительно с использованием скребка, без следов грязи, включая пятна краски, уличной грязи, разводов при различном угле попадания света.</td>
		</tr>
		<tr>
			<td>1.14.</td>
			<td>Двери, включая рельеф и ручки</td>
			<td>Двери вымыты и высушены идеально, включая рельеф и ручки, без следов грязи, пыли, жира и разводов.</td>
		</tr>
		<tr>
			<td>1.15.</td>
			<td>Выключатели и розетки, включая выступающие боковые, верхнюю и нижнюю части</td>
			<td>Выключатели и розетки вымыты и высушены идеально, включая выступающие боковые, верхнюю и нижнюю части; выключатели промыты во включённом и выключенном состоянии. Выключатели и розетки моются с предельной осторожностью; попадание воды на внутреннюю часть крайне опасно в связи с возможностью возникновения короткого замыкания, возгорания или иных поломок и поэтому недопустимо.</td>
		</tr>
		<tr>
			<td>1.16.</td>
			<td>Люстры и иные светильники, кухонные бытовые электроприборы – чайник, тостер, кофеварка, соковыжималка и пр.</td>
			<td>Люстры и иные светильники, а также бытовые электроприборы вымыты снаружи и высушены. Они моются в режиме обесточивания с предельной осторожностью; попадание воды на части, сопряжённые с электропроводкой, крайне опасно в связи с возможностью возникновения короткого замыкания, возгорания или иных поломок и поэтому недопустимо.</td>
		</tr>
		<tr>
			<td>1.17.</td>
			<td>Посуда</td>
			<td>Посуда не включается в состав уборки и поэтому не моется. Об этом Заказчику необходимо сообщить перед началом работы. За выполнение данной работы мастерами по личной договоренности с Заказчиком ЕЖЭК ответственности не несёт.</td>
		</tr>
		<tr>
			<td>1.18.</td>
			<td>Глажка</td>
			<td>Глажка не включается в состав уборки и поэтому не производится. Об этом Заказчику необходимо сообщить перед началом работы. За выполнение данной работы мастерами по личной договоренности с Заказчиком ЕЖЭК ответственности не несёт.</td>
		</tr>
		<tr>
			<td>1.19.</td>
			<td>Предметы декора, картины</td>
			<td>Предметы декора протерты и высушены идеально. Картины, не защищённые стеклом, хрупкие и деликатные предметы в состав уборки не включаются и по этому не моются. За выполнение данной работы мастерами по личной договоренности с Заказчиком ЕЖЭК ответственности не несёт.</td>
		</tr>
		<tr>
			<td>1.20.</td>
			<td>Все металлические детали, включая вытяжку, раковину, фасады шкафов, выключатели и т.п.</td>
			<td>Все металлические детали, включая вытяжку, раковину, фасады шкафов, выключатели и т.п. вымыты и высушены идеально без разводов и следов грязи, жира и кальция. Для контроля качества уборки и отсутствия следов грязи, пыли и разводов следует изучить вымытые поверхности под различными углами зрения и при различном освещении.</td>
		</tr>
		<tr>
			<td>1.21.</td>
			<td>Посудомоечная машина</td>
			<td>Посудомоечная машина вымыта и вытерта снаружи идеально без следов грязи, жира и разводов. Внутренняя поверхность дверцы и ее боковые части, включая места соединений и контейнер для моющего средства (таблеток) тщательно очищены от грязи, вымыты и вытерты. Работы с содержимым машины и ее запуск не входит в уборку. Об этом Заказчику необходимо сообщить перед началом работы. За выполнение данной работы мастерами по личной договоренности с Заказчиком ЕЖЭК ответственности не несёт.</td>
		</tr>
		<tr>
			<td>1.22.</td>
			<td>Стиральная машина</td>
			<td>Стиральная машина вымыта и вытерта снаружи идеально без следов грязи, жира и разводов. Также тщательно очищены люк, резина и лоток для средств. Для очистки лотка он вынимается из машины при помощи Заказчика. Самостоятельная выемка и вставление лотка может привести к его повреждению и поэтому недопустимы.  Резину следует очищать по всему кругу внутри мягкой неабразивной тряпкой, смоченной водой.  Работы с содержимым машины и ее запуск и/или разгрузка не входит в уборку. Об этом Заказчику необходимо сообщить перед началом работы. За выполнение данной работы мастерами по личной договоренности с Заказчиком ЕЖЭК ответственности не несёт.</td>
		</tr>
		<tr>
			<th colspan="3">2. САНУЗЕЛ</th>
		</tr>
		<tr>
			<td>2.1.</td>
			<td>Сантехника, форсунки и пробки раковин</td>
			<td>Сантехника вымыта и вытерта насухо идеально без разводов и следов кальция, включая швы соединений. Подъемные водные краны промыты в открытом и закрытом состоянии. Форсунки и пробки раковин как и особо заизвесткованные части сантехники очищены от кальция посредством замачивания в средстве от известкового налета или в столовом уксусе.</td>
		</tr>
		<tr>
			<td>2.2.</td>
			<td>Унитаз, сиденье и его крышка, держатель щетки для унитаза</td>
			<td>Унитаз, сиденье и его крышка вымыты и вытерты идеально без следов грязи, ржавчины и кальция. Для сиденья из пластика следует использовать неагрессивные средства, например, средство для мытья стекол, во избежание повреждений деликатного покрытия. Держатель щетки для унитаза вымыт идеально, в нем отсутствует грязь и жидкость.</td>
		</tr>
		<tr>
			<td>2.3.</td>
			<td>Ванна</td>
			<td>Ванна вымыта и высушена идеально. Акриловые ванны моются при помощи специальных неабразивных средств и приспособлений, с особой аккуратностью во избежание повреждения их деликатной поверхности. Плесень вокруг ванны удалена при помощи специального средства. Механическое удаление плесени опасно для здоровья мастера и чревато повреждением поверхностей, изоляции и затирки швов и поэтому недопустимо.</td>
		</tr>
		<tr>
			<td>2.4.</td>
			<td>Зеркала и стекла в шкафах и дверях</td>
			<td>Зеркала и стекла в шкафах и дверях вымыты и высушены идеально без следов грязи и разводов, включая края и стыки. Для контроля качества уборки и отсутствия следов грязи, пыли и разводов следует изучить вымытые поверхности под различными углами зрения и при различном освещении.</td>
		</tr>
		<tr>
			<td>2.5.</td>
			<td>Плитка и швы между ней</td>
			<td>Плитка и швы между ней вымыты и вытерты идеально без следов грязи, кальция. Для очистки сильных загрязнений используется зубная или другая маленькая щетка.</td>
		</tr>
		<tr>
			<td>2.6.</td>
			<td>Светильники и другие электроприборы, например, электрорадиатор, фен, электробритва</td>
			<td>Светильники и другие электроприборы вымыты со всех сторон (в том числе сверху, снизу и с боков) и высушены. Они моются в режиме обесточивания с предельной осторожностью; попадание воды на части, сопряжённые с электропроводкой, крайне опасно в связи с возможностью возникновения короткого замыкания, возгорания или иных поломок и поэтому недопустимо.</td>
		</tr>
		<tr>
			<td>2.7.</td>
			<td>Трубы отопления, радиаторы и полотенцесушители</td>
			<td>Трубы отопления, радиаторы и полотенцесушители вымыты и вытерты снаружи и внутри со всех сторон (сверху, спереди, с боков, сзади и снизу) без следов грязи, пыли и разводов от кальция.</td>
		</tr>
		<tr>
			<td>2.8.</td>
			<td>Шкафы, включая все горизонтальные и вертикальные поверхности и дверцы</td>
			<td>Шкафы, включая открытые и закрытые, вымыты снаружи и внутри, включая все горизонтальные и вертикальные поверхности и дверцы. Для уборки содержимое вынимается и при необходимости протирается и высушивается без следов грязи и кальция, а после просушки расставляется обратно строго в том же порядке, как до уборки. По согласованию с Заказчиком расстановка содержимого шкафов может быть оптимизирована для порядка и экономии пространства.</td>
		</tr>
		<tr>
			<td>2.9.</td>
			<td>Предметы декора</td>
			<td>Предметы декора протерты и высушены идеально. Хрупкие и деликатные предметы предметы в состав уборки не включаются и по этому не моются. За выполнение данной работы мастерами по личной договоренности с Заказчиком ЕЖЭК ответственности не несёт.</td>
		</tr>
		<tr>
			<td>2.10.</td>
			<td>Стиральная машина</td>
			<td>Стиральная машина вымыта и вытерта снаружи идеально без следов грязи, жира и разводов. Также тщательно очищены люк, резина и лоток для средств. Для очистки лотка он вынимается из машины при помощи Заказчика. Самостоятельная выемка и вставление лотка может привести к его повреждению и поэтому недопустимы.  Резину следует очищать по всему кругу внутри мягкой неабразивной тряпкой, смоченной водой.  Работы с содержимым машины и ее запуск и/или разгрузка не входит в уборку. Об этом Заказчику необходимо сообщить перед началом работы. За выполнение данной работы мастерами по личной договоренности с Заказчиком ЕЖЭК ответственности не несёт.</td>
		</tr>
		<tr>
			<td>2.11.</td>
			<td>Двери, включая рельеф и ручки</td>
			<td>Двери вымыты и высушены идеально, включая рельеф и ручки, без следов грязи, пыли, жира и разводов.</td>
		</tr>
		<tr>
			<td>2.12.</td>
			<td>Выключатели и розетки, включая выступающие боковые, верхнюю и нижнюю части</td>
			<td>Выключатели и розетки вымыты и высушены идеально, включая выступающие боковые, верхнюю и нижнюю части; выключатели промыты во включённом и выключенном состоянии. Выключатели и розетки моются с предельной осторожностью; попадание воды на внутреннюю часть крайне опасно в связи с возможностью возникновения короткого замыкания, возгорания или иных поломок и поэтому недопустимо.</td>
		</tr>
		<tr>
			<td>2.13.</td>
			<td>Все металлические детали, включая фасады шкафов, выключатели и т.п.</td>
			<td>Все металлические детали, включая фасады шкафов, выключатели и т.п. вымыты и высушены идеально без разводов и следов грязи, жира и кальция. Для контроля качества уборки и отсутствия следов грязи, пыли и разводов следует изучить вымытые поверхности под различными углами зрения и при различном освещении.</td>
		</tr>
		<tr>
			<th colspan="3">3. ХОЛЛ И КОМНАТЫ</th>
		</tr>
		<tr>
			<td>3.1.</td>
			<td>Книги и полки</td>
			<td>Книги и полки обработаны пылесосом и протерты влажной тряпкой от пыли идеально без ее остатков и катышков, включая верх, низ, задние и боковые стенки полок, переплеты и поверхности под и между полками. Выемка книг и папок с документами из полок и оптимизация их расстановки в уборку не входит. Об этом Заказчику необходимо сообщить перед началом работы. За выполнение данной работы мастерами по личной договоренности с Заказчиком ЕЖЭК ответственности не несёт.</td>
		</tr>
		<tr>
			<td>3.2.</td>
			<td>Предметы декора, картины</td>
			<td>Предметы декора протерты и высушены идеально. Картины, не защищённые стеклом, хрупкие и деликатные предметы в состав уборки не включаются и по этому не моются. За выполнение данной работы мастерами по личной договоренности с Заказчиком ЕЖЭК ответственности не несёт.</td>
		</tr>
		<tr>
			<td>3.3.</td>
			<td>Люстры и светильники</td>
			<td>Люстры и светильники вымыты со всех сторон (в том числе сверху, снизу и с боков)  и высушены. Они моются в режиме обесточивания с предельной осторожностью; попадание воды на части, сопряжённые с электропроводкой, крайне опасно в связи с возможностью возникновения короткого замыкания, возгорания или иных поломок и поэтому недопустимо.</td>
		</tr>
		<tr>
			<td>3.4.</td>
			<td>Зеркала и стекла в шкафах и дверях</td>
			<td>Зеркала и стекла в шкафах и дверях вымыты и высушены идеально без следов грязи и разводов, включая края и стыки.  Для контроля качества уборки и отсутствия следов грязи, пыли и разводов следует изучить вымытые поверхности под различными углами зрения и при различном освещении.</td>
		</tr>
		<tr>
			<td>3.5.</td>
			<td>Шкафы, включая все горизонтальные и вертикальные поверхности и дверцы</td>
			<td>Шкафы, включая открытые и закрытые, вымыты снаружи и внутри, включая все горизонтальные и вертикальные поверхности и дверцы. Для уборки содержимое вынимается и при необходимости протирается и высушивается без следов грязи и кальция, а после просушки расставляется обратно строго в том же порядке, как до уборки. По согласованию с Заказчиком расстановка содержимого шкафов может быть оптимизирована для порядка и экономии пространства. Уборка одежды в гардеробах и документов на столах и в секретерах не включаются в состав уборки и поэтому не производятся. Об этом Заказчику необходимо сообщить перед началом работы. За выполнение данной работы мастерами по личной договоренности с Заказчиком ЕЖЭК ответственности не несёт.</td>
		</tr>
		<tr>
			<td>3.6.</td>
			<td>Подоконники</td>
			<td>Подоконники вымыты и вытерты насухо без разводов и следов грязи и пыли.</td>
		</tr>
		<tr>
			<td>3.7.</td>
			<td>Трубы отопления и радиаторы</td>
			<td>Трубы отопления и радиаторы вымыты и выверты снаружи и внутри со всех сторон (сверху, спереди, с боков, сзади и снизу) без следов грязи, пыли и разводов от кальция.</td>
		</tr>
		<tr>
			<td>3.8.</td>
			<td>Двери, включая рельеф и ручки</td>
			<td>Двери вымыты и высушены идеально, включая рельеф и ручки, без следов грязи, пыли, жира и разводов.</td>
		</tr>
		<tr>
			<td>3.9.</td>
			<td>Выключатели и розетки, включая выступающие боковые, верхнюю и нижнюю части</td>
			<td>Выключатели и розетки вымыты и высушены идеально, включая выступающие боковые, верхнюю и нижнюю части; выключатели промыты во включённом и выключенном состоянии. Выключатели и розетки моются с предельной осторожностью; попадание воды на внутреннюю часть крайне опасно в связи с возможностью возникновения короткого замыкания, возгорания или иных поломок и поэтому недопустимо.</td>
		</tr>
		<tr>
			<td>3.10.</td>
			<td>Все металлические детали, включая полки, перила лестниц, рамки картин, фасады шкафов и т.п.</td>
			<td>Все металлические детали, включая полки, перила лестниц, рамки картин, фасады шкафов и т.п. вымыты и высушены идеально без разводов и следов грязи и кальция. Для контроля качества уборки и отсутствия следов грязи, пыли и разводов следует изучить вымытые поверхности под различными углами зрения и при различном освещении.</td>
		</tr>
		<tr>
			<td>3.11.</td>
			<td>Мягкая мебель и кровати</td>
			<td>Мягкая мебель обработана пылесосом, включая все подушки, под и между подушками и частями матраса, ворс приглажен в одном направлении, чтоб на мебели не было видно разводов. Пятна на мягкой мебели удалены только при наличии подходящего средства, но в любом случае без гарантии мастера и/ или ЕЖЭК, о чем заказчику следует сообщить перед началом уборки. Кровати и пледы застелены, декоративные подушки аккуратно разложены в привычном заказчику порядке. Также убрано за и под мягкой мебелью и кроватями, для чего при возможности и при отсутствии риска повредить половое покрытие их следует отодвинуть от стен на достаточное для уборки расстояние.</td>
		</tr>
		<tr>
			<td>3.12.</td>
			<td>Окна</td>
			<td>Окна вымыты, включая рамы внутри и снаружи, а при наличии двойных окон и между ними тоже, откосы снаружи, боковые стенки окон внутри и снаружи и между двойными окнами идеально без следов грязи, включая стыки и края. Плесень удалена при помощи специального средства. Механическое удаление плесени опасно для здоровья мастера и чревато повреждением поверхностей и изоляции и поэтому недопустимо. Стекла вымыты и высушены идеально, предпочтительно с использованием скребка, без следов грязи, включая пятка краски, уличной грязи, разводов при различном угле попадания света.</td>
		</tr>
		<tr>
			<td>3.13.</td>
			<td>Мытьё обуви</td>
			<td>Мытьё обуви не включается в состав уборки и поэтому не производится. Об этом Заказчику необходимо сообщить перед началом работы. За выполнение данной работы мастерами по личной договоренности с Заказчиком ЕЖЭК ответственности не несёт.</td>
		</tr>
		<tr>
			<td>3.14.</td>
			<td>Полы</td>
			<td>Полы вымыты (с использованием универсального средства из списка ЕЖЭК) и высушены, в том числе под свободнолежащими (не прикреплёнными) ковровыми покрытиями идеально. При мытье полов из дерева или плитки допустимо использовать специальные средства, предложенные Заказчиком. В этом случае за повреждение пола ЕЖЭК ответственности не несёт. Об этом Заказчику необходимо сообщить перед началом работы.</td>
		</tr>
		<tr>
			<td>3.15.</td>
			<td>Ковровые покрытия</td>
			<td>Ковровые покрытия очищены пылесосом, по возможности, с обеих сторон идеально. Ворс приглажен в одном направлении так, чтобы на нем не было видно разводов. Пятна на ковровых покрытиях удалены только при наличии подходящего средства, но в любом случае без гарантии ЕЖЭК, о чем заказчику следует сообщить перед началом уборки.</td>
		</tr>
	</tbody>
</table>`,
]

const p12Subtitle = 'Дата публикации: 25.01.2023 г.'


export default function CodexStandards(props) {
  useEffect(() => {
    document.title = 'СТАНДАРТЫ ЕЖЭК ПО ОБСЛУЖИВАНИЮ ЗАКАЗЧИКОВ'
  }, [props.title])

  return (
    <div className={css.wrap}>
      <div className={css.content}>
        <ParagraphBlock title={p1Title} items={p1Items} className={css.p1} />
        <ParagraphBlock title={p2Title} items={p2Items} itemsByList />
        <ParagraphBlock title={p3Title} items={p3Items} itemsByList />
        <ParagraphBlock title={p4Title} items={p4Items} itemsByList />
        <ParagraphBlock title={p5Title} items={p5Items} itemsByList />
        <ParagraphBlock title={p6Title} items={p6Items} itemsByList />
        <ParagraphBlock title={p7Title} subtitle={p7Subtitle} items={[]} itemsByList />
        <ParagraphBlock title={p8Title} items={p8Items} itemsByList />
        <ParagraphBlock title={p9Title} items={p9Items} itemsByList />
        <ParagraphBlock title={p10Title} items={p10Items} itemsByList />
        <ParagraphBlock title={''} subtitle={p11Subtitle} items={p11Items}/>
        <ParagraphBlock title={''} subtitle={p12Subtitle} items={[]} itemsByList />
      </div>
      <Footer />
    </div>
  )
}
