import React, { Component, Fragment } from 'react'
import renderRoutes from 'react-router-config/renderRoutes'
import Notifications from 'app/Layout/Notifications.connector'

//** helpers
import isClient from 'helpers/isClient'

//** layout
import PageMeta from 'app/Layout/PageMeta'
import Helmet from 'app/Layout/Helmet'

const defaultMeta = {
  title: 'Ежэк',
}

export default class Wrap extends Component {
  constructor(props) {
    super(props)

    this.state = {
      prevLocations: [],
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { location: { key: locationKey } = {} } = props
    let { prevLocations } = state

    prevLocations = [prevLocations[prevLocations.length - 1], locationKey].filter(Boolean)

    return {
      ...state,
      prevLocations,
    }
  }

  componentDidMount() {
    const body = document.body
    let timer

    setTimeout(() => {
      body.classList.remove('preload')
    }, 500)

    if (isClient) {
      window.addEventListener(
        'scroll',
        () => {
          clearTimeout(timer)

          if (!body.classList.contains('disable-hover')) {
            body.classList.add('disable-hover')
          }

          timer = setTimeout(function() {
            body.classList.remove('disable-hover')
          }, 500)
        },
        false
      )
    }
  }

  render() {
    const { route } = this.props
    const { error, stack = '', prevLocations } = this.state

    return (
      <PageMeta status={200}>
        <Fragment>
          <Helmet title={defaultMeta.title} description={defaultMeta.description} />

          {!!error ? (
            <div
              style={{ backgroundColor: '#72a4ff', color: 'white', width: '100%', cursor: 'pointer' }}
              onClick={() => this.setState({ stack: undefined, error: undefined })}
            >
              Ошибка {error} <br /> <br />
              {stack.split('\n').map((item) => (
                <div>{item}</div>
              ))}
            </div>
          ) : (
            renderRoutes(route.routes, { prevLocations })
          )}
          <Notifications />
        </Fragment>
      </PageMeta>
    )
  }
}
