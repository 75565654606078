import React, { Fragment, useState } from 'react'
import T from 'prop-types'

import Icon from 'app/Elements/Icon'
import Button from 'app/Elements/Button'

import moment from 'helpers/moment'
import { toggleOpenState } from 'helpers/order'

import cx from 'classnames'
import css from './index.styl'

const getTime = (sec) => moment.unix(sec).utc().format('HH:mm')

const OrderTimes = ({progress}) => {
  const {
      all_shop_time = 0,
      all_work_time = 0,
      initial_sum = 0,
      min_sum = '0',
      shop_sum = '0',
      total_sum = '0',
      work_sum = '0',
      loyalty_sum = 0,
      charge = 0
  } = progress
  const [isToggleOpen, handleTotalOpen] = useState(false)

  return (
    <Fragment>
      <Button
        type={'button'}
        theme={'transparent'}
        className={css.timerButton}
        onClick={toggleOpenState(isToggleOpen, handleTotalOpen)}
      >
        <Icon name={'time'} size={30} className={css.timeIcon} />

        {getTime(all_shop_time + all_work_time)}

        <span className={css.totalPrice}>
          <span>{total_sum}</span>
          <Icon name={'rouble'} size={18} className={css.roubleIcon} />
        </span>
        <Icon name={isToggleOpen ? 'dropup' : 'dropdown'} size={30} className={cx(css.totalDropdownIcon, css.dropdownIcon)} />
      </Button>

      <div className={cx(css.orderTable, !isToggleOpen && css.closed)}>

        <div className={css.total}>Итого</div>
        <div />
        <div className={cx(css.total, css.right)}>
          {total_sum}
          <Icon name={'rouble'} size={16} className={css.roubleIcon} />
        </div>

        <div className={css.line} />

        {/*<div className={css.accent}>*/}
        {/*  Первый взнос*/}
        {/*</div>*/}
        {/*<div />*/}
        {/*<div className={cx(css.accent, css.right)}>*/}
        {/*  {initial_sum}*/}
        {/*  <Icon name={'rouble'} size={18} className={css.roubleIcon} />*/}
        {/*</div>*/}

        {/*<div className={css.line} />*/}

        <div className={css.accent}>
          Минимальный платеж
          {/*<div className={css.label}>1 час работы мастера</div>*/}
        </div>
        <div />
        <div className={cx(css.accent, css.right)}>
          {min_sum}
          <Icon name={'rouble'} size={18} className={css.roubleIcon} />
        </div>

        <div className={css.line} />

        <div className={css.accent}>Выполнение заказа</div>
        <div>Время</div>
        <div className={css.right}>Сумма</div>
        <div />
        <div className={css.accent}>{`${getTime(all_work_time)} ч`}</div>
        <div className={cx(css.accent, css.right)}>
          {work_sum}
          <Icon name={'rouble'} size={18} className={css.roubleIcon} />
        </div>

        <div className={css.line} />

        <div className={css.accent}>Поход за материалами</div>
        <div>Время</div>
        <div className={css.right}>Сумма</div>
        <div />
        <div className={css.accent}>{`${getTime(all_shop_time)} ч`}</div>
        <div className={cx(css.accent, css.right)}>
          {shop_sum}
          <Icon name={'rouble'} size={18} className={css.roubleIcon} />
        </div>

        <div className={css.line} />

        <div className={css.accent}>
          Скидка-партнёр
        </div>
        <div />
        <div className={cx(css.accent, css.right)}>
          {loyalty_sum}
          <Icon name={'rouble'} size={18} className={css.roubleIcon} />
        </div>

        <div className={css.line} />

        {/* <div className={css.accent}>Материалы</div>
        <div />
        <div />
        <div>Название</div>
        <div>Кол-во</div>
        <div className={css.right}>Сумма</div>
        <div>Клей</div>
        <div className={css.accent}>00:00 ч</div>
        <div className={cx(css.accent, css.right)}>
          000
          <Icon name={'rouble'} size={18} className={css.roubleIcon} />
        </div>
        <div>Молоток</div>
        <div className={css.accent}>00:00 ч</div>
        <div className={cx(css.accent, css.right)}>
          000
          <Icon name={'rouble'} size={18} className={css.roubleIcon} />
        </div>

        <div className={css.line} /> */}
      </div>
    </Fragment>
  )
}

OrderTimes.propTypes = {
  all_shop_time: T.number,
  all_work_time: T.number,
  min_sum: T.string,
  shop_sum: T.string,
  total_sum: T.string,
  work_sum: T.string,
}

OrderTimes.defaultProps = {
  all_shop_time: 0,
  all_work_time: 0,
  min_sum: '',
  shop_sum: '',
  total_sum: '',
  work_sum: '',
}

export default OrderTimes
