import PropTypes from 'prop-types'
import React, {Component} from 'react'

//** styles
import cx from 'classnames'
import css from './index.styl'

export default class Loader extends Component {
  static propTypes = {
    className: PropTypes.string,
    ballClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
    isBig: PropTypes.bool,
  }

  render() {
    const {className, ballClassName, wrapperClassName, isBig, ...rest} = this.props

    return (
      <span {...rest} className={cx(css.wrapper, wrapperClassName)}>
        <span className={cx(css.loader, className, {[css.big]: isBig})}>
          <span className={cx(css.ball, css.first, ballClassName, {[css.big]: isBig})} />
          <span className={cx(css.ball, css.second, ballClassName, {[css.big]: isBig})} />
          <span className={cx(css.ball, css.third, ballClassName, {[css.big]: isBig})} />
        </span>
      </span>
    )
  }
}
