import React, { Fragment, useState, useEffect, useRef } from 'react'
import T from 'prop-types'

import pluralizeWord from 'helpers/strings/pluralizeWord'

import Button from 'app/Elements/Button'
import Icon from 'app/Elements/Icon'
import Image from 'app/Elements/Image'
import Map from 'app/Elements/Map'
import OrderTimes from 'app/Components/OrderTimes'
import OrderDetails from 'app/Components/OrderDetails'
import OrderStatusIcons from 'app/Components/OrderStatusIcons/index.connector'
import Attention from 'app/Components/Attention'

import getPhone from 'fetch/requests/getPhone'

import joinValues from 'helpers/strings/joinValues'
import { toggleOpenState } from 'helpers/order'

import cx from 'classnames'
import css from './index.styl'

const MASTER_GEO_MARKERS_INTERVAL = 3000
const MASTER_DISTANCE_INTERVAL = 60000

const getMasterTime = (number) => {
  return number > 0 ? `${number} ${pluralizeWord(number, 'минуту', 'минуты', 'минут')}` : "через минуту"
}

const isMasterInSearch = status => status === 'PLACED' || status === 'CONFIRMED' || status === 'IN_POOL'
const isMasterInFoundState = status => status === 'MASTER_FOUND' || status === 'IN_PLACE'
const isMasterInWorkState = status => status === 'IN_WORK' || status === 'SHOP_REQUEST' || status === 'IN_SHOP' || status === 'WORK_REQUEST'

let masterDistanceInterval = null
let masterGeoMarkersInterval = null

function getPhoneAndCall(token) {
  getPhone(token)
    .then(response => {
      const {phone} = response

      if (phone) {
        document.location.href=`tel:${phone}`
      }
    })
    .catch(error => {
      console.log(error)
    })
}

function renderMaster(props) {
  const { masterMarkers, durationMaster = 0, status, token } = props

  const masterDurationInMinutes = Math.round(durationMaster / 60)

  return masterMarkers.map(
    ({ first_name = '', last_name = '', photo, phone }, index) =>
      index === 0 && (
        <div className={css.masterFoundSection} key={index}>
          <div className={css.masterBlock} key={index}>
            <Image id={`master`} src={photo || '/assets/user.png'} className={css.masterPhoto} />
            <div>
              <div className={css.masterName}>{`Мастер ${first_name} ${last_name}`}</div>
              <div className={css.masterComment}>
                {isMasterInFoundState(status) && (
                  !!durationMaster
                    ? `Будет у вас через ${getMasterTime(masterDurationInMinutes)}`
                    : 'Прибудет в ближайшее время'
                )}
              </div>
            </div>
          </div>
          <Button
            theme={'green'}
            className={cx(css.callMasterButton, css.callButton)}
            onClick={() => getPhoneAndCall(token)}
          >
            <Icon name={'call'} size={28} className={css.callIcon} />
            <div className={cx(css.callButtonText, css.callMasterText)}>Звонок мастеру</div>
          </Button>
        </div>
      ),
  )
}

function renderMap(geoLat, geoLon, masterMarkers) {
  if (geoLat && geoLon) {
    return <Map className={css.map} startCoordinat={{ lat: +geoLat, lng: +geoLon }} masterMarkers={masterMarkers} forContacts />
  }
}

export default function InstantMaster(props) {
  const {
    radius,
    mastersNear,
    imageUrls,
    changeImageUrls,
    name,
    lastName,
    address,
    description,
    phone,
    paymentStatus,
    geoLat,
    geoLon,
    progress,
    masterMarkers,
    status,

    getDistanceMaster,
    getMasterGeoMarkers,
  } = props

  const fullName = joinValues([name, lastName], { separator: ' ' })

  const [isDetailsOpen, toggleDetails] = useState(false)
  const [isAttachOrderDetails, handleIsAttachOrderDetails] = useState(true)

  const [isMasterFound, handleIsMasterFound] = useState(false)
  const [isSearchBlock, handleIsSearchBlock] = useState(false)

  useEffect(() => {
    // Mount
    getMasterGeoMarkers()
    masterGeoMarkersInterval = setInterval(getMasterGeoMarkers, MASTER_GEO_MARKERS_INTERVAL)

    // Unmount
    return () => {
      clearInterval(masterGeoMarkersInterval)
      clearInterval(masterDistanceInterval)
    }
  }, [])

  useEffect(() => {
    if (isMasterInFoundState(status)) {
      getDistanceMaster()
      masterDistanceInterval = setInterval(getDistanceMaster, MASTER_DISTANCE_INTERVAL)
    } else {
      clearInterval(masterDistanceInterval)
    }

    if (isMasterInFoundState(status) || isMasterInWorkState(status)) {
      handleIsMasterFound(true)
    } else {
      handleIsMasterFound(false)
    }

    isMasterInSearch(status)
      ? handleIsSearchBlock(true)
      : handleIsSearchBlock(false)

    status === null
      ? handleIsAttachOrderDetails(true)
      : handleIsAttachOrderDetails(false)
  }, [status])

  return (
    <Fragment>
      {paymentStatus === 'PREORDER_PENDING'
        ? (
          <div className={css.waitingForPay}>Ожидается оплата</div>
        ) : (
          <Fragment>
            {!isMasterInSearch(status) && (
              <OrderTimes progress={progress} />
            )}
            <OrderStatusIcons />
          </Fragment>
        )
      }

      {renderMap(geoLat, geoLon, masterMarkers)}

      {isSearchBlock && (
        <Fragment>
          <div className={css.searchMetaBlock}>
            {radius && `Радиус поиска: ${radius} км.`}
            {radius && <br />}
            {mastersNear ? `Поблизости мастеров: ${mastersNear}` : 'Обновление информации'}
          </div>

          <Attention className={css.attention}>На ваш номер придет СМС как только мастер будет найден</Attention>
        </Fragment>
      )}

      <OrderDetails
        handleOpen={toggleOpenState(isDetailsOpen, toggleDetails)}
        isOpen={isDetailsOpen}
        isAttachment={isAttachOrderDetails}
        address={address}
        description={description}
        imageUrls={imageUrls}
        changeImageUrls={changeImageUrls}
        name={fullName}
        phone={phone}
      />

      {isMasterFound && renderMaster(props)}

      <Button
          theme={'green'}
          className={css.callButton}
          href="tel:+74951985495"
          asLink
      >
        <Icon name={'call'} size={28} className={css.callIcon} />
        <div className={css.callButtonText}>Звонок администратору</div>
      </Button>
    </Fragment>
  )
}

InstantMaster.propTypes = {
  radius: T.number,
  mastersNear: T.number,
  name: T.string,
  lastName: T.string,
  description: T.string,
  imageUrls: T.array,
  phone: T.string,
  time: T.string,
  status: T.string,
  statusMessage: T.string,
  paymentStatus: T.string,
  getMasterGeoMarkers: T.func.isRequired,
  getDistanceMaster: T.func.isRequired,
}

InstantMaster.defaultProps = {
  radius: 0,
  mastersNear: 0,
  name: '',
  lastName: '',
  description: '',
  imageUrls: [],
  phone: '',
  time: '',
  status: '',
  statusMessage: '',
  paymentStatus: '',
}
