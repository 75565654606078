import React, {useState, Children} from 'react'
import T from 'prop-types'

import Button from 'app/Elements/Button'

import cx from 'classnames'
import css from './index.styl'


export default function Subtext(props) {
  const {
    tag: Tag,
    className,
    lines,
    length,
  } = props

  const [display, setDisplay] = useState(false)

  const cl = cx({
    [css.textmore]: display,
    [css.textless]: !display,
    [className]: className,
  })

  let contentLn = 0;

  Children.forEach(props.children, function (child) {
    if (typeof child === 'string') {
      contentLn += child.length
    }
  })

  return (<React.Fragment>
    <Tag
      className={cl}
      style={{WebkitLineClamp: lines}}
    >
      {props.children}
    </Tag>
    {contentLn > length && (
      <Button
        className={css.toggle}
        contentClassName={css.toggleContent}
        theme={'transparent'}
        onClick={() => setDisplay(!display)}
        asLink
      >
        {display ? 'Свернуть' : 'Подробнее'}
      </Button>
    )}
  </React.Fragment>)
}

Subtext.propTypes = {
  tag: T.string,
  className: T.string,
  lines: T.number,
  length: T.number,
}

Subtext.defaultProps = {
  tag: 'p',
  className: '',
  lines: 3,
  length: 250,
}
