import React, { PureComponent } from 'react'
import T from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { selectAuthToken } from 'flux/selectors/fetch/authConfirm'
import AuthorizationSms from 'app/Components/AuthorizationSms/index.connector'

function isAuthorizedWrapper(Component) {
  return class IsAuthorized extends PureComponent {
    static propTypes = {
      authToken: T.string,
      location: T.shape({
        pathname: T.string.isRequired,
        search: T.string.isRequired,
      }).isRequired,
    }

    render() {
      const { authToken } = this.props

      if (!authToken) {
        return <AuthorizationSms />
      }
      return <Component {...this.props} />
    }
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: selectAuthToken(state),
  }
}

const mapDispatchToProps = () => ({})

export default function isAuthorized(Component) {
  return withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(isAuthorizedWrapper(Component))
  )
}
