import React, { Component, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import T from 'prop-types'
import { withRouter } from 'react-router'

import Icon from 'app/Elements/Icon'

import isServer from 'helpers/isServer'

import cx from 'classnames'
import css from './modal.styl'

function closeModal(e, props) {
  const { isOpen, onCloseClick } = props
  if (isOpen && onCloseClick) {
    onCloseClick()
  }
}

function getOffset(props, state) {
  if (isServer) return

  const { modalOffset, handleModalOffset } = state

  const top = window.pageYOffset + 150
  handleModalOffset({ ...modalOffset, top })
}

export function ModalWrapper(props) {
  const { isOpen, shouldUseOffset, name, titleCl, modalCl, iconCl, title, children, iconClose } = props

  const [modalOffset, handleModalOffset] = useState({})
  const state = {
    modalOffset,
    handleModalOffset,
  }

  useEffect(() => {
    getOffset(props, state)
  }, [isOpen])

  if (!isOpen) return null

  const closeModalFunc = (e) => closeModal(e, props)

  return (
    <Modal name={name} onEscapePress={closeModalFunc}>
      <div className={css.modalBack} onClick={closeModalFunc} />
      <div className={cx(css.modal, modalCl)} style={shouldUseOffset ? { ...modalOffset } : {}}>
        {iconClose && (
          <div className={cx(css.iconContainer, iconCl)} onClick={closeModalFunc}>
            <Icon name={'cross'} size={20} className={css.iconStyle} />
          </div>
        )}
        <h1 className={cx(css.title, titleCl)}>{title}</h1>
        {children}
      </div>
    </Modal>
  )
}

ModalWrapper.propTypes = {
  isOpen: T.bool,
  shouldUseOffset: T.bool,
  name: T.string.isRequired,
  children: T.node.isRequired,
  title: T.node,
  titleCl: T.string,
  iconCl: T.string,
  modalCl: T.string,
  onCloseClick: T.func,
}
ModalWrapper.defaultProps = {
  isOpen: true,
  shouldUseOffset: false,
  title: '',
  onCloseClick: () => {},
}

class Modal extends Component {
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown)
  }

  componentWillUnmount() {
    if (this.node) {
      document.body.removeChild(this.node)
    }

    this.node = null

    document.removeEventListener('keydown', this.handleKeydown)
  }

  handleKeydown = (event) => {
    if (event.keyCode === 27) {
      this.props.onEscapePress()
    }
  }

  render() {
    const { name, children } = this.props

    if (isServer) return null

    if (!this.node) {
      this.node = document.createElement('modal')

      if (name) {
        this.node.setAttribute('name', name)
      }

      document.body.appendChild(this.node)
    }

    return ReactDOM.createPortal(children, this.node)
  }
}

Modal.propTypes = {
  children: T.node.isRequired,
  name: T.string.isRequired,
  onEscapePress: T.func,
}
Modal.defaultProps = {
  onEscapePress: () => {},
}

export default withRouter(Modal)
