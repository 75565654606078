import authRequestRequest from 'fetch/requests/authRequest'
import {generateActionFunc, generateActionClearErrorFunc} from './_generator'

const PREFIX = 'auth'
const request = (data) => authRequestRequest(data)

export const types = {
  AUTH_REQUEST_START: `${PREFIX}/AUTH_REQUEST_START`,
  AUTH_REQUEST_SUCCESS: `${PREFIX}/AUTH_REQUEST_SUCCESS`,
  AUTH_REQUEST_FAIL: `${PREFIX}/AUTH_REQUEST_FAIL`,
  AUTH_REQUEST_CLEAR_ERROR: `${PREFIX}/AUTH_REQUEST_CLEAR_ERROR`,
}

export const authRequest = generateActionFunc({
  START: types.AUTH_REQUEST_START,
  SUCCESS: types.AUTH_REQUEST_SUCCESS,
  FAIL: types.AUTH_REQUEST_FAIL,
}, request)

export const clearAuthRequestErrors = generateActionClearErrorFunc({
  CLEAR_ERROR: types.AUTH_REQUEST_CLEAR_ERROR,
})