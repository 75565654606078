import buildReducer from 'flux/utils/buildReducer'
import { generateReducers, getInitialDataFields } from 'flux/utils/orderFluxGenerator'
import { types } from 'flux/actions/order'

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  data: {
    ...getInitialDataFields(),
  },
}

export default buildReducer(initialState, {
  ...generateReducers(),
  [types.RESET_ORDER]: (state, payload) => {
    return {
      loading: false,
      loaded: false,
      error: null,
      data: {
        ...getInitialDataFields(),
      },
    }
  },
  [types.UPDATE_ORDER]: (state, payload) => {
    return {
      ...state,
      data: {
        ...state.data,
        ...payload.data,
      },
    }
  },
  [types.GET_ORDER_INFO_START]: (state, payload) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    }
  },
  [types.GET_ORDER_INFO_SUCCESS]: (state, payload) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: null,
      data: {
        ...state.data,
        ...payload.data,
      },
    }
  },
  [types.GET_ORDER_INFO_FAIL]: (state, payload) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: { ...payload.error },
    }
  },
  [types.PLACE_ORDER_START]: (state, payload) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    }
  },
  [types.PLACE_ORDER_FAIL]: (state, payload) => {
    return {
      ...state,
      error: payload.error,
      loading: false,
      loaded: true,
    }
  },
  [types.PLACE_ORDER_SUCCESS]: (state, payload) => {
    const { data: { orderId, formUrl, masters } = {} } = payload
    return {
      ...state,
      loading: false,
      loaded: true,
      data: {
        ...state.data,
        formUrl,
        id: orderId,
        masters: masters,
      },
    }
  },
  [types.REPLACE_ORDER_SUCCESS]: (state, payload) => {
    const { data: { orderId, formUrl } = {} } = payload
    return {
      ...state,
      loading: false,
      loaded: true,
      data: {
        ...state.data,
        formUrl,
        id: orderId,
      },
    }
  },

  [types.CHANGE_STATUS]: (state, payload) => {
    return {
      ...state,
      error: null,
      data: {
        ...state.data,
        status: payload.status,
        status_source: payload.status_source,
      },
    }
  },
  [types.CHANGE_TYPE]: (state, payload) => {
    return {
      ...state,
      error: null,
      data: {
        ...state.data,
        type: payload.type,
      },
    }
  },
  [types.CHANGE_IS_NORMAL_STARTED]: (state, payload) => {
    return {
      ...state,
      error: null,
      data: {
        ...state.data,
        is_normal_started: payload.is_normal_started,
      },
    }
  },
  [types.CHANGE_PROGRESS]: (state, payload) => {
    return {
      ...state,
      error: null,
      data: {
        ...state.data,
        progress: payload.progress,
      },
    }
  },
  [types.CHANGE_STATUS_ORDER_FAIL]: (state, payload) => {
    return {
      ...state,
      error: payload.error,
      loading: false,
      loaded: true,
    }
  },

  [types.UPDATE_LIST_STREET]: (state, payload) => {
    return {
      ...state,
      data: {
        ...state.data,
        listStreet: payload,
      },
    }
  },
  [types.UPDATE_MASTER_MARKERS]: (state, payload) => {
    return {
      ...state,
      data: {
        ...state.data,
        mastersNear: payload.masters.length,
        masterMarkers: payload.masters,
        radius: payload.radius,
      },
    }
  },
  [types.GET_MASTER_MARKERS_FAIL]: (state, payload) => {
    return {
      ...state,
      error: payload.error,
      loading: false,
      loaded: true,
    }
  },

  [types.CLEAR_DATA_ORDER]: (state, payload) => {
    return {
      ...state,
      data: {
        // ...state.data,
        ...payload,
        client_metadata: {...state.data.client_metadata},
        master_metadata: {...state.data.master_metadata},
        billing_info: {...state.data.billing_info},
      },
    }
  },
  [types.SET_DURATION_MASTER]: (state, payload) => {
    return {
      ...state,
      data: {
        ...state.data,
        durationMaster: payload.durationMaster,
        distanceMaster: payload.distanceMaster,
      },
    }
  },
  [types.ADD_MASTERS_ACCEPTED]: (state, payload) => {
    return {
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }
  },
  [types.CHANGE_ORDER_TEST]: (state, payload) => {
    return {
      ...state,
      data: {
        ...state.data,
        order_test: payload.result,
      },
    }
  },
  [types.SET_ORDER_BILLING_INFO]: (state, payload) => {
    return {
      ...state,
      data: {
        ...state.data,
        billing_info: payload.data,
      },
    }
  },
  [types.SET_MASTER_METADATA]: (state, payload) => {
    return {
      ...state,
      data: {
        ...state.data,
        master_metadata: payload.data,
      },
    }
  },
  [types.SET_CLIENT_METADATA]: (state, payload) => {
    return {
      ...state,
      data: {
        ...state.data,
        client_metadata: payload.data,
      },
    }
  },
})
