import buildReducer from 'flux/utils/buildReducer'
import {types} from 'flux/actions/responsive'

const initialState = {
  isMobile: null,
}

export default buildReducer(initialState, {
  [types.SET_IS_MOBILE]: (state, payload) => {
    return {
      ...state,
      isMobile: payload.isMobile,
    }
  },
})
