import { getOrderInfo, getOrderStatus } from '../actions/order'

export default {
  promise: (props = {}) => {
    const {
      store: { dispatch, getState } = {},
      match: { params: {id} = {}} = {},
      location: { search } = {},
    } = props

    if (id) {
      return Promise.all([
        dispatch(getOrderInfo(id)),
        dispatch(getOrderStatus(id)),
      ])
    } else {
      return Promise.resolve()
    }
  },
}