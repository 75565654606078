export default [{
  slug: 'test',
  masters: 'Поблизости от вас есть свободные мастера',
  mastersSub: 'Разместите заказ и система выберет лучшего&nbsp;из&nbsp;них.',
  mastersSubSmall: 'Только мастера, прошедшие отбор по европейским стандартам, допущены к работе. Районный&nbsp;комендант. Страховка.',
  demoOrderButton: 'Просмотр демо-заказа',
  hotOrderDesc: 'Минимальная стоимость срочного заказа — 490 руб. Она включает диагностику и первые 15 минут работы мастера. Начиная с 16й минуты стоимость работы мастера рассчитывается поминутно исходя из базовой цены 490 руб/ 30 мин работы (16 руб/мин, 960 руб/час).',
  timeOrderDesc: 'Минимальная стоимость предварительного заказа — 492 руб. Она включает диагностику и первые 15 минут работы мастера. Начиная с6й минуты стоимость работы мастера рассчитывается поминутно исходя из базовой цены 612 руб/ 30 мин работы (20 руб/мин, 1 200 руб/час).',
  timeOrderPaymentDesc: 'Вы будете перенаправлены на страницу резервирования средств. Предоплата не взимается. Деньги будут списаны только после выполнения заказа. Бесплатная отмена за час до назначенного времени выполнения заказа.',
  hotOrderPaymentDesc: 'Вы будете перенаправлены на страницу резервирования средств. Предоплата не взимается. Деньги будут списаны только после выполнения заказа.',
  title: 'Тестирование',
  type: 'settings',
  iconName: 'settings',
  slogan: ' — онлайн вызов мастеров и помощников по дому',
  hotOrderMinLabel: 'Минимальный заказ 491 Р'
},{
  slug: 'ezhek-test',
  title: 'ЕЖЭК.Тест',
  hotOrderMinLabel: '\nМастер на час - 1000 ₽\n\nМастер на пол-дня -  3600 ₽\n\nМастер на день -  6400 ₽',
  type: 'settings',
  iconName: 'settings'
},{  slug: 'ezhek-welcome',
  title: 'ЕЖЭК.WELCOME',
  hotOrderDesc: '<br />Промокод WELCOME: дарим 1000 рублей за первый заказ.',
  timeOrderDesc: '<br />Промокод WELCOME: дарим 1000 рублей за первый заказ.',
  type: 'settings',
  iconName: 'settings'
},{
  slug: 'avito-uslugi',
  title: 'АВИТО.Услуги',
  type: 'settings',
  iconName: 'settings'
},{
  slug: 'elektrik',
  title: 'Электрик',
  type: 'settings',
  iconName: 'settings'
}, {
  slug: 'elektrik-na-dom',
  title: 'Электрик на дом',
  type: 'settings',
  iconName: 'settings'
}, {
  slug: 'vizov-elektrika',
  title: 'Вызов электрика',
  type: 'settings',
  iconName: 'settings'
}, {
  slug: 'ustanovka-rozetki',
  title: 'Установка розетки',
  type: 'settings',
  iconName: 'settings'
}, {
  slug: 'elektrika-montazh',
  title: 'Электрика монтаж',
  type: 'settings',
  iconName: 'settings'
}, {
  slug: 'vizvat-elektrika',
  title: 'Вызвать электрика',
  type: 'settings',
  iconName: 'settings'
}, {
  slug: 'sborka-mebeli',
  title: 'Сборка мебели',
  type: 'shelve',
  iconName: 'shelve'
}, {
  slug: 'sborschik-mebeli',
  title: 'Сборщик мебели',
  type: 'shelve',
  iconName: 'shelve'
}, {
  slug: 'remont-mebeli',
  title: 'Ремонт мебели',
  type: 'shelve',
  iconName: 'shelve'
}, {
  slug: 'kosmeticheskiy-remont',
  title: 'Косметический ремонт',
  type: 'settings',
  iconName: 'settings'
}, {
  slug: 'master-na-chas',
  title: 'Мастер на час',
  type: 'settings',
  iconName: 'settings'
}, {
  slug: 'Vyzvat_mastera_na_chas',
  title: 'Вызвать мастера на час',
  type: 'settings',
  iconName: 'settings'
}, {
  slug: 'muzh-na-chas',
  title: 'Муж на час',
  type: 'settings',
  iconName: 'settings'
}, {
  slug: 'malyar',
  title: 'Маляр',
  type: 'settings',
  iconName: 'settings'
}, {
  slug: 'uborka-kvartiry',
  title: 'Уборка квартиры',
  type: 'hover',
  iconName: 'hover'
}, {
  slug: 'uborshitsa',
  title: 'Уборщица',
  type: 'hover',
  iconName: 'hover'
}, {
  slug: 'ubrat',
  title: 'Убрать',
  type: 'hover',
  iconName: 'hover'
}, {
  slug: 'uborka',
  title: 'Уборка',
  type: 'hover',
  iconName: 'hover'
}, {
  slug: 'cleaning',
  title: 'Клининг',
  type: 'hover',
  iconName: 'hover'
}, {
  slug: 'uborka-posle-remonta',
  title: 'Уборка после ремонта',
  type: 'hover',
  iconName: 'hover'
}, {
  slug: 'moika-okno',
  title: 'Мойка окон',
  type: 'hover',
  iconName: 'hover'
}, {
  slug: 'mitye-okon',
  title: 'Мытьё окон',
  type: 'hover',
  iconName: 'hover'
}, {
  slug: 'moika-moika',
  title: 'Мойка мойка',
  type: 'hover',
  iconName: 'hover'
}, {
  slug: 'generalnaya-uborka',
  title: 'Генеральная уборка',
  type: 'hover',
  iconName: 'hover'
}, {  
  slug: 'zakaz_mastera_online',
  title: 'Заказ мастера онлайн',
  type: 'settings',
  iconName: 'settings'
}, { 
  slug: 'santehnik',
  title: 'Сантехник',
  type: 'shower',
  iconName: 'shower'
}, {
  slug: 'vyzov-santehnika',
  title: 'Вызов сантехника',
  type: 'shower',
  iconName: 'shower'
}, {
  slug: 'vyzvat-santehnika',
  title: 'Вызвать сантехника',
  type: 'shower',
  iconName: 'shower'
}, {
  slug: 'santehnik-na-dom',
  title: 'Сантехник на дом',
  type: 'shower',
  iconName: 'shower'
}, {
  slug: 'podkluchenie-stiralnoy-mashiny',
  title: 'Подключение стиральной машины',
  type: 'shower',
  iconName: 'shower'
}, {
  slug: 'ustanovka-stiralnoy-mashiny',
  title: 'Установка стиральной машины',
  type: 'shower',
  iconName: 'shower'
}]
