import React, { Fragment, useState } from 'react'
import T from 'prop-types'

import Icon from 'app/Elements/Icon'
import Button from 'app/Elements/Button'
import InfoMessage from 'app/Components/InfoMessage'
import Attachment from 'app/Components/Attachment'
import Attention from 'app/Components/Attention'

import { renderDescription, uploadFiles } from 'helpers/order'

import cx from 'classnames'
import css from './index.styl'

export default function OrderDetails(props) {
  const { isOpen, handleOpen, address, description, imageUrls, name, phone, isAttachment } = props
  const isImage = typeof imageUrls === 'string' ? Boolean(imageUrls) : (imageUrls || []).length > 0
  const [isImageLoading, handleIsImageLoading] = useState(false)
  const state = { isImageLoading, handleIsImageLoading }
  let newDescription = description ? renderDescription(description) : ''

  return (
    <Fragment>
      <Button
        theme={'transparent'}
        onClick={handleOpen}
        className={css.detailsButton}
        contentClassName={css.detailsContent}
      >
        Детали заказа
        <Icon name={isOpen ? 'dropup' : 'dropdown'} size={30} className={cx(css.dropdownIcon, css.detailsIcon)} />
      </Button>
      <div className={cx(css.detailsSection, !isOpen && css.closed)}>
        <div className={cx(css.infoBlock, !Boolean(address) && css.closed)}>{address}</div>
        <InfoMessage
          message={newDescription}
          iconName={'settings'}
          className={cx(css.description, !Boolean(description) && css.closed)}
        />
        {isAttachment || imageUrls.length ? (
          <Attachment
            isLoading={state['isImageLoading']}
            files={imageUrls || []}
            alt={description || 'ezhek clients image'}
            className={css.attachment}
            fileCl={cx(!isImage && css.closed)}
            handleFiles={uploadFiles(state, props)}
            isDeleteImageIcon={false}
          />
        ) : null}
        <div className={cx(css.infoBlock, !Boolean(name) && css.closed)}>{name}</div>
        <div className={cx(css.infoBlock, !Boolean(phone) && css.closed)}>{phone}</div>
      </div>
    </Fragment>
  )
}

OrderDetails.propTypes = {
  isOpen: T.bool,
  handleOpen: T.func,
  address: T.string,
  description: T.string,
  imageUrls: T.array,
  name: T.string,
  phone: T.string,
  isAttachment: T.bool,
}
OrderDetails.defaultProps = {
  isOpen: false,
  handleOpen: () => {},
  address: '',
  description: '',
  imageUrls: [],
  name: '',
  phone: '',
  isAttachment: true,
}
