import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { sendOrderReview, getMastersAccepted, setConfirmMaster } from 'flux/actions/order'

import { generateStateToProps, generateDispatchToProps } from 'flux/utils/orderFluxGenerator'

//** component
import Component from './index'

const mapStateToProps = (state) => ({
  ...generateStateToProps(state),
})

const mapDispatchToProps = (dispatch) => ({
  ...generateDispatchToProps(dispatch),
  getMastersAccepted: () => dispatch(getMastersAccepted()),
  setConfirmMaster: (idMaster) => dispatch(setConfirmMaster(idMaster)),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
)
