import { flatten } from 'flat'
import cloneDeep from 'lodash/cloneDeep'

import orderPlaceRequest from 'fetch/requests/orderPlace'
import orderReplaceRequest from 'fetch/requests/orderReplace'
import orderStatusRequest from 'fetch/requests/orderStatus'
import orderToNormalRequest from 'fetch/requests/orderToNormal'
import orderToDelayedRequest from 'fetch/requests/orderToDelayed'
import orderInfoRequest from 'fetch/requests/orderInfo'
import orderBillingInfoRequest from 'fetch/requests/orderBillingInfo'
import orderMasterGeoRequest from 'fetch/requests/orderMasterGeo'
import orderReviewRequest from 'fetch/requests/orderReview'
import orderDistanceMasterRequest from 'fetch/requests/orderDistanceMaster'
import orderMastersAcceptedRequest from 'fetch/requests/orderMastersAccepted'
import orderConfirmMasterRequest from 'fetch/requests/orderConfirmMaster'
import orderCheckCommandantRequest from 'fetch/requests/orderCheckCommandant'
import masterMetadataRequest from 'fetch/requests/masterMetadata'
import clientMetadataRequest from 'fetch/requests/clientMetadata'

import { selectOrderField, selectData } from 'flux/selectors/order'
import { selectAuthToken } from 'flux/selectors/fetch/authConfirm'

import killEmptyProps from 'helpers/killEmptyProps'

const PREFIX = 'order'

/*
 * reducers, action types, actions and selectors
 * are automatically generated
 * */
export const initialData = {
  id: {
    initialValue: null,
    interfaceName: 'id', // interface values
  },
  price: {
    initialValue: null,
    interfaceName: 'price',
  },
  specs: {
    initialValue: null,
    interfaceName: 'specs',
  },
  type: {
    initialValue: null,
    interfaceName: 'type',
  },
  time: {
    initialValue: null,
    interfaceName: 'time',
  },
  title: {
    initialValue: null,
    interfaceName: 'title',
  },
  description: {
    initialValue: null,
    interfaceName: 'description',
  },
  image_urls: {
    initialValue: [],
    interfaceName: 'imageUrls',
  },
  geo_lat: {
    initialValue: null,
    interfaceName: 'geoLat',
  },
  geo_lon: {
    initialValue: null,
    interfaceName: 'geoLon',
  },
  geo_city: {
    initialValue: null,
    interfaceName: 'geoCity',
  },
  geo_street: {
    initialValue: null,
    interfaceName: 'geoStreet',
  },
  geo_building: {
    initialValue: null,
    interfaceName: 'geoBuilding',
  },
  geo_floor: {
    initialValue: null,
    interfaceName: 'geoFloor',
  },
  geo_apartments: {
    initialValue: null,
    interfaceName: 'geoApartments',
  },
  created_at: {
    initialValue: null,
    interfaceName: 'createdAt',
  },
  updated_at: {
    initialValue: null,
    interfaceName: 'updatedAt',
  },
  closed_at: {
    initialValue: null,
    interfaceName: 'closedAt',
  },
  master: {
    initialValue: {},
    interfaceName: 'master',
  },
  status: {
    initialValue: null,
    interfaceName: 'status',
  },
  status_source: {
    initialValue: null,
    interfaceName: 'statusSource',
  },
  payment_status: {
    initialValue: null,
    interfaceName: 'paymentStatus',
  },
  is_commandant: {
    initialValue: false,
    interfaceName: 'isCommandant',
  },
  tips: {
    initialValue: null,
    interfaceName: 'tips',
  },
  discount: {
    initialValue: null,
    interfaceName: 'discount',
  },
  masters: {
    initialValue: null,
    interfaceName: 'masters',
  },
  client_review: {
    initialValue: null,
    interfaceName: 'clientReview',
  },
  is_normal_started: {
    initialValue: false,
    interfaceName: 'isNormalStarted',
  },

  // do not forget to unflatten on submit
  'client.name': {
    initialValue: null,
    interfaceName: 'name',
  },
  'client.lastName': {
    initialValue: null,
    interfaceName: 'lastName',
  },
  'client.email': {
    initialValue: null,
    interfaceName: 'email',
  },
  'client.phone': {
    initialValue: null,
    interfaceName: 'phone',
  },

  // from PLACE_ORDER_SUCCESS or REPLACE_ORDER_SUCCESS
  formUrl: {
    initialValue: null,
    interfaceName: 'formUrl',
  },

  // from UPDATE_MASTER_MARKERS
  masterMarkers: {
    initialValue: [],
    interfaceName: 'masterMarkers',
  },
  mastersNear: {
    initialValue: null,
    interfaceName: 'mastersNear',
  },
  radius: {
    initialValue: null,
    interfaceName: 'radius',
  },

  // from UPDATE_LIST_STREET
  listStreet: {
    initialValue: [],
    interfaceName: 'listStreet',
  },

  // from SET_DURATION_MASTER
  durationMaster: {
    initialValue: null,
    interfaceName: 'durationMaster',
  },
  distanceMaster: {
    initialValue: null,
    interfaceName: 'distanceMaster',
  },

  progress: {
    initialValue: {},
    interfaceName: 'progress',
  },

  order_test: {
    initialValue: false,
    interfaceName: 'orderTest',
  },
  ready: {
    initialValue: false,
    interfaceName: 'ready',
  },
  commandant: {
    initialValue: false,
    interfaceName: 'commandant',
  },
  billing_info: {
    initialValue: {},
    interfaceName: 'billingInfo',
  },
  master_metadata: {
    initialValue: {},
    interfaceName: 'masterMetadata',
  },
  client_metadata: {
    initialValue: {},
    interfaceName: 'clientMetadata',
  },
}

export const types = {
  ...generateActionTypes(),

  UPDATE_ORDER: `${PREFIX}/UPDATE_ORDER`,
  RESET_ORDER: `${PREFIX}/RESET_ORDER`,

  PLACE_ORDER_START: `${PREFIX}/PLACE_ORDER_START`,
  PLACE_ORDER_SUCCESS: `${PREFIX}/PLACE_ORDER_SUCCESS`,
  PLACE_ORDER_FAIL: `${PREFIX}/PLACE_ORDER_FAIL`,

  GET_ORDER_INFO_START: `${PREFIX}/GET_ORDER_INFO_START`,
  GET_ORDER_INFO_SUCCESS: `${PREFIX}/GET_ORDER_INFO_SUCCESS`,
  GET_ORDER_INFO_FAIL: `${PREFIX}/GET_ORDER_INFO_FAIL`,

  CHANGE_TYPE: `${PREFIX}/CHANGE_TYPE`,
  CHANGE_STATUS: `${PREFIX}/CHANGE_STATUS`,
  CHANGE_PROGRESS: `${PREFIX}/CHANGE_PROGRESS`,
  CHANGE_IS_NORMAL_STARTED: `${PREFIX}/CHANGE_IS_NORMAL_STARTED`,
  CHANGE_STATUS_ORDER_FAIL: `${PREFIX}/CHANGE_STATUS_ORDER_FAIL`,


  UPDATE_LIST_STREET: `${PREFIX}/UPDATE_LIST_STREET`,
  UPDATE_MASTER_MARKERS: `${PREFIX}/UPDATE_MASTER_MARKERS`,
  GET_MASTER_MARKERS_FAIL: `${PREFIX}/GET_MASTER_MARKERS_FAIL`,

  CLEAR_DATA_ORDER: `${PREFIX}/CLEAR_DATA_ORDER`,

  SET_DURATION_MASTER: `${PREFIX}/SET_DURATION_MASTER`,
  ADD_MASTERS_ACCEPTED: `${PREFIX}/ADD_MASTERS_ACCEPTED`,

  REPLACE_ORDER_SUCCESS: `${PREFIX}/REPLACE_ORDER_SUCCESS`,

  CHANGE_ORDER_TEST: `${PREFIX}/CHANGE_ORDER_TEST`,

  SET_ORDER_BILLING_INFO: `${PREFIX}/SET_ORDER_BILLING_INFO`,
  SET_MASTER_METADATA: `${PREFIX}/SET_MASTER_METADATA`,
  SET_CLIENT_METADATA: `${PREFIX}/SET_CLIENT_METADATA`,
}

export function clearDataOrder() {
  return {
    type: types.CLEAR_DATA_ORDER,
    payload: { description: null, image_urls: [] },
  }
}

function generateActionTypes() {
  const types = {}
  const dataFields = Object.keys(initialData)
  dataFields.forEach((fieldName) => {
    const typeName = `CHANGE_${fieldName.toUpperCase()}`
    types[typeName] = `${PREFIX}/${typeName}`
  })

  return types
}

export function updateOrder(data) {
  return {
    type: types.UPDATE_ORDER,
    payload: { data },
  }
}

export function updateListStreet(list) {
  return {
    type: types.UPDATE_LIST_STREET,
    payload: list,
  }
}

export function placeOrder(newOrderFields) {
  return (dispatch, getState) => {
    dispatch({ type: types.PLACE_ORDER_START })

    const state = getState()
    const token = selectAuthToken(state)
    const data = cloneDeep(selectData(state))
    const isCommandant = selectOrderField('is_commandant', state)
    const body = {
      ...killEmptyProps({
        ...data,
        ...newOrderFields,
        title: data.title || "Новый заказ"
      }),
      is_commandant: isCommandant,
    }

    return orderPlaceRequest({ token, body })
      .then((data) => {
        dispatch({
          type: types.PLACE_ORDER_SUCCESS,
          payload: {
            data: { ...data },
          },
        })

        return { ...data }
      })
      .catch((error) => {
        dispatch({
          type: types.PLACE_ORDER_FAIL,
          payload: { error },
          notification: {
            uid: types.UPLOAD_FAIL,
            level: 'error',
            title: 'Сервис временно не доступен, попробуйте еще раз',
            autoDismiss: 15,
          },
        })

        return Promise.reject(error)
      })
  }
}

export function rePlaceOrder() {
  return (dispatch, getState) => {
    const state = getState()
    const token = selectAuthToken(state)
    const id = selectOrderField('id', state)
    const body = { id }

    return orderReplaceRequest({ token, body })
      .then((data) => {
        dispatch({
          type: types.REPLACE_ORDER_SUCCESS,
          payload: {
            data: { ...data },
          },
        })
      })
      .catch((error) => {
        dispatch({
          type: types.PLACE_ORDER_FAIL,
          payload: { error },
          notification: {
            uid: types.UPLOAD_FAIL,
            level: 'error',
            title: 'Сервис временно не доступен, попробуйте еще раз',
            autoDismiss: 15,
          },
        })

        return Promise.reject(error)
      })
  }
}

export function getOrderInfo(externalId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = selectAuthToken(state)
    const id = externalId || selectOrderField('id', state)

    dispatch({ type: types.GET_ORDER_INFO_START })
    return orderInfoRequest({ id, token })
      .then((rawData) => {
        const data = flatten({ ...rawData }, { safe: true })
        dispatch({
          type: types.GET_ORDER_INFO_SUCCESS,
          payload: { data },
        })

        return { ...rawData }
      })
      .catch((error) => {
        let notification = {}

        if (error.status !== 404) {
          notification = {
            uid: types.UPLOAD_FAIL,
              level: 'error',
              title: 'Сервис временно не доступен, попробуйте еще раз',
              autoDismiss: 15,
          }
        }

        dispatch({
          type: types.GET_ORDER_INFO_FAIL,
          payload: { error },
          ...notification,
        })

        return Promise.reject(extra.detail || extra)
      })
  }
}

export function getOrderStatus(id) {
  return (dispatch, getState) => {
    const state = getState()
    const token = selectAuthToken(state)
    const type = selectOrderField('type', state)
    const status = selectOrderField('status', state)
    const time = selectOrderField('time', state)
    const progress = selectOrderField('progress', state)
    const isNormalStarted = selectOrderField('is_normal_started', state)

    return orderStatusRequest({ id, token })
      .then((data) => {
        const { status_source, status: nextStatus, type: nextType, time: nextTime, progress: nextProgress, is_normal_started: nextIsNormalStarted  } = data

        if (nextIsNormalStarted !== isNormalStarted) {
          dispatch({
            type: types.CHANGE_IS_NORMAL_STARTED,
            payload: {
              is_normal_started: nextIsNormalStarted,
            },
          })
        }

        if (nextStatus !== status) {
          dispatch({
            type: types.CHANGE_STATUS,
            payload: {
              status: nextStatus,
              status_source,
            },
          })
        }

        if (nextStatus !== status) {
          dispatch({
            type: types.CHANGE_STATUS,
            payload: {
              status: nextStatus,
              status_source,
            },
          })
        }

        if (nextType !== type) {
          dispatch({
            type: types.CHANGE_TYPE,
            payload: { type: nextType },
          })
        }

        if (progress !== nextProgress) {
          dispatch({
            type: types.CHANGE_PROGRESS,
            payload: { progress: nextProgress },
          })
        }

        if (time !== nextTime && selectOrderField('id', getState())) {
          dispatch({
            type: types.CHANGE_TIME,
            payload: { time: nextTime },
          })
        }

        return nextStatus
      })
      .catch((error) => {
        let notification = {}

        if (error.status !== 404) {
          notification = {
            uid: types.UPLOAD_FAIL,
            level: 'error',
            title: 'Сервис временно не доступен, попробуйте еще раз',
            autoDismiss: 15,
          }
        }

        dispatch({
          type: types.CHANGE_STATUS_ORDER_FAIL,
          payload: { error },
          ...notification,
        })

        return Promise.reject(extra.detail || extra)
      })
  }
}

export function getMasterGeoMarkers({getAll} = {}) {
  return (dispatch, getState) => {
    const state = getState()
    const id = selectOrderField('id', state)

    return orderMasterGeoRequest({ id: !getAll ? id : undefined })
      .then((data) => {
        dispatch({
          type: types.UPDATE_MASTER_MARKERS,
          payload: data,
        })
        return data
      })
      .catch((error) => {
        dispatch({
          type: types.GET_MASTER_MARKERS_FAIL,
          payload: { error },
          notification: {
            uid: types.UPLOAD_FAIL,
            level: 'error',
            title: 'Сервис временно не доступен, попробуйте еще раз',
            autoDismiss: 15,
          },
        })
      })
  }
}

export function changeOrderField(field, value) {
  return {
    type: types[`CHANGE_${field.toUpperCase()}`],
    payload: {
      [field]: value,
    },
  }
}

export function sendOrderReview(body) {
  return (dispatch, getState) => {
    const state = getState()
    const token = selectAuthToken(state)
    const id = selectOrderField('id', state)

    return orderReviewRequest({ id, token, body }).catch((error) => {
      dispatch({
        type: types.GET_MASTER_MARKERS_FAIL,
        payload: { error },
        notification: {
          uid: types.UPLOAD_FAIL,
          level: 'error',
          title: 'Сервис временно не доступен, попробуйте еще раз',
          autoDismiss: 15,
        },
      })
    })
  }
}

export function getDistanceMaster() {
  return (dispatch, getState) => {
    const state = getState()
    const token = selectAuthToken(state)
    const id = selectOrderField('id', state)

    return orderDistanceMasterRequest({ id, token })
      .then((data) => {
        dispatch({
          type: types.SET_DURATION_MASTER,
          payload: {
            durationMaster: +data.duration,
            distanceMaster: +data.distance,
          },
        })
      })
      .catch(() => {
        dispatch({
          // type: types.GET_ORDER_INFO_FAIL,
          payload: { error: extra },
          notification: {
            uid: types.UPLOAD_FAIL,
            level: 'error',
            title: 'Сервис временно не доступен, попробуйте еще раз',
            autoDismiss: 15,
          },
        })
      })
  }
}

export function getMastersAccepted() {
  return (dispatch, getState) => {
    const state = getState()
    const token = selectAuthToken(state)
    const id = selectOrderField('id', state)
    const isCommandant = selectOrderField('is_commandant', state)

    if (id && token) {
      return orderMastersAcceptedRequest({id, token, isCommandant})
        .then((data) => {
          console.log(data)
          dispatch({
            type: types.ADD_MASTERS_ACCEPTED,
            payload: data,
          })
        })
        .catch(() => {
          dispatch({
            type: types.GET_ORDER_INFO_FAIL,
            // payload: { error: extra },
            notification: {
              uid: types.UPLOAD_FAIL,
              level: 'error',
              title: 'Сервис временно не доступен, попробуйте еще раз',
              autoDismiss: 15,
            },
          })
        })
    } else {
      console.error({
        id, token
      })
    }
  }
}

export function setConfirmMaster(idMaster) {
  return (dispatch, getState) => {
    const state = getState()
    const token = selectAuthToken(state)
    const id = selectOrderField('id', state)
    const body = {
      master_id: idMaster,
    }

    return orderConfirmMasterRequest({ id, token, body }).catch(() => {
      dispatch({
        type: types.GET_ORDER_INFO_FAIL,
        // payload: { error: extra },
        notification: {
          uid: types.UPLOAD_FAIL,
          level: 'error',
          title: 'Сервис временно не доступен, попробуйте еще раз',
          autoDismiss: 15,
        },
      })
    })
  }
}

export function setOrderToNormal(time) {
  return (dispatch, getState) => {
    const state = getState()
    const token = selectAuthToken(state)
    const id = selectOrderField('id', state)
    const body = { time }

    return orderToNormalRequest({ id, token, body })
      .then((data) => console.log(data))
      .catch((error) => {
        dispatch({
          type: types.GET_MASTER_MARKERS_FAIL,
          payload: { error },
          notification: {
            uid: types.UPLOAD_FAIL,
            level: 'error',
            title: 'Сервис временно не доступен, попробуйте еще раз',
            autoDismiss: 15,
          },
        })
      })
  }
}

export function setOrderToDelayed() {
  return (dispatch, getState) => {
    const state = getState()
    const token = selectAuthToken(state)
    const id = selectOrderField('id', state)

    return orderToDelayedRequest({ id, token })
      .then((data) => console.log(data))
      .catch((error) => {
        dispatch({
          type: types.GET_MASTER_MARKERS_FAIL,
          payload: { error },
          notification: {
            uid: types.UPLOAD_FAIL,
            level: 'error',
            title: 'Сервис временно не доступен, попробуйте еще раз',
            autoDismiss: 15,
          },
        })
      })
  }
}

export function getOrderCheckCommandant(time) {
  return (dispatch, getState) => {
    const body = { time: time }

    return orderCheckCommandantRequest({ body })
      .then((data) => {
        dispatch({
          type: types.CHANGE_ORDER_TEST,
          payload: { ...data },
        })

        return data
      })
      .catch((error) => {
        dispatch({
          type: types.GET_MASTER_MARKERS_FAIL,
          payload: { error },
          notification: {
            uid: types.UPLOAD_FAIL,
            level: 'error',
            title: 'Сервис временно не доступен, попробуйте еще раз',
            autoDismiss: 15,
          },
        })
      })
  }
}

export function orderReset() {
  return (dispatch, getState) => {
    return dispatch({
      type: types.RESET_ORDER,
    })
  }
}

export function getOrderBillingInfoRequest() {
  return (dispatch, getState) => {
    return orderBillingInfoRequest()
      .then(data => {
        dispatch({
          type: types.SET_ORDER_BILLING_INFO,
          payload: { data },
        })
      })
  }
}

export function getMasterMetadata() {
  return (dispatch, getState) => {
    return masterMetadataRequest()
      .then(data => {
        dispatch({
          type: types.SET_MASTER_METADATA,
          payload: { data },
        })
      })
  }
}
export function getClientMetadata() {
  return (dispatch, getState) => {
    return clientMetadataRequest()
      .then(data => {
        dispatch({
          type: types.SET_CLIENT_METADATA,
          payload: { data },
        })
      })
  }
}
