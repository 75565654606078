import React, {Component} from 'react'
import PropTypes from 'prop-types'

//** lodash
import omit from 'lodash/omit'

//** elements
import Loader from 'app/Elements/Loader'

//** styles
import cx from 'classnames'
import css from './index.styl'

export default class Button extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    type: PropTypes.oneOf(['submit', 'reset', 'button', 'menu']),
    theme: PropTypes.oneOf(['orange', 'green', 'transparent']),
    size: PropTypes.oneOf([36, 40]),
    showFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    isBusy: PropTypes.bool,
    asLink: PropTypes.bool,
    noBlank: PropTypes.bool,
  }

  static defaultProps = {
    type: 'button',
    theme: 'orange',
    size: 36,
    isBusy: false,
    showFocus: true,
    asLink: false,
    noBlank: false,
  }

  render() {
    const {
      children,
      className,
      contentClassName,
      disabled,
      type,
      theme,
      size,
      showFocus,
      isBusy,
      asLink,
      noBlank,
      ...otherProps
    } = this.props

    const cl = cx({
      [css.button]: true,
      [css.buttonBusy]: isBusy,
      [css.buttonFocus]: showFocus,
      [css[`x${size}`]]: size,
      [css[theme]]: theme,
      [className]: className,
    })

    const contentCl = cx({
      [css.content]: true,
      [contentClassName]: contentClassName,
    })

    if (asLink) {
        return (
            <a
                target={noBlank ? "" : "_blank"}
                className={cl}
                type={type}
                {...omit(otherProps, ['busyManagerKey'])}
            >
                <span className={contentCl}>{children}</span>

                {isBusy && (
                    <span className={css.loader}>
                    <Loader />
                  </span>
                )}
            </a>
        )
    } else {
        return (
            <button
                disabled={disabled || isBusy}
                className={cl}
                type={type}
                {...omit(otherProps, ['busyManagerKey'])}
            >
                <span className={contentCl}>{children}</span>

                {isBusy && (
                  <span className={css.loader}>
                    <Loader />
                  </span>
                )}
            </button>
        )
    }
  }
}
