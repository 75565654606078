import React, {useState} from 'react'

import isEmpty from "lodash/isEmpty"

import Input from "app/Elements/Input"
import Button from "app/Elements/Button"
import Footer from 'app/Elements/Footer'
import pageConnector from 'app/Pages/utils/pageConnector'

import masterAccTerminate from 'fetch/requests/masterAccTerminate'

import {toPhonePattern, phonePlaceholder} from 'helpers/form/mask'

import cx from 'classnames'
import css from './index.styl'


function AccountTermination({ history }) {

  const _formFields = {
    firstName: '',
    firstNameError: '',
    email: '',
    emailError: '',
    phone: '',
    phoneError: '',
    message: '',
    messageError: '',
  }

  const [isLoading, handleIsLoading] = useState(false)
  const [formFields, handleFormFields] = useState(_formFields)

  function clearFormFields() {
    handleFormFields(_formFields)
  }

  function checkFields() {
    let errors = {}

    if (!formFields.firstName) {
      errors.firstNameError = 'Введите имя'
    }

    if (!formFields.email) {
      errors.emailError = 'Введите email'
    }

    if (!formFields.phone) {
      errors.phoneError = 'Введите номер телефона'
    }

    if (!formFields.message) {
      errors.messageError = 'Введите сообщение'
    }

    if (!isEmpty(errors)) {
      handleErrors(errors)
    }

    return isEmpty(errors)
  }

  function handleSubmit(event) {
    event.preventDefault()
    if (checkFields()) {
      handleIsLoading(true)

      masterAccTerminate({...formFields}).then(() => {
        clearFormFields()
        handleIsLoading(false)
        setTimeout(() => history.push('/'), 500)
      })
    }
  }

  function handleErrors(errors) {
    handleFormFields({
      ...formFields,
      ...errors,
    })
  }

  function handleInput(value, field, isError) {
    handleFormFields({
      ...formFields,
      [field]: !isError ? value : formFields[field],
      [`${field}Error`]: isError ? value : '',
    })
  }

  return (
    <div className={css.wrap}>
      <div className={css.content}>
        <div className={cx(css.inputLabel, css.infoText)}>Хотите удалить учетную запись?</div>
        <form onSubmit={handleSubmit} className={css.form}>
          <div className={css.inputRow}>
            <div className={css.inputLabel}>Имя *</div>
            <Input
              className={css.inputWrap}
              inputClassName={css.input}
              handleInput={(value) => handleInput(value, 'firstName')}
              value={formFields.firstName}
              name={'firstName'}
              id={'firstName'}
              disabled={isLoading}
            />
            <div className={css.inputError}>{formFields.firstNameError}</div>
          </div>
          <div className={css.inputRow}>
            <div className={css.inputLabel}>Email *</div>
            <Input
              className={css.inputWrap}
              inputClassName={css.input}
              handleInput={(value) => handleInput(value, 'email')}
              value={formFields.email}
              name={'email'}
              id={'email'}
              disabled={isLoading}
            />
            <div className={css.inputError}>{formFields.emailError}</div>
          </div>
          <div className={css.inputRow}>
            <div className={css.inputLabel}>Телефон *</div>
            <Input
              className={css.inputWrap}
              inputClassName={css.input}
              placeholder={phonePlaceholder}
              handleInput={(value) => handleInput(toPhonePattern(value), 'phone')}
              value={formFields.phone}
              name={'phone'}
              id={'phone'}
              disabled={isLoading}
            />
            <div className={css.inputError}>{formFields.phoneError}</div>
          </div>
          <div className={css.inputRow}>
            <div className={css.inputLabel}>Сообщение *</div>
            <Input
              textArea
              className={css.inputWrap}
              inputClassName={cx(css.input, css.textarea)}
              handleInput={(value) => handleInput(value, 'message')}
              value={formFields.message}
              element={'textarea'}
              name={'message'}
              id={'message'}
              disabled={isLoading}
            />
            <div className={css.inputError}>{formFields.messageError}</div>
          </div>
          <Button
            theme={'green'}
            className={css.button}
            contentClassName={css.buttonText}
            isBusy={isLoading}
            type="submit"
          >
            Отправить
          </Button>
        </form>
      </div>
      <Footer/>
    </div>
  )
}

export default pageConnector(AccountTermination, {withRouter: true})
