import React, {Fragment, useState, useEffect} from 'react'

import moment from 'helpers/moment'

import Icon from 'app/Elements/Icon'
import Button from 'app/Elements/Button'
import Input from 'app/Elements/Input'
import SmartLink from 'app/Elements/SmartLink'
import Loader from 'app/Elements/Loader'
import ShareBlock from 'app/Components/ShareBlock'
import {Dialog} from 'app/Elements/Floating'

import OrderStatusIcons from 'app/Components/OrderStatusIcons/index.connector'

import InfoIcon from 'app/Assets/Info'

import cx from 'classnames'
import css from './index.styl'

const starsArr = [
  {id: 1, active: false},
  {id: 2, active: false},
  {id: 3, active: false},
  {id: 4, active: false},
  {id: 5, active: false},
]

const ratingItems = [
  {
    name: 'friendliness',
    title: 'Приветливость мастера',
    raiting: 0,
  },
  {
    name: 'tidiness',
    title: 'Опрятность мастера',
    raiting: 0,
  },
  {
    name: 'equipment',
    title: 'Оснащенность мастера',
    raiting: 0,
  },
  {
    name: 'literacy',
    title: 'Техническая грамотность мастера',
    raiting: 0,
  },
  {
    name: 'cost',
    title: 'Стоимость заказа',
    raiting: 0,
  },
  {
    name: 'satisfaction',
    title: 'Общая удовлетворенность заказом',
    raiting: 0,
  },
]

const _formFields = {
  text: '',
  public_text: '',
}

function renderCloseOrder(handleViewRating, props) {
  const {
    tips = '0',
    discount = '0',
    progress: {total_sum = 0, done_at},
    statusSource,
  } = props

  return statusSource ? (
    <Fragment>
      <OrderStatusIcons/>
      {statusSource === 'master' && (
        <Fragment>
          <div className={css.pageWrap}>
            <Icon name={'ok'} size={79} className={css.okIcon}/>
          </div>
          <div className={cx(css.textCloseOrder)}>
            Ваш заказ успешно
            <br/>
            завершен.
            <br/>
            {/* {`Стоимость: ${total_sum}`}
            <Icon name={'rouble'} size={24} className={css.roubleIcon} />
            <br /> */}
            {/* {`Скидка: ${discount ? discount : '0'}`}
            <Icon name={'rouble'} size={24} className={css.roubleIcon} />
            <br />
            {`Чаевые: ${tips ? tips : '0'}`}
            <Icon name={'rouble'} size={24} className={css.roubleIcon} /> */}
          </div>
          {typeof total_sum === "undefined" ? (
            <div className={cx(css.textCloseOrder, css.textCloseOrderData)}>
              <Loader isBig/>
            </div>
          ) : (
            <Fragment>
              {!!done_at && (
                <div className={cx(css.textCloseOrder, css.textCloseOrderData)}>
                  {moment.parseZone(done_at).format('dddd, DD MMMM, HH:mm')}
                </div>
              )}
              <div className={cx(css.textCloseOrder)}>
                {`Стоимость: ${total_sum}`}
                <Icon name={'rouble'} size={24} className={css.roubleIcon}/>
              </div>
            </Fragment>
          )}
          <Button theme={'orange'} className={css.ratingButton} onClick={() => handleViewRating(true)}>
            <div>Оценить работу мастера</div>
          </Button>
        </Fragment>
      )}

      {statusSource === 'backoffice' && (
        <div className={css.loaderWrap}>Заказ завершен в Бэкофисе</div>
      )}

      {(statusSource !== 'master' && statusSource !== 'backoffice') && (
        <div className={css.loaderWrap}>Заказ завершен системой</div>
      )}
    </Fragment>
  ) : (
    <div className={css.loaderWrap}>
      <Loader isBig/>
    </div>
  )
}

function RatingItem({title, raiting, changeRating, name}) {
  const [stars, handleStars] = useState(starsArr)

  const changeStars = (id) => {
    changeRating(name, id)
    return handleStars(stars.map((star) => (star.id <= id ? {...star, active: true} : {...star, active: false})))
  }

  useEffect(() => changeStars(raiting), [])

  return (
    <Fragment>
      <div className={cx(css.ratingBodyText, css.titleStars)}>{title}</div>
      <div className={css.stars}>
        {stars.map(({id, active}) => (
          <Icon
            name={'star'}
            size={35}
            className={cx(css.starIcon, active && css.starIconActive)}
            key={id}
            onClick={() => changeStars(id)}
          />
        ))}
      </div>
      <div className={css.line}/>
    </Fragment>
  )
}

function ReviewItems({name, value, onChange, label, hint}) {
  return (<Fragment>
    <div className={cx(css.ratingBodyText, css.titleStars, css.inputLabel)}>{label}</div>
    <Input
      textArea
      rows={5}
      inputClassName={css.reviewInput}
      handleInput={(val) => onChange(val, name)}
      name={name}
      id={name}
      value={value}
      hint={hint}
    />
    <div className={css.line}/>
  </Fragment>)
}

function renderRatingMaster() {
  return (
    <Fragment>
      <div className={css.pageWrap}>
        <Icon name={'smile'} size={79} className={css.smileIcon}/>
      </div>
      <div className={cx(css.textCloseOrder, css.thank)}>Спасибо!</div>
      <div className={css.ratingBodyText}>
        Мы очень ценим,
        <br/>
        что Вы нашли время и ответили
        <br/>
        на наши вопросы. Проходя опрос Вы
        <br/>
        помогаете себе и соседям получать
        <br/>
        и в дальнейшем качественный сервис
        <br/>
        за разумные деньги
      </div>
      <ShareBlock/>
      <div className={css.pageWrap}>
        <SmartLink to={'/'} theme={'green'} isUnderlined className={css.linkHome}>
          На главную
        </SmartLink>
      </div>
    </Fragment>
  )
}

function RenderRatingTest({sendOrderReview}) {
  const [formFields, handleFormFields] = useState(_formFields)
  const [ratingsMasters, handleRatingsMasters] = useState(ratingItems)
  const [viewThanks, handleViewThanks] = useState(false)

  const changeRating = (name, newRating) => {
    return handleRatingsMasters(
      ratingsMasters.map((item) => (item.name === name ? {...item, raiting: newRating} : {...item})),
    )
  }

  function handleInput(value, field) {
    handleFormFields({
      ...formFields,
      [field]: value
    })
  }

  const requestRatingResult = () => {
    const ratings = {...formFields}

    ratingsMasters.map((item) => (ratings[item.name] = item.raiting))
    sendOrderReview(ratings)
    handleViewThanks(true)
  }

  return (
    <Fragment>
      <div className={css.headerRatingBlock}>Оценка работы мастера</div>
      {!viewThanks ? (
        <Fragment>
          <div className={css.ratingBodyText}>
            Ваше мнение очень важно! Проходя опрос
            Вы помогаете себе и соседям получать и в дальнейшем качественный сервис за
            разумные деньги
            <div className={css.line}/>
          </div>

          {ratingsMasters.map(({title, raiting, name}) => (
            <RatingItem key={name} title={title} raiting={raiting} changeRating={changeRating} name={name}/>
          ))}

          <ReviewItems
            name={'public_text'}
            onChange={handleInput}
            value={formFields.public_text}
            label={<Fragment>
              <p>Ваш отзыв о сервисе ЕЖЭК</p>
              <Dialog
                className={css.modalContent}
                render={({close}) => (<Fragment>
                  <p>
                    Оставьте отзыв о сервисе ЕЖЭК, который смогут увидеть все будущие пользователи сервиса.<br/>
                    Публикуются все отзывы. Перед публикацией из отзыва будет удалена вся конфиденциальная <br/>
                    информация и лексика, нарушающая правила пользования сервисом.
                  </p>
                  <Button onClick={() => close()}>
                    {"Закрыть"}
                  </Button>
                </Fragment>)}
              >
                <span style={{
                  marginLeft: "4px",
                  display: "flex",
                  justifyContent: "center",
                }}>
                  <InfoIcon
                    color={"#0495fd"}
                    size={"16"}
                  />
                </span>
              </Dialog>
            </Fragment>}
          />

          <ReviewItems
            name={'text'}
            onChange={handleInput}
            value={formFields.text}
            label={<Fragment>
              <p>Ваш отзыв о работе мастера</p>
              <Dialog
                className={css.modalContent}
                render={({close}) => (<Fragment>
                  <p>
                    Оставьте отзыв о работе мастера. Его получит ТОЛЬКО мастер. <br/>
                    Перед отправкой мастеру из отзыва будет удалена лексика, нарушающая правила пользования сервисом.
                  </p>
                  <Button onClick={() => close()}>
                    {"Закрыть"}
                  </Button>
                </Fragment>)}
              >
                <span style={{
                  marginLeft: "4px",
                  display: "flex",
                  justifyContent: "center",
                }}>
                  <InfoIcon
                    color={"#0495fd"}
                    size={"16"}
                  />
                </span>
              </Dialog>
            </Fragment>}
          />

          <Button
            theme={'orange'}
            className={cx(css.ratingButton, css.ratingButtonTotal)}
            onClick={() => requestRatingResult()}
          >
            <div>Оценить работу</div>
          </Button>
          <ShareBlock/>
        </Fragment>
      ) : (
        renderRatingMaster()
      )}
    </Fragment>
  )
}

export default function OrderClose(props) {
  const {sendOrderReview, clientReview} = props
  const [viewRating, handleViewRating] = useState(false)

  if (clientReview) {
    return renderRatingMaster()
  }

  return viewRating ? (
    <RenderRatingTest sendOrderReview={sendOrderReview}/>
  ) : (
    renderCloseOrder(handleViewRating, props)
  )
}
