import React, {Component} from "react"
import T from "prop-types"

import cx from "classnames"
import css from "./index.styl"


export default class Accordion extends Component {
    static propTypes = {
        className: T.string,
        label: T.node,
        content: T.node,
        element: T.string,
    }

    static defaultProps = {
        element: "li",
    }

    render() {
        const {
            element: Element,
            className,
            label,
            content,
        } = this.props

        return (
            <Element className={cx(css.accordion, className)}>
                <input type="checkbox" defaultChecked/>
                <i></i>
                <div className={css.label}>{label}</div>
                <div className={css.content}>{content}</div>
            </Element>
        )
    }
}

export class AccordionGroup extends Component {
    static propTypes = {
        className: T.string,
        children: T.node,
        element: T.string,
    }

    static defaultProps = {
        element: "ul"
    }

    render() {
        const {
            element: Element,
            className,
            children,
            ...restProps
        } = this.props

        return (
            <Element className={cx(css.accordionGroup, className)} {...restProps}>
                {children}
            </Element>
        )
    }
}

