import React, {useState} from 'react'

import Button from 'app/Elements/Button'
import Icon from 'app/Elements/Icon'
import DateSelector from 'app/Elements/DateSelector'

import { ModalWrapper } from 'app/Layout/Modal'

import moment, { getMinTimeForPreOrder } from 'helpers/moment'

import css from './index.styl'
import cx from 'classnames'

export default function ModalMasterNotFound({ handleMasterNotFoundOpened, setOrderToNormal, setOrderToDelayed }) {
  const [date, handleDate] = useState(moment().format('YYYY-MM-DD'))
  const [time, handleTime] = useState(getMinTimeForPreOrder().format('HH:mm'))

  const state = {
    date, handleDate,
    time, handleTime
  }

  const activeTransferOrder = date !== '' && time !== ''

  return (
    <ModalWrapper
      shouldUseOffset
      name={'masterNotFoundModal'}
      title={'Мастер не найден'}
      titleCl={css.title}
      modalCl={css.modal}
    >
      <div className={css.text}>
        <p>Вы можете оформить предварительный заказ на другое время или отложить заказ</p>
      </div>

      <div className={css.priceBlock}>
        { 
          <div>Для оформления предварительного заказа сначала укажите удобные дату и время, а затем нажмите на кнопку «Разместить предварительный заказ». <br /> 
          </div>
        }
      </div>
     
      <DateSelector state={state} />
      <div>
        <Button
            theme={'orange'}
            className={cx(css.button)}
            disabled={!activeTransferOrder}
            onClick={() => setOrderToNormal(`${date}T${time}:00Z`)}
        >
          Разместить предварительный заказ
        </Button>

        <Button
          theme={'orange'}
          className={cx(css.button)}
          onClick={() => {
            handleMasterNotFoundOpened(false)
            setOrderToDelayed()
          }}
        >
          Сохранить заказ как отложенный
        </Button>

        <Button
          theme={'green'}
          className={cx(css.button)}
          href="tel:+74951985495"
          asLink
        >
          <Icon name={'call'} size={28} className={css.callIcon} />
          Звонок администратору
        </Button>
      </div>
    </ModalWrapper>
  )
}
