import {combineReducers} from 'redux'

import responsive from './responsive'
import order from './order'
import authConfirm from './fetch/authConfirm'
import authRequest from './fetch/authRequest'

const fetch = combineReducers({
  authConfirm,
  authRequest,
})

export default {
  responsive,
  order,
  fetch,
}
