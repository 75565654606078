import React, { Fragment, useState, useEffect, useRef } from 'react'
import T from 'prop-types'
import { getFullUrl } from 'fetch/utils'
import Icon from 'app/Elements/Icon'
import Image from 'app/Elements/Image'
import Button from 'app/Elements/Button'
import SmartLink from 'app/Elements/SmartLink'
import Loader from 'app/Elements/Loader'
import { ModalWrapper } from 'app/Layout/Modal'

import OrderStatusIcons from 'app/Components/OrderStatusIcons/index.connector'
import OrderDetails from 'app/Components/OrderDetails'

import moment, { dayweek_conjugation } from 'helpers/moment'
import { renderDescription } from 'helpers/order'

import cx from 'classnames'
import css from './index.styl'


const GET_MASTERS_TIMEOUT = 3000

PreOrder.propTypes = {
  masters: T.arrayOf(T.object),
  setConfirmMaster: T.func.isRequired,
  getMastersAccepted: T.func.isRequired,
}

function formatedDate(stringTime) {
  return moment.parseZone(stringTime).format('dddd, DD.MM.YYYY, HH:mm')
}

function prepareDate(date) {
  const day = dayweek_conjugation[moment.utc(date).format('dddd').toLowerCase()]
  const dat = moment.parseZone(date).format(', DD MMMM в HH:mm').toLowerCase()

  return `${day}${dat}`
}

function renderMasters({ masters }, state) {
  const onMasterSelect = (index) => {
    state.handleSelectedMaster(index)
    state.handleRenderingPage('master-select')
  }

  return (
    <div className={css.masters}>
      {masters.map(({ first_name, photo, rating, id }, index) => {
        return (
          <div key={`master-${id}`} className={css.master} onClick={() => onMasterSelect(index)}>
            <Image id={`master-${first_name}`} src={photo || '/assets/user.png'} className={css.masterImg} />
            <div className={css.masterName}>{first_name}</div>
            <div className={css.ratingBlock}>
              <span className={css.ratingNum}>{(+rating).toFixed(1) || 5}</span>
              <Icon name={'star'} size={28} className={css.starIcon} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

const mastersFoundNumber = ['лучшего мастера', 'двух лучших мастеров', 'трех лучших мастеров']

//dump
function renderLoaderPreOrder() {
  return (
    <Fragment>
      <div className={css.greenBlock}>Ищем для вас мастеров</div>
      <div className={css.loaderWrap}>
        <Loader isBig />
      </div>
    </Fragment>
  )
}

function renderFoundMasters(props, state) {
  const { isDetailsOpen, toggleIsDetailsOpen } = state
  const { imageUrls, masters } = props

  return (
    <Fragment>
      <div className={cx(css.greenBlock, css.greenBlockMasters)}>
        {`Мы отобрали ${mastersFoundNumber[masters.length - 1]}`},
        <br />
        {`соответствующ${masters.length === 1 ? 'его' : 'их'} Вашему запросу.`}
        <br />
        Пожалуйста, выберите мастера.
      </div>
      <OrderStatusIcons className={css.iconsStyle} />
      {renderMasters(props, state)}
      <div className={css.time}>{formatedDate(props.time)}</div>
      <OrderDetails
        isOpen={isDetailsOpen}
        isAttachment={!imageUrls.length === 0}
        imageUrls={imageUrls}
        handleOpen={() => toggleIsDetailsOpen(!isDetailsOpen)}
        {...props}
      />
    </Fragment>
  )
}

function renderMasterSelect(props, state) {
  const { masters, setConfirmMaster } = props
  const { selectedMaster, handleRenderingPage } = state
  const { first_name, photo, rating, description, id } = masters[selectedMaster]
  let newDescription = description ? renderDescription(description) : ''

  return (
    <Fragment>
      <OrderStatusIcons className={css.iconsStyle} />
      <Image id={`master-${first_name}`} src={photo || '/assets/user.png'} className={css.masterImgBig} />
      <div className={css.masterInfoBlock}>
        <span className={css.nameSelect}>{first_name}</span>
        <span className={css.ratingSelect}>{(+rating).toFixed(1) || 5}</span>
        <Icon name={'star'} size={28} className={css.starIconSelect} />
      </div>

<div className={css.descriptionSelect}>{newDescription}</div>
      
<Button
        theme={'orange'}
        className={css.button}
        onClick={() => {
          setConfirmMaster(id)
          handleRenderingPage('master-confirm')
        }}
      >
        Подтвердите выбор мастера
      </Button>
{/*<Button
        theme={'transparent'}
        className={cx(css.button, css.lastElem)}
        contentClassName={css.transparent}
        onClick={() => handleRenderingPage('found-masters')}
      >
        Вернуться к выбору
      </Button>*/}
      <div className={css.time}>{formatedDate(props.time)}</div>
    </Fragment>
  )
}

function renderConfirmSelect(props, state) {
  const { masters, time, id } = props
  const { selectedMaster } = state
  const { first_name, last_name } = masters[selectedMaster]

  return (
    <Fragment>
      <OrderStatusIcons className={css.iconsStyle} />
      <div className={css.pageWrap}>
        <Icon name={'ok'} size={79} className={css.okIcon} />
      </div>
      <div className={cx(css.accentText, css.accentTextConfirm)}>{`Мастер ${first_name} ${last_name}`}</div>
      <div className={cx(css.accentText, css.accentTextNormal)}>Прибудет к Вам в</div>
      <div className={css.accentText}>{prepareDate(time)}</div>
      <div className={css.pageWrap}>
        <a href={getFullUrl({ url: `/api/client/order/${id}/calendar/`, fullUrl: true })} target='_blank'>
          <Button theme={'orange'} className={cx(css.button, css.buttonCalendar)}>
            Добавить в календарь
          </Button>
        </a>
      </div>
      <div className={css.pageWrap}>
        <SmartLink to={'/'} theme={'green'} isUnderlined className={css.linkHome}>
          На главную
        </SmartLink>
      </div>
    </Fragment>
  )
}

function renderNotFoundMasters(props, state) {
  return (
    <Fragment>
      <div className={css.pageWrap}>
        <Icon name={'smileSad'} size={79} className={css.smileSadIcon} />
      </div>
      <div className={cx(css.accentText, css.accentNotFound)}>
        К сожалению, сейчас нет
        <br />
        доступных мастеров.
      </div>
      <div className={cx(css.accentText, css.accentNotFound)}>
        Ваш заказ находится в<br />
        ожидании. Наш оператор
        <br />
        свяжется с вами в<br />
        ближайшее время.
      </div>
      <div className={css.pageWrap}>
        <SmartLink to={'/'} theme={'green'} isUnderlined className={css.linkHome}>
          На главную
        </SmartLink>
      </div>
    </Fragment>
  )
}

function renderPagePreOrder(props, state) {
  switch (state.renderingPage) {
    case 'found-masters':
{/*      return renderFoundMasters(props, state)
    case 'master-select': */}
      return renderMasterSelect(props, state)
    case 'not-found-masters':
      return renderNotFoundMasters(props, state)
  }
}

function renderNoTestOrderModal(props, state) {
  const { masters } = props
  const { handleIsModalTestOrder } = state
  return (
    <ModalWrapper
      shouldUseOffset
      name={'testOrderConfirmModal'}
      onCloseClick={() => handleIsModalTestOrder(false)}
      // titleCl={css.timeSelectorModalTitle}
      modalCl={css.noTestOrderModal}
      iconClose
    >
      <div className={css.noTestOrderModalText}>
        К сожалению, <br /> контрольный заказ <br />в данный момент недоступен.
          {masters.length > 0 && <Fragment><br />Но мы нашли для вас мастеров</Fragment>}
      </div>
      <Button theme={'green'} className={css.noTestOrderModalButton} onClick={() => handleIsModalTestOrder(false)}>
        Хорошо
      </Button>
    </ModalWrapper>
  )
}

export default function PreOrder(props) {
  const [isDetailsOpen, toggleIsDetailsOpen] = useState(false)
  const [selectedMaster, handleSelectedMaster] = useState(0)
  const [isLoadingMastersList, handleIsLoadingMastersList] = useState(true)
  const [renderingPage, handleRenderingPage] = useState('found-masters')
  const [isModalTestOrder, handleIsModalTestOrder] = useState(false)
  const state = {
    isDetailsOpen,
    toggleIsDetailsOpen,
    selectedMaster,
    handleSelectedMaster,
    renderingPage,
    handleRenderingPage,
    isModalTestOrder,
    handleIsModalTestOrder,
  }

  const { masters, getMastersAccepted, ready, status, isCommandant, commandant } = props
  const timerGetMasters = useRef(false)

  useEffect(() => {
    timerGetMasters.current = setTimeout(async function setGetStatusLoop() {
      await getMastersAccepted().catch(console.error)
      timerGetMasters.current = setTimeout(setGetStatusLoop, GET_MASTERS_TIMEOUT)
    }, GET_MASTERS_TIMEOUT)
  }, [])

  useEffect(() => {
    return () => {
      setTimeout(() => clearInterval(timerGetMasters.current), 1000)
    }
  }, [])

  useEffect(() => {
    if (ready) {
      setTimeout(() => clearInterval(timerGetMasters.current), 1000)
      handleIsLoadingMastersList(false)
      masters.length === 0 && handleRenderingPage('not-found-masters')
    }
    if (ready && isCommandant && !commandant) {
      handleIsModalTestOrder(true)
    }
  }, [ready])

  if (masters && status === 'MASTER_FOUND') return renderConfirmSelect(props, state)

  return (
    <Fragment>
      {isModalTestOrder && renderNoTestOrderModal(props, state)}

      {isLoadingMastersList ? renderLoaderPreOrder() : renderPagePreOrder(props, state)}
    </Fragment>
  )
}
