import { generateReducer } from './_generator'
import { types } from 'flux/actions/fetch/authConfirm'

export default generateReducer({
  START: types.AUTH_CONFIRM_START,
  FAIL: types.AUTH_CONFIRM_FAIL,
  SUCCESS: types.AUTH_CONFIRM_SUCCESS,
  CLEAR_ERROR: types.AUTH_CONFIRM_CLEAR_ERROR,
}, {
  [types.AUTH_RESET]: (state, payload) => {
    return {
      ...state,
      data: null,
      error: null,
      fetching: false,
    }
  },
})
