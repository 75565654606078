import fetch from 'fetch'

export default ({phone, code}) => {
  return fetch({
    url: '/client/auth/confirm/',
    method: 'POST',
    body: {
      phone,
      confirmation_code: code,
    }
  })
}
