import React, {Fragment, useEffect, useState} from 'react'
import T from 'prop-types'

import Marquee from 'react-double-marquee'

import Icon from 'app/Elements/Icon'
import Button from 'app/Elements/Button'
import SmartLink from 'app/Elements/SmartLink'
import Map from 'app/Elements/Map'
import UnifiedMap, {PROVIDER_GOOGLE, PROVIDER_YANDEX} from 'app/Elements/UnifiedMap'

import {buttons} from 'app/Layout/OrderWrap'

import ParagraphBlock from "app/Elements/ParagraphBlock"
import Footer from 'app/Elements/Footer'

import pluralizeWord from 'helpers/strings/pluralizeWord'

import footer from 'app/Assets/footer.png'
import sberbank from 'app/Assets/sberbank.png'

import cx from 'classnames'
import css from './index.styl'

const p4Title = 'Как это работает?'
const p4Items = [
  'Вы формулируете и размещаете заказ. Обычно — на ближайшее время, как заказ такси. Если вы хотите спланировать свое время на будущее, можете указать другой день и час',
  'Система выбирает лучшего из ближайших свободных мастеров',
  'Вы можете отслеживать перемещения назначенного мастера на веб странице ЕЖэк',
  'Мастер прибывает к вам, проводит диагностику, начинает работу или отправляется за необходимыми материалами',
  'По окончании работы вы оплачиваете время, фактически потраченное мастером на работу и покупку материалов',
  'Вы выставляете оценку мастеру, которая поможет нам мониторить качество услуг',
]


const MASTER_GEO_MARKERS_INTERVAL = 5000

let masterGeoMarkersInterval = null

function renderButtons(props) {
  const {clearDataOrder} = props

  return buttons.map((button) => {
    const {iconName, title, type} = button

    return (
      <SmartLink
        key={title}
        theme={'green'}
        to={`/order${type ? '?type=' + type : ''}`}
        className={css.buttonContainer}
        // disabled={process.env.NODE_ENV === 'production'}
        // style={process.env.NODE_ENV === 'production' ? {opacity: 0.5, pointerEvents: "none"} : {}}
      >
        <Button theme={'transparent'} className={css.button} onClick={() => clearDataOrder()}>
          <Icon name={iconName} size={40} className={css.icon}/>
        </Button>
        <label className={css.buttonLabel}>{title}</label>
      </SmartLink>
    )
  })
}

export default function StartPage(props) {
  const {className, getMasterGeoMarkers, isMobile, isTablet, clientMetadata: {links = {}} = {}} = props

  const isDesktop = !isMobile && !isTablet
  const shouldRenderMap = true
  const mapProvider = PROVIDER_YANDEX

  const [masters, handleMasters] = useState([])
  const [modalIsOpen, handleModal] = useState(false)
  const [mastersFirstLoad, handleMastersFirstLoad] = useState(true)
  const [mastersTotalCount, handleMastersTotalCount] = useState(0)
  const [mastersText, handleMastersText] = useState('Загружаем список мастеров...')

  const openModal = () => {
    const body = document.body

    body.classList.add('overflowed')

    handleModal(true)
  }
  const closeModal = () => {
    const body = document.body

    body.classList.remove('overflowed')

    handleModal(false)
  }

  const onMapUpdated = (ctx) => {
    masters.forEach((master) => {
      const masterMarker = ctx.placedMasterMarkers.get(master.id)

      if (masterMarker) {
        const hintContent = `<div id="content">
                             <div id="siteNotice"></div>
                             <div id="bodyContent">
                              <h4 style="margin: 0 0 6px 0">Мастер</h4>
                              <p style="margin: 0 0 6px 0">ID: ${master.id}</p>
                             </div>
                            </div>`;
        const _specs = (master.full_specs || []).reduce((prevVal, currVal) => `${prevVal}${currVal.type}<br/>`, '')
        const balloonContent = `<div id="content">
                                 <div id="siteNotice"></div>
                                 <div id="bodyContent">
                                    <h4 style="margin: 0 0 6px 0">Мастер</h4>
                                    <p style="margin: 0 0 6px 0">ID: ${master.id}</p>
                                    <h4 style="margin: 0 0 6px 0">Специализации</h4>
                                    <div style="max-height: 100px; overflow-y: auto;">
                                    ${_specs}
                                    </div>
                                 </div>
                                </div>`;

        if (mapProvider === PROVIDER_GOOGLE) {
          if (!masterMarker.masterInfoWindow) {
            masterMarker.masterInfoWindow = new google.maps.InfoWindow({
              content: hintContent,
            })

            masterMarker.addListener('mouseover', () => {
              masterMarker.masterInfoWindow.open({
                anchor: masterMarker,
                map: ctx.map,
                shouldFocus: false,
              })
            })

            masterMarker.addListener('mouseout', () => {
              masterMarker.masterInfoWindow.close()
            })
          }

          if (masterMarker.masterSpecsWindow) {
            masterMarker.masterSpecsWindow.setContent(balloonContent)
          } else {
            masterMarker.masterSpecsWindow = new google.maps.InfoWindow({
              content: balloonContent
            })

            masterMarker.addListener('click', () => {
              masterMarker.masterInfoWindow.close()

              masterMarker.masterSpecsWindow.open({
                anchor: masterMarker,
                map: ctx.map,
                shouldFocus: false,
              })
            })
          }
        }

        if (mapProvider === PROVIDER_YANDEX) {
          masterMarker.properties.set('hintContent', hintContent)
          masterMarker.properties.set('balloonContent', balloonContent)
        }

      }

      ctx.placedMasterMarkers.set(master.id, masterMarker)
    })
  }

  if (shouldRenderMap) {
    useEffect(() => {
      // Mount
      getMasterGeoMarkers()
        .then(({masters = [], totalMastersCount = 0} = {}) => {
          handleMasters(masters)
          handleMastersFirstLoad(false)
          handleMastersTotalCount(totalMastersCount)
        })
      masterGeoMarkersInterval = setInterval(() => {
        getMasterGeoMarkers()
          .then(({masters = [], totalMastersCount = 0} = {}) => {
            handleMasters(masters)
            handleMastersFirstLoad(false)
            handleMastersTotalCount(totalMastersCount)
          })
      }, MASTER_GEO_MARKERS_INTERVAL)

      // Unmount
      return () => clearInterval(masterGeoMarkersInterval)
    }, [])
  }

  useEffect(() => {
    if (!mastersFirstLoad) {
      if (mastersTotalCount === 0) {
        handleMastersText('Мастера поблизости от Вас не найдены')
      } else {
        handleMastersText(`Поблизости от Вас ${mastersTotalCount} ${pluralizeWord(mastersTotalCount, 'мастер', 'мастера', 'мастеров')}`)
      }
    }
  }, [mastersTotalCount, mastersFirstLoad])

  return (
    <Fragment>
      <div className={cx(css.content, isDesktop ? css.contentDesktop : undefined)}>
        {shouldRenderMap && (
          <UnifiedMap
            provider={mapProvider}
            className={css.map}
            actionsDisabled={true}
            markerDisabled={true}
            startCoordinat={{lat: 55.75508560165916, lng: 37.617530822753906}}
            zoom={10}
            masterMarkers={masters}
            infoWindowDisabled={true}
            fullscreenControl={false}
            onMapUpdated={onMapUpdated}
          />
        )}
        <div className={css.contentBlock}>
          <div className={css.willBeAvailableSoon}>
            <div className={css.willBeAvailableSoonText}>
              {mastersText}
            </div>
          </div>
          <div className={css.blockButtons}>

            <div className={cx(css.item, css.serviceLabel)}>ВЫБЕРИТЕ УСЛУГУ</div>

            <div className={css.buttons}>{renderButtons(props)}</div>

            <Button
              onClick={() => openModal()}
              className={css.howItWorkBtn}
              contentClassName={css.howItWorkBtnText}
            >
              Как это работает
            </Button>

            <SmartLink to={'/masters/'} theme={'green'} isUnderlined className={cx(css.item, css.iam, css.link)}>
              Я мастер
            </SmartLink>

            <div className={cx(css.paymentRulesBlock, css.bottom)}>
              <SmartLink
                to={links['purchase_rules'].url}
                theme={'green'} className={css.darkLink}
                isUnderlined
              >
                {links['purchase_rules'].title}
              </SmartLink>

              <img src={sberbank} className={css.sberLogo}/>
            </div>
          </div>
          <div className={css.blockFooter}>
            <img src={footer} className={css.footerImage}/>
          </div>
          <div className={css.marqueeWrap}>
            <Marquee
              direction="left"
              childMargin={0}
              speed={0.18}
            >
              Вызов мастера бесплатно.  Мастер на час - 1500 руб; на пол-дня – 5400 руб.; на день – 9600 руб. Платите по окончании работы.
            </Marquee>
          </div>
        </div>

        {modalIsOpen && (
          <div className={css.videoModalWrap} onClick={() => closeModal()}>
            <div className={css.videoModal}>
              <iframe
                width="900"
                height="467"
                src="https://www.youtube.com/embed/Kkvo2-WJutk"
                frameBorder="0"
                allowFullScreen
              />
              <div className={css.videoModalClose} onClick={() => closeModal()}>
                <Icon name="cross" size={24}/>
              </div>
              <div className={css.videoModalCloseText}>закрыть</div>
            </div>
          </div>
        )}

      </div>

      {/*{!isDesktop && (
        <div className={css.seoContent}>
          <h1 className={css.header}>ЕЖэк — это онлайн сервис, через который жители Москвы заказывают бытовые услуги
            напрямую у индивидуальных профессиональных мастеров.</h1>

          <div className={css.textWrap}>
            <div className={css.text}>
              <h2 className={css.title}>Опытные эксперты</h2>
              <span>Мастера ЕЖэк – сантехники, электрики, сборщики, отделочники, уборщики и цветоводы, поддержку которым оказывает команда опытных экспертов.</span>
            </div>
            <div className={css.text}>
              <h2 className={css.title}>Ваши соседи</h2>
              <span>Наши мастера – ваши соседи. Они придут на заказ быстро, сработают профессионально, и, если у вас появятся вопросы по проделанной работе, будут рядом, чтобы их решить.</span>
            </div>
          </div>

          <h2 className={css.title}>Наши услуги</h2>

          <div className={css.imageWrap}>
            <div className={css.block}>
              <img src="/assets/index/4404bde9c89a4d4e89059084f0005674@2x.png" alt=""/>
              <span>Установка и ремонт сантехники, труб, радиаторов, нагревательных котлов, отопительных приборов, систем водоочистки</span>
            </div>

            <div className={css.block}>
              <img src="/assets/index/anthony-indraus-Bb9jWuTMPUk-unsplash 1@2x.png" alt=""/>
              <span>Монтаж и ремонт электропроводки, осветительных приборов, выключателей, розеток, автоматических ворот, уличного и ландшафтного освещения</span>
            </div>

            <div className={css.block}>
              <img src="/assets/index/76751ad539344a41a9950d2ee585e350@2x.png" alt=""/>
              <span>Сборка, инсталляция, перестановка и ремонт мебели, картин, предметов декора</span>
            </div>

            <div className={css.block}>
              <img src="/assets/index/76751ad0d2ee585e350539344a41a995@2x.png" alt=""/>
              <span>Косметический ремонт внутренних помещений и фасадов</span>
            </div>

            <div className={css.block}>
              <img src="/assets/index/902eec1d034d4126a621ebd062b29163@2x.png" alt=""/>
              <span>Уборка и мойка помещений, окон, балконов, закрытых и открытых террас и веранд, бассейнов, фасадов</span>
            </div>

            <div className={css.block}>
              <img src="/assets/index/d332a8abfccc494ab03b9de8d3fb83e1@2x.png" alt=""/>
              <span>Уход за цветами в доме, садовые и дворницкие работы на придомовой территории</span>
            </div>
          </div>

          <ParagraphBlock
            className={css.paragraphBlock}
            title={p4Title}
            items={p4Items}
            itemsByList
          />

          <div className={css.howItWorkVideoWrap}>
            <iframe
              src="https://www.youtube.com/embed/Kkvo2-WJutk"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </div>
      )}*/}

      <Footer />

    </Fragment>
  )
}

StartPage.propTypes = {
  className: T.string,
}
StartPage.defaultProps = {
  className: '',
}
