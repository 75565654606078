import fetch from 'fetch'

export default ({ id, token }) => {
  if (!token) return Promise.reject()

  return fetch({
    url: `/client/order/${id}/status/`,
    token,
  })
}
