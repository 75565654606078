import React, { useState } from 'react'

import isEmpty from "lodash/isEmpty"

import Input from "app/Elements/Input"
import Button from "app/Elements/Button"
import Footer from 'app/Elements/Footer'
import pageConnector from 'app/Pages/utils/pageConnector'

import { phonePlaceholder } from 'helpers/form/mask'

import clientOrderTerminate from 'fetch/requests/clientOrderTerminate'

import cx from 'classnames'
import css from './index.styl'


function OrderTerminate({history}) {

  const _formFields = {
    fullName: '',
    fullNameError: '',
    email: '',
    emailError: '',
    phone: '',
    phoneError: '',
    orderId: '',
    orderIdError: '',
    message: '',
    messageError: '',
  }

  const [isLoading, handleIsLoading] = useState(false)
  const [formFields, handleFormFields] = useState(_formFields)

  function clearFormFields() {
    handleFormFields(_formFields)
  }

  function checkFields() {
    let errors = {}

    if (!formFields.fullName) {
      errors.fullNameError = 'Введите имя'
    }

    if (!formFields.email) {
      errors.emailError = 'Введите email'
    }

    if (!formFields.phone) {
      errors.phoneError = 'Введите номер телефона'
    }

    if (!formFields.orderId) {
      errors.orderIdError = 'Введите номер заказа'
    }

    if (!formFields.message) {
      errors.messageError = 'Введите сообщение'
    }

    if (!isEmpty(errors)) {
      handleErrors(errors)
    }

    return isEmpty(errors)
  }

  function handleErrors(errors) {
    handleFormFields({
      ...formFields,
      ...errors,
    })
  }

  function handleInput(value, field, isError) {
    handleFormFields({
      ...formFields,
      [field]: !isError ? value : formFields[field],
      [`${field}Error`]: isError ? value : '',
    })
  }

  function handleSubmit(event) {
    event.preventDefault()
    if (checkFields()) {
      handleIsLoading(true)

      clientOrderTerminate({
        full_name: formFields.fullName,
        phone: formFields.phone,
        email: formFields.email,
        order: formFields.orderId,
      }).then(() => {
        clearFormFields()
        handleIsLoading(false)
        setTimeout(() => {
           alert('Ваш запрос принят!')
          history.push('/')
        }, 500)
      }).catch(() => {
        handleIsLoading(false)
      })
    }
  }

  return (
    <div className={css.wrap}>
      <div className={css.content}>
        <div className={cx(css.inputLabel, css.infoText)}>
          Хотите отказаться от заказа и отменить привязку вашей банковской карты?
        </div>
        <form onSubmit={handleSubmit} className={css.form}>
          <div className={css.inputRow}>
            <div className={css.inputLabel}>Имя *</div>
            <Input
              className={css.inputWrap}
              inputClassName={css.input}
              handleInput={(value) => handleInput(value, 'fullName')}
              value={formFields.fullName}
              name={'fullName'}
              id={'fullName'}
              disabled={isLoading}
            />
            <div className={css.inputError}>{formFields.fullNameError}</div>
          </div>
          <div className={css.inputRow}>
            <div className={css.inputLabel}>Email *</div>
            <Input
              className={css.inputWrap}
              inputClassName={css.input}
              handleInput={(value) => handleInput(value, 'email')}
              value={formFields.email}
              type={'email'}
              name={'email'}
              id={'email'}
              disabled={isLoading}
            />
            <div className={css.inputError}>{formFields.emailError}</div>
          </div>
          <div className={css.inputRow}>
            <div className={css.inputLabel}>Телефон *</div>
            <Input
              className={css.inputWrap}
              inputClassName={css.input}
              placeholder={phonePlaceholder}
              handleInput={(value) => handleInput(value, 'phone')}
              value={formFields.phone}
              name={'phone'}
              id={'phone'}
              disabled={isLoading}
            />
            <div className={css.inputError}>{formFields.phoneError}</div>
          </div>
          <div className={css.inputRow}>
            <div className={css.inputLabel}>Номер заказа *</div>
            <Input
              className={css.inputWrap}
              inputClassName={css.input}
              placeholder={''}
              handleInput={(value) => handleInput(value, 'orderId')}
              value={formFields.orderId}
              name={'order_id'}
              id={'order_id'}
              disabled={isLoading}
            />
            <div className={css.inputError}>{formFields.orderIdError}</div>
          </div>
          <div className={css.inputRow}>
            <div className={css.inputLabel}>Ваш комментарий *</div>
            <Input
              textArea
              className={css.inputWrap}
              inputClassName={cx(css.input, css.textarea)}
              handleInput={(value) => handleInput(value, 'message')}
              value={formFields.message}
              element={'textarea'}
              name={'message'}
              id={'message'}
              disabled={isLoading}
            />
            <div className={css.inputError}>{formFields.messageError}</div>
          </div>

          <Button
            theme={'green'}
            className={css.button}
            contentClassName={css.buttonText}
            isBusy={isLoading}
            type="submit"
          >
            Отправить
          </Button>
        </form>
      </div>
      <Footer/>
    </div>
  )
}

export default pageConnector(OrderTerminate, {withRouter: true})
