import fetch from 'fetch'

export const uploadFile = (file) => {
  const formData = new FormData()

  formData.append('file', file)

  return fetch({
    method: 'POST',
    url: `/client/upload/`,
    body: formData,
    noHeaders: true,
  })
}
