import React from 'react'
import T from 'prop-types'

import cx from 'classnames'
import css from './index.styl'

export default function Attention(props) {
  const {
    elem: Element,
    className,
    children,
  } = props

  return (
    <Element className={cx(css.wrap, className)}>
      <span className={css.icon}>i</span>
      {children}
    </Element>
  )
}

Attention.propTypes = {
  elem: T.string,
  className: T.string,
}
Attention.defaultProps = {
  elem: 'div',
  className: '',
}
