import React, {useEffect} from 'react'

import Footer from 'app/Elements/Footer'
import ParagraphBlock from 'app/Elements/ParagraphBlock'

import css from './index.styl'

const p1Title = 'ТАРИФЫ МАСТЕРОВ ЕЖЭК'
const p1Items = [
  'Тарифная политика Мастеров ЕЖЭК разработана специально таким образом, чтобы позволить ежедневно пользоваться услугами ЕЖЭК Заказчикам, обладающим как самым небольшим бюджетом, так и внушительной «глубиной кошелька».',
  'Мастера получают от Заказчиков оплату за выполнение заказов, которая рассчитывается по следующим <u>тарифам</u>:',
]

const p2Title = '1) Повременная оплата срочных заказов.'
const p2Items = [
  'Вызов мастера ЕЖЭК осуществляется бесплатно.',
  'При создании заказа осуществляется привязка банковской карты Заказчика к заказу, для чего с неё списывается и впоследствии возвращается Заказчику 1 рубль.',
  'Минимальный заказ стоит 1500 рублей (в его стоимость входит подбор подходящего Мастера и его работа продолжительностью до 1 часа).',
  'Подбор подходящего Мастера включает проверку данных и документов Мастера, многократное тестирование, инструктаж, организацию экспертной поддержки и поддержки Контактного центра, которые были осуществлены нами заранее, а также выбор Мастера, наилучшим образом подходящего для выполнения конкретного заказа, из Мастеров ЕЖЭК, находящихся поблизости от места этого заказа, и организацию скорейшего прибытия выбранного Мастера на этот заказ.',
  'Начиная с 61-й минуты, стоимость работы Мастера рассчитывается поминутно исходя из базового тарифа 1 500 рублей/час.',
  'На работу дольше 4х часов предоставляется скидка 10%. То есть заказ, выполнение которого заняло пол-дня, стоит 5 400 рублей. Если работа длится дольше 8ми часов, то на весь заказ даётся скидка 20%. Таким образом, стоимость заказа, который потребовал работы Мастера в течение одного рабочего дня продолжительностью 8 часов, составляет 9 600 рублей.',
  'Стоимость срочных заказов не зависит от квалификации Мастера, так как Мастера ЕЖЭК, выполняющие срочные заказы, имеют одинаково высокую квалификацию и полностью взаимозаменяемы.',
]

const p3Title = '2) Повременная оплата предварительных заказов'
const p3Items = [
  'Если Вам удобнее запланировать заказ заранее, или если все Мастера ЕЖЭК в течение дня заняты, то Вы можете разместить заказ по предварительной записи на любое удобное для Вас время, начиная с дня, следующего за днем размещения заказа.',
  'Работы по предварительным заказам производятся по тарифам срочных заказов.',
]

const p4Title = '3) Плата за покупку материалов, инструментов и/или запчастей мастером в ходе исполнения заказа'
const p4Items = [
  'Такая плата взимается по тарифу, сниженному на 60%, но не более 600 рублей за 60 минут отлучки за материалами вне зависимости от того, исполняется ли срочный заказ, или заказ по предварительной записи.',
  'Эта плата установлена на таком низком уровне, чтобы Мастеру было не выгодно задерживаться при походе за материалами, так как это время тарифицируется значительно ниже, чем сама работа, но при этом, чтобы недостаток материалов, инструментов и/или запчастей не служил препятствием для завершения заказа и Заказчик мог себе позволить отправить Мастера за ними в магазин.',
  'Стоимость самих закупленных материалов, инструментов и/или запчастей компенсируется  Заказчиком Мастеру напрямую по себестоимости на основании документов, подтверждающих эту себестоимость.',
]

const p5Title = '4) Надбавки'
const p5Items = [
  '<i>4.1 Удалённость от метро</i>',
  'Если место выполнения заказа расположено более чем в 15ти минутах хода от метро, стоимость минимального заказа увеличивается на 600 рублей. Мастер вправе отказаться от соответствующей надбавки и дать Заказчику скидку на часть или всю ее сумму по своему усмотрению.',
  '<i>4.2 Работа с напарником</i>',
  'Если работа требует участия напарника, то он вызывается Заказчиком в обычном порядке на отдельный срочный или предварительный заказ и оплачивается им отдельно.',
  '<i>4.3 «Чаевые»</i>',
  'Заказчик по своему желанию может поблагодарить мастера за хорошую работу, увеличив оплату. «Чаевые» заносятся в приложение наравне с другими платежами.',
]

const p6Title = '5) Штрафы за отмену заказа'
const p6Items = [
  'Штраф в размере стоимости минимального заказа взимается с Заказчика в случае необоснованно отмененного им срочного заказа, а также предварительного заказа менее чем за один час до его начала.',
]

const p7Title = '6) Акции'
const p7Items = [
  'ЕЖЭК заинтересован в своевременном удовлетворении ситуационных и сезонных потребностей заказчиков и регулярно проводит соответствующие акции. В рамках этих акций Заказчикам предоставляются скидки на отдельные заказы или на целый пакет услуг. Данные скидки предоставляются в дополнение к скидкам за длительность заказа – пол-дня и целый день.',
  // 'Дата публикации: 03 марта 2022 г.',
]

export default function CodexTariffs(props) {
  useEffect(() => {
    document.title = 'ТАРИФЫ МАСТЕРОВ ЕЖэк'
  }, [props.title])

  return (
    <div className={css.wrap}>
      <div className={css.content}>
        <ParagraphBlock title={p1Title} items={p1Items} className={css.p1} />
        <ParagraphBlock title={p2Title} items={p2Items} />
        <ParagraphBlock title={p3Title} items={p3Items} />
        <ParagraphBlock title={p4Title} items={p4Items} />
        <ParagraphBlock title={p5Title} items={p5Items} />
        <ParagraphBlock title={p6Title} items={p6Items} />
        <ParagraphBlock title={p7Title} items={p7Items} />
      </div>
      <Footer />
    </div>
  )
}
