import React, {useState} from 'react'

import Button from 'app/Elements/Button'
import Icon from 'app/Elements/Icon'
import SmartLink from 'app/Elements/SmartLink'
import DateSelector from 'app/Elements/DateSelector'

import {ModalWrapper} from 'app/Layout/Modal'

import moment, {getMinTimeForPreOrder} from 'helpers/moment'

import css from './index.styl'
import cx from 'classnames'

export default function ModalCanceled({type, setOrderToNormal, handleIsModalCanceledOpen}) {
    const [date, handleDate] = useState(moment().format('YYYY-MM-DD'))
    const [time, handleTime] = useState(getMinTimeForPreOrder().format('HH:mm'))

    const state = {
        date, handleDate,
        time, handleTime
    }

    const activeTransferOrder = date !== '' && time !== ''

    return (
        <ModalWrapper
            shouldUseOffset
            name={'masterNotFoundModal'}
            title={'Ваш заказ был отменен'}
            titleCl={css.title}
            modalCl={css.modal}
        >
            <div className={css.text}>
                <p>{type === 'HOT' ? 'Вы можете оформить предварительный заказ на другое время' : 'Для уточнения деталей свяжитесь с администрацией'}</p>
            </div>

            {type === 'HOT' && (
                <DateSelector state={state}/>
            )}

            <div>
                {type === 'HOT' && (
                    <Button
                        theme={'orange'}
                        className={cx(css.button)}
                        disabled={!activeTransferOrder}
                        onClick={() => {
                            handleIsModalCanceledOpen(false)
                            setOrderToNormal(`${date}T${time}:00Z`)
                        }}
                    >
                        Разместить предварительный заказ
                    </Button>
                )}

                <Button
                    theme={'green'}
                    className={cx(css.button)}
                    href="tel:+74951985495"
                    asLink
                >
                    <Icon name={'call'} size={28} className={css.callIcon}/>
                    Звонок администратору
                </Button>

                <div className={css.wrap}>
                    <SmartLink to={'/'} theme={'green'} isUnderlined className={css.linkHome}>
                        На главную
                    </SmartLink>
                </div>
            </div>
        </ModalWrapper>
    )
}
