import React from 'react'
import T from "prop-types"
import qs from 'query-string'

import Icon from 'app/Elements/Icon'
import SmartLink from 'app/Elements/SmartLink'
import ListGroup from 'app/Elements/ListGroup'
import ListGroupItem from 'app/Elements/ListGroupItem'
import Pagination from 'app/Elements/Pagination'
import Rating from 'app/Elements/Rating'
import Card, {CardHeader, CardContent} from 'app/Elements/Card'
import Loader from 'app/Elements/Loader'
import Subtext from 'app/Elements/Subtext'

import capitalizeFirstLetter from 'helpers/strings/capitalizeFirstLetter'
import moment from 'helpers/moment'
import getReviews from 'fetch/requests/getReviews'

import cx from 'classnames'
import css from './index.styl'


const SERVICE_TYPE = {
  UNSET: {
    iconName: "info",
    label: "",
  },
  REPAIR: {
    iconName: "settings",
    label: "Починить",
  },
  SETUP: {
    iconName: "shower",
    label: "Установить",
  },
  ASSEMBLE: {
    iconName: "shelve",
    label: "Собрать",
  },
  REARRANGE: {
    iconName: "cargo",
    label: "Переставить",
  },
  CLEAN: {
    iconName: "hover",
    label: "Убрать",
  },
  PLANT: {
    iconName: "plant",
    label: "Посадить",
  },
}

export default class ReviewFeed extends React.Component {

  static propTypes = {
    children: T.node,
    className: T.string,
    clients: T.bool,
    masters: T.bool,
  }

  static defaultProps = {
    clients: false,
    masters: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      total: 0,
      loading: false,
    }
  }

  componentDidMount() {
    this.loadFeed()
  }

  shortName(fname = "", lname = "") {
    return `${capitalizeFirstLetter(fname)} ${lname ? capitalizeFirstLetter(lname.charAt(0)) + "." : ""}`
  }

  toggleLoading = () => {
    const {loading} = this.state

    this.setState({loading: !loading})
  }

  loadFeed = () => {
    const {clients, masters} = this.props

    this.toggleLoading()

    getReviews({
      assignment: (masters && 'master') || (clients && 'client')
    }).then(
      (resp) => {
        this.setState({items: resp.results, total: resp.count}, () => this.toggleLoading())
      },
      () => {
        this.toggleLoading()
      },
    )
  }

  renderLoading = () => {
    const {
      loading,
    } = this.state

    return loading && (
      <div className={css.loading}>
        <Loader isBig/>
      </div>
    )
  }

  renderReviews = () => {
    const {
      masters,
      clients,
    } = this.props

    const {
      items,
    } = this.state

    return items.length > 0 && (<ListGroup>
      {items.map((feed, idx) => (<ListGroupItem key={`feed_${idx}`}>
        <Card className={css.review}>
          <CardHeader className={css.header}>
            {clients && (
              feed.partner ? (
                <div>
                  <img src={feed.partner.logo} className={css.avatar} alt=""/>
                </div>
              ) : (
                <div className={css.avatarIcon}>
                  <Icon
                    name={SERVICE_TYPE[feed.service_type].iconName}
                    size={80}
                    className={css.icon}
                  />
                </div>
              )
            )}
            {masters && (
              <div>
                <img src={feed.master.photo} className={css.avatar} alt=""/>
              </div>
            )}
          </CardHeader>
          <CardContent className={css.content}>
            <div className={css.contentHeader}>
              <h3 className={css.title}>
                {clients && this.shortName(feed.client.first_name, feed.client.last_name)}
                {masters && this.shortName(feed.master.first_name, feed.master.last_name)}
              </h3>

              {clients && (<Rating rate={+feed.client_review.result} size={15} style={{margin: '5px 0'}}/>)}
              {masters && (<Rating rate={+feed.master_review.result} size={15} style={{margin: '5px 0'}}/>)}
            </div>

            <Subtext>
              {clients && feed.client_review.public_text}
              {masters && feed.master_review.public_text}
            </Subtext>

            <p className={css.contentText}>
              {`${moment(feed.created_at).format("MMMM D, YYYY")}, ${feed.geo_city}`}
            </p>

            {feed.specs.map((spec, idx) => (<SmartLink
              key={`spec-${idx}`}
              theme={'green'}
              to={`#`}
              className={cx(css.highlight)}
            >
              {spec.title}
            </SmartLink>))}
          </CardContent>
        </Card>
      </ListGroupItem>))}
    </ListGroup>)
  }

  renderPagination = () => {
    const {
      location,
      total
    } = this.props

    const {page = 1} = qs.parse(location.search)

    return (
      <Pagination
        location={location}
        perPage={total > 5 ? 5 : total}
        page={+page}
        total={total}
      />
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderLoading()}

        {this.renderReviews()}

        {this.renderPagination()}
      </React.Fragment>
    )
  }
}
