import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Route from 'react-router/Route'
import renderRoutes from 'react-router-config/renderRoutes'
import {loadAsyncConnect} from '../helpers/utils'

export class AsyncConnect extends Component {
  static propTypes = {
    render: PropTypes.func,
    reloadOnPropsChange: PropTypes.func,
    routes: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    reloadOnHashChange: PropTypes.bool,
  }

  static contextTypes = {
    store: PropTypes.object.isRequired,
  }

  static defaultProps = {
    reloadOnHashChange: true,
  }

  constructor(props, context) {
    super(props, context)

    // console.log("constructor")
    let routeAvailable = true
    if (typeof window === "undefined" || !window.__LOAD_ON_SERVER__) {
      routeAvailable = false
      this.loadAsyncData(this.props)
    }

    this.state = {
      routeAvailable
    }
  }

  componentDidMount() {
    // console.log("componentDidMount")
    // if (!window.__LOAD_ON_SERVER__) {
    //   this.loadAsyncData(this.props)
    // }
  }

  componentWillReceiveProps(nextProps) {
    const {reloadOnHashChange} = nextProps

    let navigated = (
      this.props.location.pathname !== nextProps.location.pathname ||
      this.props.location.search !== nextProps.location.search
    )

    if (reloadOnHashChange && !navigated) {
      navigated = this.props.location.hash !== nextProps.location.hash
    }

    // Allow a user supplied function to determine if an async reload is necessary
    if (navigated) {
      // console.log("navigated")
      this.loadAsyncData(nextProps)
    }
  }

  loadAsyncData(props) {
    const loadResult = loadAsyncConnect({...props, ...this.context})
      .then(() => this.setState({routeAvailable: true}))
      .catch(() => this.setState({routeAvailable: true}))
  }

  render() {
    const {routes, location} = this.props
    const {routeAvailable} = this.state

    if (typeof window !== 'undefined')
      if (!routeAvailable)
        return null

    return (
      <Route
        location={location}
        render={() => renderRoutes(routes)}
      />
    )
  }
}

export default AsyncConnect
