import React from 'react'

export default function ListActiveIcon(props) {
  const {className} = props

  return (
    <svg className={className} width="21" height="29" viewBox="0 0 21 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 19V3.00691C20 1.8986 19.0222 1 17.8161 1H7" stroke="#F5A623" strokeWidth="2" strokeLinecap="round"/>
      <path d="M4.55396 1H3.18705C1.97925 1 1 1.96714 1 3.16V25.84C1 27.0329 1.97925 28 3.18705 28H17.813C19.0207 28 20 27.0329 20 25.84V22.6005" stroke="#F5A623" strokeWidth="2" strokeLinecap="round"/>
      <path d="M4 14H16" stroke="#F5A623" strokeWidth="2" strokeLinecap="round"/>
      <path d="M4 19H16" stroke="#F5A623" strokeWidth="2" strokeLinecap="round"/>
      <path d="M4 10H16" stroke="#F5A623" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  )
}
