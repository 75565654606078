import {connect} from 'react-redux'

/**
 * Wraps react components with data loaders
 * @param  {Array} asyncItems
 * @return {WrappedComponent}
 */
function wrapWithDispatch(asyncItems) {
  return asyncItems.map((item) => {

    return {
      ...item,
    }
  })
}

/**
 * Exports decorator, which wraps React components with asyncConnect and connect at the same time
 * @param  {Array} asyncItems
 * @param  {Function} [mapStateToProps]
 * @param  {Object|Function} [mapDispatchToProps]
 * @param  {Function} [mergeProps]
 * @param  {Object} [options]
 * @return {Function}
 */
export function asyncConnectWithoutProps(asyncItems, mapStateToProps, mapDispatchToProps, mergeProps, options) {
  return (Component) => {
    Component.reduxAsyncConnect = wrapWithDispatch(asyncItems)

    return connect(mapStateToProps, mapDispatchToProps, mergeProps, options)(Component)
  }
}
