import React, {useRef, useEffect, useState, Fragment} from 'react'
import T from 'prop-types'
import { push } from 'react-router-redux'
import { withRouter } from 'react-router'
import { matchRoutes } from 'react-router-config'
import { parse } from 'query-string'
import renderRoutes from 'react-router-config/renderRoutes'

import Icon from 'app/Elements/Icon'
import MobileMenu from 'app/Elements/MobileMenu'

//** page utils
import pageConnector from 'app/Pages/utils/pageConnector'
import Page404 from 'app/PagesXXX/404/index.connector'

import landingText from 'fetch/requests/landingText'
import partnerInfo from 'fetch/requests/partnerInfo'

import { defineTitle } from 'app/Layout/OrderWrap'
import { scrollToTop } from 'helpers/scrollTo'

import { selectOrderField } from 'flux/selectors/order'

import { getOrderStatus, clearDataOrder } from 'flux/actions/order'
import getOrderInfoLoader from 'flux/loaders/orderInfo'

import css from './index.styl'

import newOrderSlugs from 'app/Layout/OrderInfoWrap/slugs'

const BILLING_SCOPE = "default"

let orderInfoInterval

function OrderInfoWrap(props) {
  const {
    location: {
      pathname, search,
    } = {},
    match: { params: { slug = BILLING_SCOPE } = {}} = {},
    route,
    id,
    getOrderStatus,
    goToPage,
    clearDataOrder,
    ...restProps
  } = props

  const landingTextEmpty = {
    "slug": "",
    "title":"",
    "slogan": "",
    "head_text": {"html":"","delta":"","plain":""},
    "head_sub_text":{"html":"","delta":"","plain":""},
    "head_sub_text_small":{"html":"","delta":"","plain":""},
    "body_text_a":{"html":"","delta":"","plain":""},
    "body_text_b":{"html":"","delta":"","plain":""},
    "body_sub_text_a":{"html":"","delta":"","plain":""},
    "body_sub_text_b":{"html":"","delta":"","plain":""},
    "warning_text_a":{"html":"","delta":"","plain":""},
    "warning_text_b":{"html":"","delta":"","plain":""}
  }

  const [landingTexts, setLanding] = useState(null)
  const [partnerInfoData, setPartnerInfoData] = useState({is_active: true})

  // const newOrderSlug = slug && (newOrderSlugs.find(s => slug == s.slug) || {
  //   slug,
  //   title: 'Вызвать мастера',
  //   type: 'settings',
  //   iconName: 'settings'
  // })

  useEffect(() => {
    if (slug) {
      landingText({slug}).then(
          (resp) => {
            setLanding(resp)
          },
          (err) => {
          }
      )
    }
  }, [slug])

  useEffect(() => {
    if (slug) {
      partnerInfo({slug}).then(
          (resp) => {
            setPartnerInfoData(resp)
          },
          (err) => {
          }
      )
    }
  }, [slug])

  const { type = '' } = parse(search) || {}
  let title = ''

  if (slug !== BILLING_SCOPE) {
    title = landingTexts ? landingTexts.title : ''
  } else if (type) {
    title = defineTitle(type).title
  } else {
    title = props.title
  }

  const topRef = useRef(null)
  useEffect(() => {
    scrollToTop(topRef, true, { topOffset: 5 })
    document.title = title
  }, [title])

  useEffect(() => {
    if (id) {
      orderInfoInterval = setInterval(() => getOrderStatus(id), 3000)
      return () => {
        // Unmount
        clearInterval(orderInfoInterval)
        clearDataOrder()
      }
    } else {
      clearInterval(orderInfoInterval)
    }
  }, [id])

  let orderNumText
  if (id) {
    orderNumText = `Заказ #${id}`
  } else if (pathname.startsWith('/order/new')) {
    orderNumText = 'Новый заказ'
  }

  return (
    <div className={css.wrap} ref={topRef}>
      {partnerInfoData.is_active ? (
          <Fragment>
            <div className={css.header}>
              {orderNumText && <h3 className={css.orderNum}>{orderNumText}</h3>}

              {!orderNumText && <h3 className={css.orderTitle}>{title}</h3>}
            </div>

            { renderRoutes(route.routes, {
                ...restProps,
                newOrderTitle: title,
                newOrderType: type,
                newOrderSlug: slug,
                newOrderLabels: landingTexts || landingTextEmpty,
                partnerInfoData, }) }
          </Fragment>
      ) : (
          <Page404 />
      )}
    </div>
  )
}

OrderInfoWrap.propTypes = {
  id: T.oneOfType([T.string, T.number]),
  route: T.object.isRequired,
  goToPage: T.func.isRequired,
  getOrderStatus: T.func.isRequired,
}
OrderInfoWrap.defaultProps = {}

const mapStateToProps = (state) => ({
  id: selectOrderField('id', state),
  title: selectOrderField('title', state),
})
const mapDispatchToProps = (dispatch) => ({
  goToPage: (url) => dispatch(push(url)),
  getOrderStatus: (id) => dispatch(getOrderStatus(id)),
  clearDataOrder: () => dispatch(clearDataOrder()),
})

export default pageConnector(OrderInfoWrap, {
  asyncLoaders: [getOrderInfoLoader],
  mapStateToProps,
  mapDispatchToProps,
  withRouter: true,
})
