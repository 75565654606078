import {
  placeOrder,
  updateOrder,
  updateListStreet,
  getMasterGeoMarkers,
  getOrderCheckCommandant,
} from 'flux/actions/order'

import { authRequest } from 'flux/actions/fetch/authRequest'
import { authConfirm, authReset } from 'flux/actions/fetch/authConfirm'

import { generateStateToProps, generateDispatchToProps } from 'flux/utils/orderFluxGenerator'

import { selectAuthConfirmationCode } from 'flux/selectors/fetch/authRequest'
import { composeAddress } from 'flux/selectors/order'

import { selectAuthToken, selectAuthConfirmError } from 'flux/selectors/fetch/authConfirm'
import { selectError } from 'flux/selectors/order'

import getOrderBillingInfo from 'flux/loaders/orderBillingInfo'

import orderBillingInfo from 'fetch/requests/orderBillingInfo'

//** component
import Component from './index'
import pageConnector from "../utils/pageConnector"

const mapStateToProps = (state) => ({
  ...generateStateToProps(state),
  address: composeAddress(state),
  confirmationCode: selectAuthConfirmationCode(state),
  authToken: selectAuthToken(state),
  authError: selectAuthConfirmError(state),
  orderError: selectError(state),
})

const mapDispatchToProps = (dispatch) => ({
  ...generateDispatchToProps(dispatch),
  authReset: () => dispatch(authReset()),
  requestAuth: (data) => dispatch(authRequest(data)),
  confirmAuth: (params) => dispatch(authConfirm(params)),
  placeOrder: (order) => dispatch(placeOrder(order)),
  updateOrder: (order) => dispatch(updateOrder(order)),
  updateListStreet: (list) => dispatch(updateListStreet(list)),
  getOrderCheckCommandant: (time) => dispatch(getOrderCheckCommandant(time)),
  getMasterGeoMarkers: () => dispatch(getMasterGeoMarkers({getAll: true})),
  orderBillingInfo: (args) => orderBillingInfo(args),
})

export default pageConnector(Component, {
  asyncLoaders: [getOrderBillingInfo],
  mapStateToProps,
  mapDispatchToProps,
  withRouter: true,
})