import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import { generateStateToProps } from 'flux/utils/orderFluxGenerator'
import { selectError } from 'flux/selectors/order'

//** component
import Component from './index'

import {
  setOrderToNormal,
  setOrderToDelayed,
  rePlaceOrder,
  orderReset,
} from 'flux/actions/order'

import {
  authReset
} from 'flux/actions/fetch/authConfirm'

const mapStateToProps = state => ({
  ...generateStateToProps(state),
  orderError: selectError(state),
})

const mapDispatchToProps = dispatch => ({
  setOrderToNormal: (time) => dispatch(setOrderToNormal(time)),
  setOrderToDelayed: () => dispatch(setOrderToDelayed()),
  rePlaceOrder: () => dispatch(rePlaceOrder()),
  authReset: () => dispatch(authReset()),
  orderReset: () => dispatch(orderReset()),
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component))