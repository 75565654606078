import React from 'react'

import Footer from 'app/Elements/Footer'
import ParagraphBlock from 'app/Elements/ParagraphBlock'

import css from './index.styl'

const p1Highlighted = 'Часто задаваемые вопросы и ответы на них'
const p1Items = [
  '<b>1. Зачем нужен ЕЖЭК?</b>',
  'Для оперативного вызова специалистов по решению бытовых задач с гарантированной квалификацией по справедливым и предсказуемым ценам.',
  '<b><br />2. Почему мне стоит использовать ЕЖЭК?</b>',
  'Потому что он полезен для избавления от стресса, связанного с бытовыми проблемами, освобождения времени для близких, творчества и приключений и при этом удобнее и надежнее аналогов.',
  '<b><br />3. Кто может заказать услуги ЕЖЭК?</b>',
  'Любое физическое или юридическое лицо, способное оплатить заказ банковской картой. При оплате услуг формируется чек, который приходит по электронной почте, указанной приразмещении заказа. Услуги мастеров ЕЖЭК не облагаются НДС, поэтому счет-фактура не формируется. В случае необходимости служба поддержки по может оформить договор и счет на оказанные услуги с подробным описанием.',
  '<b><br />4. Как определяется стоимость услуг мастеров ЕЖЭК?</b>',
  'Определяющим показателем сложности задачи является время, затраченное на ее выполнение. Поэтому стоимостььуслуг мастеров ЕЖЭК рассчитывается повременно.',
  'В настоящее время стоимость минимального заказа составляет 4 500 руб., а время его исполнения – 3 часа. После выхода сервиса на полную мощность с тоимость минимального заказа сократится до 1 500 руб., а время его исполнения до одного часа.',
  'Далее услуги тарифицируются поминутно, также исходя из стоимости 1 500 руб/ 60 минут работы мастера.',
  'За время мастера на поход в магазин для покупки недостающих материалов и запчастей, а также на обслуживание заказов по адресам, удаленным от метро более чем на 15 минут пешком, взимается дополнительная плата. Более подробно – см. Статью «Тарифы ЕЖЭК» в разделе «Кодекс». На крупные заказы предоставляется скидка: 10% на заказы от 4-х часов и 20% на заказы от 8-ми часов.',
  'На стоимость влияют сезонные скидки и акции. Например, генеральная уборка квартиры до или после праздников, сезонная мойка окон, весенняя профилактика сантехники или электрики, субботник во дворе или в саду, косметический ремонт квартиры летом или сборка мебели к началу учебного года обойдутся на 20% дешевле обычного. Также мы устраиваем дополнительные акции - сюрпризы и дарим подарки самым лояльным пользователям. О всех этих событиях мы объявляем на каналах ЕЖЭК в соцсетях.',
  'Примерную стоимость выполнения мастером ЕЖЭК необходимых Вам работ можно рассчитать при помощи калькулятора, нажав на кнопку «Сколько это стоит» в разделе заказа мастер.',
  '<b><br />5. Надо ли платить при размещении заказа?</b>',
  'Нет, не надо. Оплата - по факту выполнения работ. Но надо гарантировать заказ, введя номер карты, которой Вы планируете оплатить выполненную работу и с которой в момент размещения заказа с целью ее проверки списывается один рубль.',
  '<b><br />6. Сколько я должен буду заплатить точно?</b>',
  'Точная стоимость зависит от времени выполнения необходимых Вам работ. Заранее она не известна. Примерную стоимость можно рассчитать при помощи калькулятора ЕЖЭК, нажав на кнопку «Сколько это стоит» в разделе заказа мастера. Эту стоимость можно зафиксировать, указав ее при размещении заказа в разделе «Что необходимо сделать». Если за эту цену все желаемые работы выполнить будет невозможно, мастер предложит оптимальное решение в рамках заданного бюджета. За скидками и акциями следите тут: <a href="https://t.me/Ezheklive">https://t.me/Ezheklive</a>.',
  '<b><br />7. Можно ли разместить заказ по телефону?</b>',
  'Срочный заказ размещается через веб-приложение тут: <a href="https://ezhek.ru">https://ezhek.ru</a> с указанием времени его выполнения «Сейчас». Только так ЕЖЭК может обеспечить оперативный подбор лучшего из ближайших к Вам мастеров и его прибытие на заказ.',
  'Предварительные заказы также размещаются через веб-приложение с указанием времени, потому что только так Вы сможете сделать наиболее приемлемый для себя выбор мастера, предварительно ознакомившись с его профилем.',
  'Поддержка уже размещенных через сайт заказов осуществляется по телефону с 9 часов утра до 18 часов вечера без перерывов и выходных. После выхода сервиса на полную мощность поддержка будет осуществляться круглосуточно – 24/7.',
  '<b><br />8. Как узнать, выполняет ли ЕЖЭК определенный вид работ?</b>',
  'ЕЖЭК выполняет все виды работ по направлениям, указанным в разделе "Услуги ЕЖЭК". Даже если Ваш запрос узкоспециальный, кто-то из мастеров ЕЖЭК обязательно располагает соответствующими навыками и опытом. Просто разместите заказ на нашем сайте, и этот мастер оперативно отправится к Вам.',
  '<b><br />9. Как быстро мастер может прибыть к заказчику?</b>',
  'Важный принцип работы ЕЖЭК – территориальный. Наши мастера – ваши соседи. Им не потребуется много времени на дорогу к вам. После выхода сервиса на полную мощность время прихода мастера на срочный заказ будет ограничиваться 30 минутами.',
  'Если вам больше подходит предварительная запись на определенное время, это тоже возможно начиная с дня, следующего за размещением заказа.',
  '<b><br />10. Где территориально ЕЖЭК выполняет заказы?</b>',
  'Так как основной принцип работы ЕЖЭК – территориальный, то заказы выполняются на тех территориях, куда мастера ЕЖЭК могут прибыть достаточно оперативно. В настоящее время это вся Москва в пределах МКАД, а также районы за МКАД, оснащённые линиями метро.',
  'На карте в разделе заказа мастера можно увидеть, где находятся мастера ЕЖЭК в текущий момент времени.',
  '<b><br />11. Можно ли заказать услугу для друзей (родителей, детей), и оплатить с моей карты?</b>',
  'Конечно! Разместите заказ на сайте, указав в графе «адрес» - адрес друзей (родителей, детей), а карту для бронирования мастера и последующего списания средств привяжите свою. В зависимости от того, что Вам более удобно, номер телефона для связи может быть указан или Ваш, или друзей (родителей, детей). Все дополнительные вопросы по размещенному заказу поможет урегулировать служба поддержки ЕЖЭК.',
  '<b><br />12.	Что будет, если мастеру нужно выйти в магазин за запасными частями и материалами?</b>',
  'Поход мастеров за материалами оплачивается по тарифу, сниженному на 60% — 600 руб/ 60 мин. Общая сумма ограничена 600 руб. Таким образом, если поход занял больше, чем 60 минут, то дополнительное время не оплачивается. Стоимость похода за материалами включается в общую стоимость заказа, рассчитываемую автоматически. Так, например, если мастер работал один час, после чего выяснил, что нужны дополнительные материалы или запчасти, сходил за ними в ближайший магазин за 1 час и 10 минут и потом работал еще час, то стоимость заказа составит 3 600 рублей (1 500 рублей (первый час работы) + 600 рублей (поход за материалами) + 1 500 рублей (стоимость второго часа работы)). Передвижение мастера во время отлучки вы можете отслеживать через веб страницу ЕЖЭК.',
  '<b><br />13.	Что такое контрольный заказ?</b>',
  'Контрольный  заказ – это заказ, выполняемый в присутствии эксперта ЕЖЭК в рамках нашей программы контроля качества. Такой заказ выполняется за наш счет и Вами не оплачивается. Для размещения такого заказа заказчик должен дать своё согласие на присутствие на заказе помимо мастера ещё и эксперта ЕЖЭК.',
  '<b><br />14.	Куда обратиться, если мастер не прибыл в указанное время на заказ, если мне нужно уехать до окончания заказа или если возникли другие вопросы?</b>',
  'О любых проблемах и вопросах, связанных с заказами, которые размещены через сервис ЕЖЭК, заказчик должен как можно быстрее сообщить в Контактный центр ЕЖЭК по телефону, указанному в веб-приложении ЕЖЭК и на сайте в разделе «Контакты». Мы немедленно предпримем все необходимые меры для устранения проблемы и ответим на любые вопросы.',
  '<b><br />15.	Может ли мастер заранее сообщить стоимость работ?</b>',
  'Мастер может примерно оценить стоимость работ после проведения диагностики. Эта стоимость может измениться в ходе выполнения заказа, если откроются осложняющие обстоятельства (скрытые дефекты систем коммуникаций, оборудования и т.п.).',
  '<b><br />16.	Могу ли я оплатить заказ наличными деньгами?</b>',
  'Оплата работы мастеров ЕЖЭК осуществляется банковскими картами через эквайринг Сбербанка или при помощи SberPay, интегрированные в сервис ЕЖЭК.',
  '<b><br />17.	Каковы гарантии качества оказываемых услуг?</b>',
  'За качеством работы мастеров следит Контактный центр ЕЖЭК. Кроме этого мастера находятся на связи с экспертами, которые своевременно дают консультации по технически сложным вопросам.',
  '<b><br />18.	Каковы гарантии безопасности заказчика и мастера?</b>',
  'ЕЖЭК обеспечивает безопасность заказчика и мастера засчёт тщательного отбора мастеров, а также мониторинга взаимодействия заказчиков и мастеров Контактным центром ЕЖЭК.',
  '<b><br />19.	Местные ли мастера ЕЖЭК?</b>',
  'Мы выступаем за равные возможности и высокое качество. Мастера ЕЖЭК прошли тщательную проверку технических и социальных навыков. Мы даем гарантию, что любой мастер ЕЖЭК свободно владеет русским языком и выполнит работу очень качественно.',
  '<b><br />20.	Что будет, если мастер будет специально затягивать время выполнения заказа?</b>',
  'Мы гарантируем, что мастера ЕЖЭК не будут затягивать работу. Наши мастера тщательно отобраны, протестированы и специально проинструктированы на этот случай. Затягивать время выполнения заказа – не в их интересах, так как это неблагоприятно скажется на их репутации и доходах. Если Вам показалось, что так происходит, просим Вас незамедлительно позвонить в службу поддержки и мы примем меры.',
  '<b><br />21.	В чем преимущество сотрудничества с ЕЖЭК для мастеров?</b>',
  'ЕЖЭК предоставляет мастерам заказы рядом с их домом по справедливым ценам и помогает устранять спорные ситуации с заказчиками. Наш сервис помогает мастерам совершенствовать свое мастерство путём проведения консультаций экспертов.',
  'Кроме того, ЕЖЭК неукоснительно соблюдает законодательство и помогает это делать мастерам. В частности, им предоставляется консультационная поддержка, а также практическая помощь в подготовке информации для расчета налога на профессиональный доход и налоговой отчетности индивидуальных предпринимателей.',
  '<b><br />22.	В чем отличие ЕЖЭК от платформ-агрегаторов?</b>',
  'ЕЖЭК — это не агрегатор, а высоко-технологичный сервис. С ЕЖЭК Вам больше не нужно мучительно выбирать мастера и вести с ним сложные переговоры, долго ждать его и переживать за качество работ. Все это мы уже сделали за Вас. ЕЖЭК – это качественные оперативные надомные бытовые услуги, доступные для всех.',
  'Второе отличие – социальная направленность сервиса. Он доступен заказчикам с самыми ограниченными финансовыми возможностями. Это стало возможным потому, что мы инвестируем бОльшую часть дохода в развитие технологий и эффективности оказания услуг.',
  '<br />Не верьте нам на слово. Просто попробуйте.',
  '<br />Подпишитесь на наши каналы в социальных сетях и узнавайте первыми о скидках и других новостях сервиса: <a href="https://t.me/Ezheklive">https://t.me/Ezheklive</a>.',
]

export default function FAQ(props) {

  return (
    <div className={css.wrap}>
      <div className={css.content}>
        <ParagraphBlock highlighted={p1Highlighted} items={p1Items} className={css.p1} />
      </div>
      <Footer />
    </div>
  )
}
