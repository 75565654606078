import React from 'react'
import T from 'prop-types'
import { useDropzone } from 'react-dropzone'

import { uploadFile } from 'fetch/requests/assets'

import Icon from 'app/Elements/Icon'
import Loader from 'app/Elements/Loader'

import cx from 'classnames'
import css from './index.styl'

export const uploadImagesHelper = (source = []) => {
  const files = []
  const links = []

  source.forEach((item) => {
    if (typeof item === 'string') {
      links.push(item)
    } else {
      files.push(item)
    }
  })

  return new Promise((resolve, reject) => {
    if (files.length > 0) {
      try {

        uploadFile(files[0])
          .then((response) => {
            resolve({cdnUrl: response.cdn_url})
          })
          .catch((error) => {
            reject(error)
          })
      } catch (e) {
        reject(e)
      }
    } else if (links.length) {
      resolve(links)
    } else {
      resolve([])
    }
  })
}

function removeFile(props, file) {
  const { handleFiles, files } = props

  window.URL.revokeObjectURL(file.preview)
  handleFiles(files.filter((f) => f !== file))
}

function renderFiles(props) {
  const { files, fileCl, alt, className, isDeleteImageIcon } = props

  return files.map((file) => {
    const preview = file ? (file.preview ? file.preview : file) : ''

    return (
      <div key={preview} className={cx(css.imageWrap, className)}>
        <img src={preview} className={cx(css.image, fileCl)} alt={alt} />
        {isDeleteImageIcon && (
          <Icon size={30} name={'cross'} className={css.crossIcon} onClick={() => removeFile(props, file)} />
        )}
      </div>
    )
  })
}

function handleFiles(files, props) {
  const { handleFiles, files: _files } = props

  handleFiles(_files.concat(files))
}

function AddFile(props) {
  const { label, accept, buttonCl, className } = props
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple: false,
    onDrop: (files) => handleFiles(files, props),
  })

  return (
    <div className={cx(css.wrap, className)}>
      <div className={css.label}>{label}</div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <button type={'button'} className={cx(css.button, buttonCl)}>
          <div className={css.plusInButton}>+</div>
        </button>
      </div>
    </div>
  )
}

export default function Attachment(props) {
  const { files, isLoading } = props

  if (isLoading) {
    return (
      <div className={css.wrap}>
        <Loader isBig />
      </div>
    )
  }

  return files.length > 0 ? renderFiles(props) : <AddFile {...props} />
}

Attachment.propTypes = {
  className: T.string,
  isLoading: T.bool,
  label: T.string,
  fileCl: T.string,
  buttonCl: T.string,
  handleFiles: T.func,
  accept: T.string,
  alt: T.string,
  files: T.array,
  isDeleteImageIcon: T.bool,
}
Attachment.defaultProps = {
  className: '',
  isLoading: false,
  label: 'Прикрепите фотографию или видео',
  fileCl: '',
  buttonCl: '',
  accept: '.jpg,.jpeg,.png',
  alt: 'image',
  handleFiles: () => {},
  files: [],
  isDeleteImageIcon: true,
}
