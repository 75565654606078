import React, { Component } from 'react'
import PropTypes from 'prop-types'

//** assets
import checkmark from './assets/24/checkmark.svg'
import time from './assets/24/time.svg'
import rouble from './assets/24/rouble.svg'
import cross from './assets/24/cross.svg'
import search from './assets/search.svg'
import arrowLeft from './assets/24/arrow-left.svg'
import arrowRight from './assets/24/arrow-right.svg'
import footer from './assets/footer.svg'
import dropup from './assets/24/dropup.svg'
import dropdown from './assets/24/dropdown.svg'
import listActive from './assets/24/listActive.svg'
import personActive from './assets/24/personActive.svg'
import personFilled from './assets/24/personFilled.svg'
import door from './assets/24/door.svg'
import settings from './assets/24/settings.svg'
import cart from './assets/24/cart.svg'
import shower from './assets/24/shower.svg'
import shelve from './assets/24/shelve.svg'
import cargo from './assets/24/cargo.svg'
import hover from './assets/24/hover.svg'
import plant from './assets/24/plant.svg'
import location from './assets/24/location.svg'
import call from './assets/24/call.svg'
import star from './assets/24/star.svg'
import close from './assets/notifications/close.svg'
import error from './assets/notifications/error.svg'
import success from './assets/notifications/success.svg'
import warning from './assets/notifications/warning.svg'
import info from './assets/notifications/info.svg'
import doc from './assets/24/doc.svg'
import download_kodeks from './assets/24/download_kodeks.svg'
import ok from './assets/24/ok.svg'
import smile from './assets/24/smile.svg'
import smileSad from './assets/24/smileSad.svg'
import menu from './assets/24/menu.svg'
import fb_landing from './assets/fb_landing.svg'
import insta_landing from './assets/insta_landing.svg'
import vk_landing from './assets/vk_landing.svg'
import whatsapp_landing from './assets/whatsapp_landing.svg'
import telegram_landing from './assets/telegram_landing.svg'
import youtube_landing from './assets/youtube_landing.svg'
import yandex_zen_landing from './assets/yandex_zen_landing.svg'

//** styles
import cx from 'classnames'
import css from './index.styl'

export default class Icon extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
  }

  static defaultProps = {
    color: 'black',
  }

  render() {
    const { name, className, size, width, height, ...otherProps } = this.props

    if (!name) {
      return null
    }

    const cl = cx({
      [css.icon]: true,
      [className]: className,
    })

    return (
      <svg
        className={cl}
        viewBox={`0 0 ${width || size} ${height || size}`}
        width={width || size}
        height={height || size}
        style={{ minHeight: (height || size) + 'px' }}
        {...otherProps}
      >
        <use xlinkHref={`#${name}`} />
      </svg>
    )
  }
}
