import 'babel-polyfill'
import 'isomorphic-fetch'
import 'app/styles/app.styl'
import 'app/styles/dropdown.styl'

import React from 'react'
import ReactDOM from 'react-dom/client'
// import ReactDOM from 'react-dom'

import {ReduxAsyncConnect} from 'proj_modules/redux-connect'
import {setStore} from 'fetch'

import createStore from 'flux/@createStore'

import {Provider} from 'react-redux'
import {ConnectedRouter} from 'react-router-redux'
import {PersistGate} from 'redux-persist/integration/react'
import createHistory from 'history/createBrowserHistory'

import routes from 'app/routes'

const history = createHistory()
const initialState = JSON.parse(decodeURIComponent(window.__INITIAL_STATE__))

window.__LOAD_ON_SERVER__ = typeof initialState['order'] !== "undefined"
// console.log(JSON.stringify(initialState))

createStore(history, initialState).then(({store, persistor}) => {
  setStore(store)

  const root = ReactDOM.createRoot(document.getElementById("app"));
  root.render(
    <Provider store={store} key="provider">
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <ReduxAsyncConnect
            routes={routes}
            reloadOnHashChange={false}
          />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  )
})
