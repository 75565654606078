import React from 'react'

import Footer from 'app/Elements/Footer'
import ParagraphBlock from 'app/Elements/ParagraphBlock'

import css from './index.styl'

const p1Highlighted = 'ЕЖЭК — это онлайн сервис, через который жители Москвы заказывают бытовые услуги напрямую у индивидуальных профессиональных мастеров'
const p1Items = [
  'Мастера "ЕЖЭК" – сантехники, электрики, сборщики, отделочники, уборщики и цветоводы, поддержку которым оказывает команда опытных экспертов.',
  'Наши мастера – ваши соседи. Они придут на заказ быстро, сработают профессионально, и, если у вас появятся вопросы по проделанной работе, будут рядом, чтобы их решить.',
  'Сегодня услугами мастеров ЕЖЭК могут воспользоваться москвичи, проживающие внутри МКАД, а также за МКАД поблизости от метро. В ближайшее время планируется расширение нашей деятельности и на другие регионы.',
]

const p2Title = 'Наши услуги'
const p2Items = [
  'Установка и ремонт сантехники, труб, радиаторов, нагревательных котлов, отопительных приборов, систем водоочистки',
  'Монтаж и ремонт электропроводки, осветительных приборов, выключателей, розеток, автоматических ворот, уличного и ландшафтного освещения',
  'Сборка, инсталляция, перестановка и ремонт мебели, картин, предметов декора',
  'Косметический ремонт внутренних помещений и фасадов',
  'Уборка и мойка помещений, окон, балконов, закрытых и открытых террас и веранд, бассейнов, фасадов',
  'Уход за цветами в доме, садовые и дворницкие работы на придомовой территории',
]

const p3Title = ''
const p3Items = [
  'Все услуги мастеров ЕЖЭК относятся указанным шести направлениям. Так как в ЕЖЭК уже аккредитовано более 420ти мастеров, каждый из которых обладает уникальными навыками и опытом, общий перечень услуг мастеров ЕЖЭК по названным направлениям уже практически неограничен. Этот перечень будет расти и дальше, так как в процессе аккредитации находится еще несколько тысяч кандидатов в мастера ЕЖЭК.'
]

const p4Title = 'Наша команда'
const p4Items = [
  '<span>ЕЖЭК — это сервис, основанный руководителем одной из крупнейших международных управляющих компаний ЖКХ в Москве, инженером компьютерных наук, мастером искусственного интеллекта, экспертом по налогообложению и праву и специалистом по маркетингу инноваций. <b> Сейчас в команде наших экспертов — 16 специалистов, и она постоянно растет.</b></span>'
]

export default function AboutPage(props) {

  return (
    <div className={css.wrap}>
      <div className={css.imageWrap}></div>
      <div className={css.content}>
        <ParagraphBlock highlighted={p1Highlighted} items={p1Items} className={css.p1} />
        <ParagraphBlock title={p2Title} items={p2Items} itemsByList />
        <ParagraphBlock title={p3Title} items={p3Items} />
        <ParagraphBlock title={p4Title} items={p4Items} />
      </div>
      <Footer />
    </div>
  )
}
