import React from 'react'
import T from "prop-types";
import cx from 'classnames'

import Icon from 'app/Elements/Icon'

import css from './index.styl'


const starsArr = [
  {id: 1, active: false},
  {id: 2, active: false},
  {id: 3, active: false},
  {id: 4, active: false},
  {id: 5, active: false},
]

export default function Rating(props) {
  const {
    tag: Tag,
    className,
    rate,
    size,
    ...restProps
  } = props

  return (<Tag className={cx(className, css.rateBar)} {...restProps}>
    {starsArr.map(({id}, idx) => (
      <Icon
        key={id}
        name={'star'}
        size={size}
        className={cx(css.starIcon, (idx < rate) && css.starIconActive)}
      />
    ))}
  </Tag>)
}

Rating.propTypes = {
  className: T.string,
  tag: T.string,
  size: T.number,
  rate: T.number,
}
Rating.defaultProps = {
  className: '',
  tag: 'div',
  size: 15,
  rate: 5,
}
