import React from 'react'
import { Redirect } from 'react-router-dom'

import Wrap from 'app/Layout/Wrap'
import OrderWrap from 'app/Layout/OrderWrap'
import OrderInfoWrap from 'app/Layout/OrderInfoWrap'

import Start from 'app/Pages/Start/index.connector'
import NewOrder from 'app/Pages/OrderNew/index.connector'
import OrderIn from 'app/Pages/OrderIn'
// import PaymentRules from 'app/Pages/PaymentRules'
import AboutPage from 'app/Pages/About'
import CustomersPage from 'app/Pages/Customers'
import MastersPage from 'app/Pages/Masters'
import MastersRequestPage from 'app/Pages/MastersRequest'
import FAQPage from 'app/Pages/FAQ'
import ContactsPage from 'app/Pages/Contacts'
import CodexPage from 'app/Pages/Codex'
import CodexTariffsPage from 'app/Pages/Codex/Tariffs'
import CodexTariffsPoliciesPage from 'app/Pages/Codex/TariffsPolicies'
import CodexServicesPage from 'app/Pages/Codex/Services'
import CodexStandardsPage from 'app/Pages/Codex/Standards'
import CodexAgreementTermsPage from 'app/Pages/Codex/AgreementTerms'
import CodexPrivacyPolicyPage from 'app/Pages/Codex/PrivacyPolicy'
import AccountTerminationPage from 'app/Pages/AccountTermination'
import OrderTerminatePage from 'app/Pages/OrderTerminate'

import NotFoundPage from 'app/PagesXXX/404/index.connector'

import Order from 'app/Pages/Order'

import isAuthorized from './isAuthorized'

export default [
  {
    component: Wrap,
    routes: [
      {
        component: OrderWrap,
        routes: [
          {
            path: '/',
            exact: true,
            shouldRenderTopMenu: true,
            shouldRenderImage: false,
            component: Start,
          },
          {
            path: '/about/',
            exact: true,
            shouldRenderTopMenu: true,
            shouldRenderImage: false,
            component: AboutPage,
          },
          {
            path: '/customers/',
            exact: true,
            shouldRenderTopMenu: true,
            shouldRenderImage: false,
            component: CustomersPage,
          },
          {
            path: '/masters/',
            exact: true,
            shouldRenderTopMenu: true,
            shouldRenderImage: false,
            component: MastersPage,
          },
          {
            path: '/masters/request/',
            shouldRenderTopMenu: true,
            shouldRenderImage: false,
            exact: true,
            component: MastersRequestPage,
          },
          {
            path: '/faq/',
            exact: true,
            shouldRenderTopMenu: true,
            shouldRenderImage: false,
            component: FAQPage,
          },
          {
            path: '/contacts/',
            exact: true,
            shouldRenderTopMenu: true,
            shouldRenderImage: false,
            component: ContactsPage,
          },
          {
            path: '/codex/',
            exact: true,
            shouldRenderTopMenu: true,
            shouldRenderImage: false,
            component: CodexPage,
          },
          {
            path: '/codex/services',
            exact: true,
            shouldRenderTopMenu: true,
            shouldRenderImage: false,
            component: CodexServicesPage,
          },
          {
            path: '/codex/tariffspolicies',
            exact: true,
            shouldRenderTopMenu: true,
            shouldRenderImage: false,
            component: CodexTariffsPoliciesPage,
          },
          {
            path: '/codex/tariffs',
            exact: true,
            shouldRenderTopMenu: true,
            shouldRenderImage: false,
            component: CodexTariffsPage,
          },
          {
            path: '/codex/standards',
            exact: true,
            shouldRenderTopMenu: true,
            shouldRenderImage: false,
            component: CodexStandardsPage,
          },
          {
            path: '/codex/AgreementTerms',
            exact: true,
            shouldRenderTopMenu: true,
            shouldRenderImage: false,
            component: CodexAgreementTermsPage,
          },
          {
            path: '/codex/PrivacyPolicy',
            exact: true,
            shouldRenderTopMenu: true,
            shouldRenderImage: false,
            component: CodexPrivacyPolicyPage,
          },
          {
            path: '/order/in/:fastauthtoken',
            exact: true,
            shouldRenderTopMenu: false,
            shouldRenderImage: false,
            component: OrderIn,
          },
          {
            component: OrderInfoWrap,
            path: '/order/custom/:slug',
            routes: [
              {
                path: '/order/custom/:slug',
                component: NewOrder,
                // component: process.env.NODE_ENV === 'production' ? () => <Redirect to='/' /> : NewOrder,
              },
            ],
            shouldRenderTopMenu: true,
            shouldGoBack: true,
            wrapMobile: true,
          },
          {
            path: '/order/in/',
            exact: true,
            component: NotFoundPage,
          },
          {
            component: OrderInfoWrap,
            path: '/order/:id',
            routes: [
              {
                path: '/order/:id',
                component: isAuthorized(Order),
              },
            ],
          },
          {
            component: OrderInfoWrap,
            path: '/order',
            routes: [
              {
                path: '/order',
                component: NewOrder,
                // component: process.env.NODE_ENV === 'production' ? () => <Redirect to='/' /> : NewOrder,
              },
            ],
            shouldRenderTopMenu: true,
            shouldGoBack: true,
            wrapMobile: true,
          },
          // {
          //   path: '/paymentrules/',
          //   exact: true,
          //   component: PaymentRules,
          // },
          {
            path: '/account_terminate',
            exact: true,
            shouldRenderTopMenu: true,
            shouldRenderImage: false,
            component: AccountTerminationPage,
          },
          {
            path: '/order_terminate',
            exact: true,
            shouldRenderTopMenu: true,
            shouldRenderImage: false,
            component: OrderTerminatePage,
          },
          {
            path: '*',
            component: NotFoundPage,
          },
        ],
      },
    ],
  },
]
