import React, {useEffect, useState} from 'react'
import T from 'prop-types'

import Input from 'app/Elements/Input'

import css from './index.styl'

import DatePicker, {registerLocale} from 'react-datepicker'
import ru from 'date-fns/locale/ru'

registerLocale('ru', ru)

import moment, {getMaxTimeForPreOrder, getMinTimeForPreOrder} from 'helpers/moment'
import cap from 'helpers/strings/capitalizeFirstLetter'

export default function DateSelector({state}) {
    const changeDate = (date, name) => {
        state[`handle${cap(name)}`](moment(date).format(name === 'date' ? 'YYYY-MM-DD' : 'HH:mm'))
    }

    const curDate = moment(`${state['date']}T00:00:00.000`)
    const minDate = moment(`${moment().format('YYYY-MM-DD')}T00:00:00.000`)

    let curTime = moment(`${state['date']}T${state['time']}:00.000`)

    const minTime = getMinTimeForPreOrder(curDate)
    const maxTime = getMaxTimeForPreOrder(curDate)

    if (curTime < minTime) {
        curTime = minTime

        // обновление времени в стейте, когда открыто модальное окно
        changeDate(curTime, 'time')
    }

    if (curTime > maxTime) {
        curDate.add(1, 'd')
        minDate.add(1, 'd')

        // обновление даты в стейте, когда открыто модальное окно
        changeDate(curDate, 'date')
    }

    return (
        <div className={css.dateSelectorInputs}>
            <div className={css.divInputData}>
                <DatePicker
                    customInput={
                        <Input label={'Дата'} className={css.dateInput} handleInput={date => changeDate(date, 'date')}/>
                    }
                    onChange={(e) => changeDate(e, 'date')}
                    selected={curDate.toDate()}
                    minDate={minDate.toDate()}
                    locale='ru'
                    dateFormat='dd.MM.yyyy'
                />
            </div>
            <div className={css.divInputTime}>
                <DatePicker
                    customInput={
                        <Input label={'Время'} className={css.timeInput} handleInput={date => changeDate(date, 'time')}/>
                    }
                    onChange={(e) => changeDate(e, 'time')}
                    selected={curTime.toDate()}
                    minTime={minTime.toDate()}
                    maxTime={maxTime.toDate()}
                    locale='ru'
                    showTimeSelect
                    showTimeSelectOnly
                    timeFormat='HH:mm'
                    timeIntervals={30}
                    dateFormat='HH:mm'
                    timeCaption='Время'
                />
            </div>
        </div>
    )
}

DateSelector.propTypes = {
    state: T.shape({
        date: T.string,
        time: T.string,
        handleDate: T.func,
        handleTime: T.func
    }),
}
DateSelector.defaultProps = {
    state: {
        date: '',
        time: '',
        handleDate: () => {
        },
        handleTime: () => {
        }
    }
}
