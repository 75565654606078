import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import {authRequest} from 'flux/actions/fetch/authRequest'
import {authConfirm} from 'flux/actions/fetch/authConfirm'

import {selectAuthConfirmationCode} from 'flux/selectors/fetch/authRequest'

import {selectAuthConfirmError} from 'flux/selectors/fetch/authConfirm'

//** component
import Component from './index'

const mapStateToProps = state => ({
  confirmationCode: selectAuthConfirmationCode(state),
  authError: selectAuthConfirmError(state),
})

const mapDispatchToProps = dispatch => ({
  requestAuth: data => dispatch(authRequest(data)),
  confirmAuth: params => dispatch(authConfirm(params)),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Component),
)
