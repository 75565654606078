import {createSelector} from 'reselect'

export const selectStore = ({fetch: {authRequest = {}} = {}} = {}) => authRequest

export const selectAuthRequestData = createSelector(
  selectStore,
  ({data}) => data, // may be null
)

export const selectAuthConfirmationCode = createSelector(
  selectAuthRequestData,
  (data) => {
    const {confirmation_code} = data || {}
    return confirmation_code
  }
)

export const selectAuthRequestError = createSelector(
  selectStore,
  ({error}) => error, // may be null
)

export const selectAuthRequestIsError = createSelector(
  selectAuthRequestError,
  (error) => !!error,
)

export const selectAuthRequestIsFetching = createSelector(
  selectStore,
  ({fetching}) => fetching,
)
