import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {stringify} from 'query-string'

//** lodash
import range from 'lodash/range'

//** elements
import Link from 'app/Elements/SmartLink'
import Icon from 'app/Elements/Icon'

//** styles
import cx from 'classnames'
import css from './index.styl'

const linkHookDefault = () => {}

const buildLinkDefault = ({pathname, query}, page) =>
  `${pathname}?${stringify({...query, page})}`

const totalPages = (total, perPage) => Math.ceil(total / perPage)

const buildPagesArray = (page, lastPage, {head = 3, tail = 3} = {}) => {
  const minItems = Math.max(5, head + tail + 2)
  const headRange = (h = head) => range(1, h + 1)
  const middleRange = (p = page) => range(p - 2, p + 3).filter(i => i > 0)
  const tailRange = (last = lastPage, t = tail) => range(last + 1 - t, last + 1)
  const minRange = p => range(1, lastPage + 1)

  // if just {n + m} pages
  // then just plain range
  // special case
  if (lastPage <= minItems) {
    return minRange()
  }

  if (page <= head + 3) {
    return [
      ...headRange(page + 2),
      null,
      ...tailRange(lastPage, minItems - page),
    ]
  }

  if (page >= lastPage - minItems + 2) {
    return [
      ...headRange(minItems - 1 - (lastPage - page)),
      null,
      ...tailRange(lastPage, lastPage - page + 3),
    ]
  }

  return [...headRange(), null, ...middleRange(), null, ...tailRange()]
}

export default class Pagination extends PureComponent {
  static propPropTypesypes = {
    className: PropTypes.string,
    location: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    head: PropTypes.number,
    tail: PropTypes.number,
    buildLink: PropTypes.func,
    linkHook: PropTypes.func,
  }

  static defaultProps = {
    page: 1,
    perPage: 25,
    total: 25,
    head: 3,
    tail: 3,
    buildLink: buildLinkDefault,
    linkHook: linkHookDefault,
  }

  renderPrevPageLink = () => {
    const {page, location, buildLink, linkHook} = this.props

    return (
      <Link
        className={cx(css.page, css.pageLeft)}
        to={buildLink(location, page - 1)}
        onClick={event => linkHook(event, page - 1)}
        disabled={page === 1}
      >
        <Icon name={'arrow-left'} size={24} />
      </Link>
    )
  }

  renderNextPageLink = () => {
    const {page, perPage, total, location, buildLink, linkHook} = this.props

    const lastPage = totalPages(total, perPage)

    return (
      <Link
        className={cx(css.page, css.pageRight)}
        to={buildLink(location, page + 1)}
        onClick={event => linkHook(event, page + 1)}
        disabled={page === lastPage}
      >
        <Icon name={'arrow-right'} size={24} />
      </Link>
    )
  }

  renderPagination = () => {
    const {page, perPage, total, head, tail} = this.props

    const lastPage = totalPages(total, perPage)

    const pages = buildPagesArray(page, lastPage, {head, tail})

    return <ul className={css.pagination}>{pages.map(this.renderItem)}</ul>
  }

  renderItem = (page, idx) => {
    const {page: propsPage, location, buildLink, linkHook} = this.props
    const isCurrent = page === propsPage

    const cl = cx({
      [css.page]: page,
      [css.pagePlaceholder]: !page,
      [css.currentPage]: isCurrent,
    })

    const Element = page && !isCurrent ? Link : 'span'
    const elementProps = page && !isCurrent
      ? {
        to: buildLink(location, page),
        onClick: event => linkHook(event, page),
      }
      : {}

    return (
      <li key={idx}>
        <Element
          {...elementProps}
          className={cl}
        >
          {page || '∙∙∙'}
        </Element>
      </li>
    )
  }

  render() {
    const {total, perPage, className} = this.props

    if (total <= perPage) {
      return null
    }

    const clNav = cx({
      [css.nav]: true,
      [className]: className,
    })

    return (
      <nav className={clNav}>
        <div className={css.wrap}>
          {this.renderPrevPageLink()}
          {this.renderPagination()}
          {this.renderNextPageLink()}
        </div>
      </nav>
    )
  }
}
