import React, { Fragment } from 'react'
import T from 'prop-types'

import css from './index.styl'

const DropDownListStreet = ({
  listStreet,
  handleStreet,
  isOpen,
  handleOpen,
  // handleInputFocus
}) => {
  return (
    isOpen && (
      <Fragment>
        <div
          className={css.substrate}
          onClick={() => {
            handleOpen(false)
          }}
        />
        <div className={css.list}>
          {listStreet.map((item, index) => (
            <div
              key={index}
              className={css.item}
              onClick={() => {
                handleStreet(item)
                handleOpen(false)
                // handleInputFocus('')
              }}
            >
              <span>{item.split(',')[0]}</span>
              <span className={css.city}>{item.split(',')[1]}</span>
            </div>
          ))}
        </div>
      </Fragment>
    )
  )
}

DropDownListStreet.propTypes = {
  listStreet: T.arrayOf(T.string),
  handleStreet: T.func,
  isOpen: T.bool,
  handleOpen: T.func,
}
DropDownListStreet.defaultProps = {
  listStreet: [],
  handleStreet: () => {},
  isOpen: false,
  handleOpen: () => {},
}

export default DropDownListStreet
