import React from 'react'

import Button from 'app/Elements/Button'
import Icon from 'app/Elements/Icon'
import SmartLink from 'app/Elements/SmartLink'

import { ModalWrapper } from 'app/Layout/Modal'

import css from './index.styl'

export default function ModalToDelayed() {
  return (
    <ModalWrapper
      shouldUseOffset
      name={'masterNotFoundModal'}
      title={'Ваш заказ отложен'}
      titleCl={css.title}
      modalCl={css.modal}
    >
      <div className={css.text}>
        <p>Для активации заказа свяжитесь с нашим оператором</p>
      </div>

      <div>
        <Button
          theme={'green'}
          className={css.button}
          href="tel:+74951985495"
          asLink
        >
          <Icon name={'call'} size={28} className={css.callIcon} />
          Звонок администратору
        </Button>

        <div className={css.wrap}>
          <SmartLink to={'/'} theme={'green'} isUnderlined className={css.linkHome}>
            На главную
          </SmartLink>
        </div>
      </div>
    </ModalWrapper>
  )
}
