import React from 'react'
import T from 'prop-types'

import cx from 'classnames'
import css from './index.styl'

export default function ParagraphBlock(props) {
  const {className, title, subtitle, highlighted, items = [], itemsByList, itemsNoInner} = props

  return (
    <div className={cx(css.paragraph, className)}>
      {highlighted && (
        <div className={css.highlighted}>{highlighted}</div>
      )}
      {title && (
        <div className={css.title}>{title}</div>
      )}
      {subtitle && (
        <div className={css.subtitle}>{subtitle}</div>
      )}
      {items.map((item, index) => {
        return (
          <div className={css.listItem} key={`item_list_${index}`}>
            {itemsByList && (
              <div className={css.listNumber}>{index + 1}</div>
            )}
            {itemsNoInner
              ? <div className={css.itemText}>{item}</div>
              : <div className={css.itemText} dangerouslySetInnerHTML={{__html: item}}/>}
          </div>
        )
      })}
    </div>
  )
}

ParagraphBlock.propTypes = {
  className: T.string,
  title: T.string,
  highlighted: T.string,
  items: T.array,
  itemsByList: T.bool,
}
ParagraphBlock.defaultProps = {
  className: '',
}
