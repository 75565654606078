import React, {Component, Fragment} from 'react'
import T from 'prop-types'

import cx from 'classnames'
import css from './index.styl'
import joinValues from "../../../helpers/strings/joinValues"

export function getSearchTest(props) {
  const {street = '', house = '', housing = '', building = '', city = ''} = props
  const full_house = house + (housing ? ' корпус ' + housing : '') + (building ? ' строение ' + building : '')
  return joinValues([city, street, full_house])
}

let geocodingTimeoutId = null

export default class GoogleMap extends Component {
  initialMap = false
  placedMasterMarkers = new Map()
  clickInMap = false

  componentDidMount() {
    const attempt = () => {
      if (!window.google) {
        setTimeout(attempt, 10)
        return
      }
      this.initMap()
    }
    attempt()
  }

  componentDidUpdate() {
    if (!this.initialMap) return
    const {
      searchText,
      changeStreetList,
      masterMarkers,
      handleAddressError,
      passAddress,
      isInputActive,
      onMapUpdated,
    } = this.props

    // console.log("isInputActive", isInputActive)

    if (masterMarkers && this.oldMasterMarkers !== masterMarkers) {
      this.setMasterMarker(masterMarkers)
      this.oldMasterMarkers = masterMarkers
      onMapUpdated(this)
    }

    if (this.oldSearchText !== searchText) {

      this.oldSearchText = searchText

      searchText &&
      this.autocompleteService.getPlacePredictions(
        {
          input: searchText,
          location: this.moscowLatLng,
          radius: 0,
        },
        (result) => {
          result &&
          changeStreetList(
            result.map(
              (item) => item.description && `${item.description.split(',')[0]}`
              // `${item.description.split(',')[0]}, ${
              //   item.description.split(',')[item.description.split(',').length - 2]
              // }`
            )
          )
        }
      )

      if (this.clickInMap) {
        this.clickInMap = false
        return
      }

      if (isInputActive) {
        return
      }

      if (geocodingTimeoutId) {
        clearTimeout(geocodingTimeoutId)
      }
      geocodingTimeoutId = setTimeout(() => {
        this.geoCoding({address: `${searchText}`}, (dataAddress) => {
          const {
            geometry: {location},
            address_components,
            formatted_address,
          } = dataAddress

          const addressError =
            (searchText.split(',')[1] &&
              address_components.filter(({types}) => types[0] === ('street_number' || 'street_address')).length ===
              0) ||
            false

          const lng = location.lng()
          const lat = location.lat()

          let data = this.parseAddressFromCoords(formatted_address, address_components, lng, lat)
          // console.log('componentDidUpdate', data)

          passAddress(data)
          handleAddressError(addressError)

          this.setMarkerInMap(location)
          this.map.setCenter(location)
        })

      }, 750)

    }
  }

  parseAddressFromCoords(formatted_address, address_components, lng, lat) {
    let data = {
      city: '',
      street: '',
      house: '',
      address: formatted_address,
      lon: lng,
      lat: lat,
      building: '',
      housing: '',
      entrance: '',
      entranceCode: '',
      floor: '',
      apartments: '',
    }

    address_components.map((item) => {
      switch (item.types[0]) {
        case 'street_number':
          let house, housing, building

          console.log("::", item.long_name)

          if (item.long_name.includes("корпус")) {
            [house, housing] = item.long_name.split(' корпус ')
          } else if (item.long_name.includes(", к.")) {
            [house, housing] = item.long_name.split(', к.')
            if (!housing) house = item.long_name
          } else if (item.long_name.includes("к")) {
            [house, housing] = item.long_name.split('к')
            if (!housing) house = item.long_name
          } else if (item.long_name.includes("строение")) {
            [house, building] = item.long_name.split(' строение ')
          } else {
            house = item.long_name
          }

          if (house.includes("дом ")) {
            house = house.replace("дом ", "")
          }

          data.house = house
          data.housing = housing
          data.building = building

          break
        case 'premise':
          data.house = item.long_name
          break
        case 'route':
          data.street = item.long_name
          break
        case 'locality':
          data.city = item.long_name
          break
        default:
          break
      }
    })

    return data
  }

  setMasterMarker = (masters) => {
    const newIds = new Set(masters.map((m) => m.id))

    const idsToRemove = [...this.placedMasterMarkers.keys()].filter((id) => !newIds.has(id))

    idsToRemove.forEach((id) => {
      this.placedMasterMarkers.get(id).setMap(null)
      this.placedMasterMarkers.delete(id)
    })

    masters.forEach(({id, lat, lon}) => {
      const markerPoint = new google.maps.LatLng({lat: +lat, lng: +lon})
      const m = this.placedMasterMarkers.get(id)
      if (m) {
        m.setPosition(markerPoint)
      } else {
        const m = new google.maps.Marker({
          icon: this.iconMasters,
          position: markerPoint,
          map: this.map,
        })
        this.placedMasterMarkers.set(id, m)
      }
    })
  }

  initMap = async () => {
    const {startCoordinat, zoom, markerDisabled, forContacts, fullscreenControl} = this.props
    const _moscowCoords = startCoordinat ? {lat: startCoordinat.lat, lng: startCoordinat.lng} : {
      lat: 55.7558328,
      lng: 37.6155767
    }
    this.moscowLatLng = new google.maps.LatLng(_moscowCoords)
    const _fullscreenControl = fullscreenControl !== undefined ? fullscreenControl : !forContacts

    this.map = new window.google.maps.Map(document.getElementById('map'), {
      center: this.moscowLatLng,
      zoom: zoom || 11,
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: _fullscreenControl,
      gestureHandling: 'cooperative',
    })

    if (!markerDisabled || forContacts) {
      this.marker = new google.maps.Marker({
        position: forContacts ? startCoordinat : this.moscowLatLng,
        map: this.map,
      })
    }

    if (!forContacts) {
      const centerControlDiv = document.createElement('div')
      const centerControl = this.centerControl(centerControlDiv, map)

      centerControlDiv.index = 1
      this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(centerControlDiv)
    }

    this.autocompleteService = new google.maps.places.AutocompleteService()

    this.sessionToken = new google.maps.places.AutocompleteSessionToken()

    this.oldSearchText = ''

    this.oldMasterMarkers = {}
    this.mastersList = {}
    this.iconMasters = {
      url: '/assets/marker.svg',
      size: new google.maps.Size(39, 39),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(20, 20),
    }

    if (startCoordinat) {
      const pointOrder = new google.maps.LatLng(startCoordinat)

      this.map.setCenter(startCoordinat)
      if (!markerDisabled) this.setMarkerInMap(startCoordinat)
    } else {
      this.initPlaceMark()
    }
    if (!forContacts) await this.initUserLocation()
    this.initialMap = true
  }

  initUserLocation = async () => {
    this.infoWindow = new google.maps.InfoWindow()
    if (!localStorage.getItem('ezhek-user-state')) {
      this.setUserGeoLocation()
    }
  }

  setUserGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async ({coords: {latitude, longitude}}) => {
          this.pos = {
            lat: latitude,
            lng: longitude,
          }
          this.geoCoding({location: this.pos}, (res) => {
            this.addAddressInInput(res)
            this.setMarkerInMap(this.pos)
          })
          this.map.setCenter(this.pos)
        },
        () => {
          this.handleLocationError(true, this.infoWindow, this.map.getCenter())
        },
        {
          enableHighAccuracy: true
        }
      )
    } else {
      this.handleLocationError(false, this.infoWindow, this.map.getCenter())
    }
  }

  handleLocationError = (browserHasGeolocation, infoWindow, pos) => {
    const {infoWindowDisabled} = this.props
    infoWindow.setPosition(pos)
    infoWindow.setContent(
      browserHasGeolocation ? 'Ошибка: сбой службы геолокации.' : 'Ошибка: ваш браузер не поддерживает геолокацию.'
    )
    if (!infoWindowDisabled) infoWindow.open(this.map)
  }

  initPlaceMark = () => {
    this.map.addListener('click', this.onMapClick)
  }

  onMapClick = ({latLng}) => {
    this.clickInMap = true
    // console.log('clickInMap ', this.clickInMap)
    this.geoCoding({location: latLng}, (res) => {
      this.addAddressInInput(res)
      this.setMarkerInMap(latLng)
    })
    this.map.setCenter(latLng)

    // const bounds = {
    //   west: 36.592956,
    //   east: 38.949088,
    //   south: 55.095389,
    //   north: 56.337461,
    // }
    // const rectangle = new google.maps.Rectangle({
    //   map: this.map,
    //   bounds: bounds,
    // })
  }

  geoCoding = (data, f) => {
    const geocoder = new google.maps.Geocoder()
    const {location, address} = data

    const bounds = {
      west: 36.592956,
      east: 38.949088,
      south: 55.095389,
      north: 56.337461,
    }

    geocoder.geocode(location ? {location: location} : {address: address, bounds}, (results, status) => {
      return status === 'OK' && f(results[0])
    })
  }

  setMarkerInMap = (position) => {
    this.marker && this.marker.setPosition(position)
  }

  addAddressInInput = ({geometry: {location}, formatted_address, address_components}) => {
    const lng = location.lng()
    const lat = location.lat()
    const {passAddress} = this.props

    // console.log('addAddressInInput')
    const data = this.parseAddressFromCoords(formatted_address, address_components, lng, lat)

    passAddress(data)
  }

  centerControl = (controlDiv, map) => {
    var controlUI = document.createElement('div')
    controlUI.style.backgroundColor = '#fff'
    controlUI.style.backgroundImage = 'url(/assets/locate.svg)'
    controlUI.style.border = '2px solid #fff'
    controlUI.style.cursor = 'pointer'
    controlUI.style.marginRight = '10px'
    controlUI.style.textAlign = 'center'
    controlUI.style.width = '40px'
    controlUI.title = 'Click to recenter the map'
    controlDiv.appendChild(controlUI)

    // Set CSS for the control interior.
    var controlText = document.createElement('div')
    controlText.style.color = 'rgb(25,25,25)'
    controlText.style.fontFamily = 'Roboto,Arial,sans-serif'
    controlText.style.fontSize = '16px'
    controlText.style.lineHeight = '38px'
    controlText.style.paddingLeft = '5px'
    controlText.style.paddingRight = '5px'
    controlText.innerHTML = '+'
    controlUI.appendChild(controlText)

    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener('click', () => {
      this.setUserGeoLocation()
    })
  }

  render() {
    const {className} = this.props

    return <div className={cx(css.map, className)} id={'map'}/>
  }
}

GoogleMap.propTypes = {
  className: T.string,
  searchText: T.string,
  masterMarkers: T.arrayOf(
    T.shape({
      first_name: T.string,
      id: T.number,
      last_name: T.string,
      lat: T.string,
      lon: T.string,
      on_work: T.bool,
      phone: T.string,
    })
  ),
  passAddress: T.func,
  changeStreetList: T.func,
  handleAddressError: T.func,
  isInputActive: T.bool,
  onMapUpdated: T.func,
}
GoogleMap.defaultProps = {
  className: '',
  searchText: '',
  masterMarkers: [],
  passAddress: () => {
  },
  changeStreetList: () => {
  },
  handleAddressError: () => {
  },
  onMapUpdated: () => {},
  isInputActive: false,
}
