import {getClientMetadata} from '../actions/order'

export default {
  promise: (props = {}) => {
    const {
      store: { dispatch, getState } = {},
      match: { params: {id} = {}} = {},
      location: { search } = {},
    } = props

    return Promise.all([
      dispatch(getClientMetadata()),
    ])
  },
}