import _reduce from 'lodash/reduce'

import { uploadImagesHelper } from '../app/Components/Attachment'


export const toggleOpenState = (isOpen, handleIsOpen) => () => handleIsOpen(!isOpen)

export const renderDescription = text => {
  return text
    .split('')
    .map((item) => (item.charCodeAt() === 10 ? '\n' : item))
    .join('')
}

export const uploadFiles = (state, props) => {
  return async function handleFiles(files) {
    const { changeImageUrls } = props
    const { handleIsImageLoading } = state

    if (!files.length) {
      changeImageUrls([])
    } else {
      handleIsImageLoading(true)
      const { cdnUrl } = await uploadImagesHelper(files)
      changeImageUrls([cdnUrl])
      handleIsImageLoading(false)
    }
  }
}

const hoursToMins = (hours = 0) => Math.round(hours * 60)

const secsToMins = (minutes = 0) => Math.round(minutes / 60)

export const ORDER_TYPE_NORMAL = "normal"
export const ORDER_TYPE_HOT = "hot"

const getDiscounts = (vars = {}) => {
  const {BILLING_LOYALTIES = []} = vars

  const _results = BILLING_LOYALTIES.map((val, idx) => [val.period, val.rate])

  return _results.sort((a, b) => b[0] - a[0])
}

export const calcCost = (minutes = 0, hours = 0, employeeCount= 0, orderType = ORDER_TYPE_NORMAL, vars = {}) => {

  const {
    BILLING_INITIAL_AMOUNT_HOT = 0,
    BILLING_INITIAL_AMOUNT_NORMAL = 0,
    BILLING_MIN_SUM_HOT = 0,
    BILLING_MIN_SUM_NORMAL = 0,
    BILLING_WORK_FREE_TIME = 0,
    BILLING_WORK_MINUTE_PRICE = 0,
  } = vars

  let initialAmount = 0

  let minAmount = 0

  let discountAmount = 0

  let workAmount = 0

  let subTotalAmount = 0

  let totalAmount = 0

  const totalMinutes = hoursToMins(hours) + minutes

  const freeMinutes = secsToMins(BILLING_WORK_FREE_TIME)

  const workMinutes = totalMinutes > freeMinutes ? totalMinutes - freeMinutes : 0

  if (orderType === ORDER_TYPE_NORMAL) {
    // initialAmount = BILLING_INITIAL_AMOUNT_NORMAL
    minAmount = BILLING_MIN_SUM_NORMAL
  } else if (orderType === ORDER_TYPE_HOT) {
    // initialAmount = BILLING_INITIAL_AMOUNT_HOT
    minAmount = BILLING_MIN_SUM_HOT
  }

  workAmount = workMinutes * BILLING_WORK_MINUTE_PRICE

  subTotalAmount = workAmount + minAmount + initialAmount

  const loyalties = getDiscounts(vars)

  for (let i = 0; i < loyalties.length; i++) {
    const [loyalMinutes, discount] = loyalties[i]
    if (totalMinutes >= loyalMinutes) {
      discountAmount = subTotalAmount * discount;
      break;
    }
  }

  totalAmount = Math.round((subTotalAmount - discountAmount) * employeeCount)

  return {
    totalMinutes,
    employeeCount,
    initialAmount,
    minAmount,
    freeMinutes,
    workAmount,
    subTotalAmount,
    discountAmount,
    pricePerMinute: BILLING_WORK_MINUTE_PRICE,
    totalAmount,
  }
}
