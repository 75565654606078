import { selectOrderField } from 'flux/selectors/order'
import { changeOrderField, types, initialData } from 'flux/actions/order'
import cap from 'helpers/strings/capitalizeFirstLetter'

const dataFields = Object.keys(initialData)

export function getInitialDataFields() {
  const initialFields = {}
  dataFields.forEach((fieldName) => {
    initialFields[fieldName] = initialData[fieldName].initialValue
  })

  return initialFields
}

export function generateReducers() {
  const reducer = {}
  dataFields.forEach((fieldName) => {
    const typeName = `CHANGE_${fieldName.toUpperCase()}`
    reducer[types[typeName]] = function(state, payload) {
      return {
        ...state,
        data: {
          ...state.data,
          [fieldName]: payload[fieldName],
        },
      }
    }
  })

  return reducer
}

export function generateStateToProps(state) {
  const stateToProps = {}
  dataFields.forEach((fieldName) => {
    const { interfaceName } = initialData[fieldName]
    stateToProps[interfaceName] = selectOrderField(fieldName, state)
  })

  return stateToProps
}

export function generateDispatchToProps(dispatch) {
  const dispatchToProps = {}
  dataFields.forEach((fieldName) => {
    const { interfaceName } = initialData[fieldName]
    dispatchToProps[`change${cap(interfaceName)}`] = (values) => dispatch(changeOrderField(fieldName, values))
  })

  return dispatchToProps
}
