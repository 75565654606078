import React from 'react'
import T from 'prop-types'

import GoogleMap from 'app/Elements/Map'
import YandexMap from 'app/Elements/YaMap'

// import cx from 'classnames'
// import css from './index.styl'


export const PROVIDER_GOOGLE = 'google'
export const PROVIDER_YANDEX = 'yandex'

export default function UnifiedMap(props) {

  const {provider, ...restProps} = props

  return (<React.Fragment>
    {provider === PROVIDER_GOOGLE && <GoogleMap {...restProps} />}
    {provider === PROVIDER_YANDEX && <YandexMap {...restProps} />}
  </React.Fragment>)
}

UnifiedMap.propTypes = {
  provider: T.string.isRequired,
}
UnifiedMap.defaultProps = {
  provider: PROVIDER_YANDEX
}
