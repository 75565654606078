import {asyncConnectWithoutProps, ReduxAsyncConnect} from 'proj_modules/redux-connect'
import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'

//** lodash
import find from 'lodash/find'

//** flux
// import {selectConnectError} from 'flux/selectors/loaders/index'

import Page404 from 'app/PagesXXX/404'

function getComponentName(Component) {
  return Component.displayName || Component.name
}

const getErrors = (state, ownProps, loadersKeys) => {
  const loadersErrors = []

  // loadersKeys.forEach((key) => {
  //   const error = selectConnectError(state, key)
  //   if (error) {
  //     loadersErrors.push({key, error})
  //   }
  // })

  return loadersErrors
}

class ErrorComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {showErrors: true}
  }

  toggleClose = event => {
    const {showErrors} = this.state

    this.setState({showErrors: !showErrors})
  }

  render() {
    const {errors} = this.props
    const {showErrors} = this.state

    const style = {
      backgroundColor: 'white',
      position: 'absolute',
      top: 0,
      right: 0,
      left: showErrors ? 0 : undefined,
      paddingBottom: 50,
      zIndex: 999,
    }

    const close = {
      backgroundColor: 'gray',
      position: 'absolute',
      top: '10px',
      right: '10px',
      padding: 6,
    }

    return (
      <div style={style}>
        <button style={close} onClick={this.toggleClose}>
          {showErrors ? '[HIDE]' : '[SHOW]'}
        </button>

        {
          errors.map(({key, error}) => {
            const {
              url,
              status,
              message,
              extra,
              parsed = false,
            } = error || {}

            return (
              <div ref="error" key={key}>
                {showErrors && (
                  <div style={{padding: 6}}>
                    <span style={{color: 'red', fontWeight: 'bold'}}>in loader "{key}" occurred request error: </span>
                    <br />
                    <pre style={{whiteSpace: 'pre-wrap'}}>
                      url: {url || 'n/a'}{'\n'}
                      status: {status || 'n/a'}{'\n'}
                      message: {message || 'n/a'}{'\n'}
                      {extra && `extra: ${parsed ? JSON.stringify(extra) : extra}`}
                    </pre>
                  </div>
                )}
              </div>
            )
          })
        }
      </div>
    )
  }
}

class PageConnector extends Component {
  static propTypes = {
    __errors: PropTypes.array,
    __showErrors: PropTypes.bool,
    __ErrorComponent: PropTypes.func,
    __PageComponent: PropTypes.func,
  }

  render() {
    const {__PageComponent, __ErrorComponent, __errors, __showErrors} = this.props

    const __is404 = !!find(__errors, ({error = {}}) => error.status === 404)

    return (
      <Fragment>
        {(__showErrors && __errors && __errors.length > 0) && (
          <__ErrorComponent errors={__errors} />
        )}

        {__is404 ? <Page404 /> : <__PageComponent {...this.props} />}
      </Fragment>
    )
  }
}

export default function pageConnector(PageComponent, props = {}) {
  const {
    asyncLoaders = [],
    mapStateToProps = () => ({}),
    mapDispatchToProps = () => ({}),
    showErrors = true/*process.env.NODE_ENV !== 'production'*/,
    withRouter: wrapWithRouter = false,
  } = props

  const componentName = getComponentName(PageComponent)

  const loadersKeys = asyncLoaders.map(({key}) => key)

  const mapStateToPropsWithErrors = (state, ownProps) => {
    return {
      ...mapStateToProps(state, ownProps),
      __errors: getErrors(state, ownProps, loadersKeys),
      __showErrors: showErrors,
      __PageComponent: PageComponent,
      __ErrorComponent: ErrorComponent,
      __reload: ReduxAsyncConnect.WrappedComponent.reload,
    }
  }

  PageConnector.displayName = `PageConnector(${componentName})`

  return asyncConnectWithoutProps(
    asyncLoaders,
    mapStateToPropsWithErrors,
    mapDispatchToProps,
  )(wrapWithRouter ? withRouter(PageConnector) : PageConnector)
}

