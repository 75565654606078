import React from 'react'
import T from 'prop-types'

import cx from 'classnames'
import css from './index.styl'
import joinValues from "app/../helpers/strings/joinValues";


let geocodingTimeoutId = null
const MOSCOW_LAT_LNG = [55.76, 37.64] // Moscow

export function getSearchText(props) {
  const {street = '', house = '', housing = '', building = '', city = '', entrance = ''} = props
  const full_house = house + (housing ? ' корпус ' + housing : '') + (building ? ' строение ' + building : '') + (entrance ? ' подъезд ' + entrance : '')
  return joinValues([city, street, full_house])
}

export default class YaMap extends React.Component {
  initialMap = false
  clickInMap = false
  placedMasterMarkers = new Map()

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const attempt = () => {
      if (!window.ymaps) {
        setTimeout(attempt, 10)
        return
      }
      this.initMap()
    }
    attempt()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.initialMap) return
    const {
      searchText,
      changeStreetList,
      masterMarkers,
      handleAddressError,
      passAddress,
      isInputActive,
      onMapUpdated,
    } = this.props

    if (masterMarkers && this.oldMasterMarkers !== masterMarkers) {
      this.setMasterMarker(masterMarkers)
      this.oldMasterMarkers = masterMarkers
      onMapUpdated(this)
    }

    if (this.oldSearchText !== searchText) {
      this.oldSearchText = searchText

      searchText && this.searchControl.search(searchText).then(() => {
        const results = this.searchControl.getResultsArray()
        if (results.length) {
          changeStreetList(
            results.map((item) => item.getAddressLine())
          )
        }
      })

      if (this.clickInMap) {
        this.clickInMap = false
        return
      }

      if (isInputActive) {
        return
      }

      if (geocodingTimeoutId) {
        clearTimeout(geocodingTimeoutId)
      }
      geocodingTimeoutId = setTimeout(() => {
        this.geoCoding({address: searchText}, (resp) => {
          const firstGeoObject = resp.geoObjects.get(0)
          const coords = firstGeoObject.geometry.getCoordinates()

          const data = this.parseAddressFromCoords(firstGeoObject.getAddressLine(), firstGeoObject.properties.get('metaDataProperty.GeocoderMetaData.Address.Components'), coords[0], coords[1])
          passAddress(data)

          this.setMarkerInMap(coords)
          this.map.setCenter(coords, this.map.getZoom(), {duration: 1000})
        }, () => {
          handleAddressError(true)
        })
      }, 750)

    }
  }

  componentWillUnmount() {
  }

  initMap = () => {
    ymaps.ready(async () => {
      const {startCoordinat, zoom, markerDisabled, forContacts, fullscreenControl} = this.props
      const _moscowCoords = startCoordinat ? [startCoordinat.lat, startCoordinat.lng] : MOSCOW_LAT_LNG
      const _fullscreenControl = fullscreenControl !== undefined ? fullscreenControl : !forContacts

      const zoomControl = new ymaps.control.ZoomControl({
        options: {
          size: "small",
          position: {
            bottom: 50,
            right: 10,
          }
        }
      });

      this.map = new ymaps.Map(document.getElementById('map'), {
        center: _moscowCoords,
        behaviors: ['multiTouch', 'drag'],
        zoom: zoom || 10,
        // controls: ['typeSelector', 'geolocationControl'],
        controls: [],
        autoFitToViewport: 'always',
      }, {
        // searchControlProvider: 'yandex#search'
      })

      this.map.controls.add(zoomControl);

      if (_fullscreenControl) {
        const fullscreenControl = new ymaps.control.FullscreenControl()
        this.map.controls.add(fullscreenControl)
      }

      if (!markerDisabled || forContacts) {
        this.marker = new ymaps.Placemark(forContacts ? _moscowCoords : MOSCOW_LAT_LNG, {}, {
          preset: 'islands#redDotIcon',
          draggable: false,
        })
        this.map.geoObjects.add(this.marker)
        // this.marker.events.add('dragend', () => {
        // })
      }

      if (!forContacts) {
        const centerControlBtn = this.centerControl()
        this.map.controls.add(centerControlBtn, {})
      }

      this.searchControl = new ymaps.control.SearchControl()

      this.oldSearchText = ''
      this.oldMasterMarkers = {}
      this.mastersList = {}

      if (startCoordinat) {
        this.map.setCenter([startCoordinat.lat, startCoordinat.lng], 10, {duration: 1000})
        if (!markerDisabled) {
          this.setMarkerInMap([startCoordinat.lat, startCoordinat.lng])
        }
      } else {
        this.map.events.add('click', this.onMapClick)
      }

      if (!forContacts) await this.initUserLocation()
      this.initialMap = true
    })
  }

  initUserLocation = async () => {
    this.infoWindow = new ymaps.Popup(this.map)
    if (!localStorage.getItem('ezhek-user-state')) {
      this.setUserGeoLocation()
    }
  }

  setUserGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async ({coords: {latitude, longitude}}) => {
          this.geoCoding({location: [latitude, longitude]}, (resp) => {
            this.addAddressInInput(resp)
            this.setMarkerInMap([latitude, longitude])
          })
          this.map.setCenter([latitude, longitude], 10, {duration: 1000})
        },
        () => {
          this.handleLocationError(true, this.infoWindow, this.map.getCenter())
        },
        {enableHighAccuracy: true},
      )
    } else {
      this.handleLocationError(false, this.infoWindow, this.map.getCenter())
    }
  }

  handleLocationError = (browserHasGeolocation, infoWindow, pos) => {
    const {infoWindowDisabled} = this.props
    infoWindow.setPosition(pos)
    infoWindow.setData(
      browserHasGeolocation ? 'Ошибка: сбой службы геолокации.' : 'Ошибка: ваш браузер не поддерживает геолокацию.'
    )
    if (!infoWindowDisabled) infoWindow.open()
  }

  onMapClick = (e) => {
    this.clickInMap = true

    const coords = e.get('coords')
    this.geoCoding({location: coords}, (resp) => {
      this.addAddressInInput(resp)
      this.setMarkerInMap(coords)
    })
    this.map.setCenter(coords)
  }

  geoCoding = (req, callback, errCb) => {
    const {location, address} = req
    ymaps.geocode(location ? location : address, {results: 1}).then(callback, errCb)
  }

  addAddressInInput = (resp) => {
    const {passAddress} = this.props

    const firstGeoObject = resp.geoObjects.get(0)
    const coords = firstGeoObject.geometry.getCoordinates()

    const data = this.parseAddressFromCoords(firstGeoObject.getAddressLine(), firstGeoObject.properties.get('metaDataProperty.GeocoderMetaData.Address.Components'), coords[0], coords[1])

    passAddress(data)
  }

  parseAddressFromCoords = (formatted_address, address_components, lat, lng) => {
    let data = {
      city: '',
      street: '',
      house: '',
      address: formatted_address,
      lon: lng,
      lat: lat,
      building: '',
      housing: '',
      entrance: '',
      entranceCode: '',
      floor: '',
      apartments: '',
    }

    address_components.map((item) => {
      switch (item.kind) {
        case 'locality':
          data.city = item.name
          break
        case 'street':
          data.street = item.name
          break
        case 'house':
          let house, housing, building

          // console.log("::", item.name)

          if (item.name.includes('к') && item.name.includes('с')) {
            [house, housing] = item.name.split('к');
            [housing, building] = housing.split('с');
          } else if (item.name.includes('к')) {
            [house, housing] = item.name.split('к')
          } else if (item.name.includes('с')) {
            [house, building] = item.name.split('с')
          } else {
            house = item.name
          }

          data.house = house
          data.housing = housing
          data.building = building
          break
        case 'entrance':
          let entrance, _prefix

          if (item.name.includes('подъезд')) {
            [_prefix, entrance] = item.name.split('подъезд')
          }

          data.entrance = entrance
          break
        default:
          break
      }
    })

    return data
  }

  setMarkerInMap = (coords) => {
    this.marker && this.marker.geometry.setCoordinates(coords)
  }

  setMasterMarker = (masters) => {
    const newIds = new Set(masters.map((m) => m.id))

    const idsToRemove = [...this.placedMasterMarkers.keys()].filter((id) => !newIds.has(id))

    idsToRemove.forEach((id) => {
      if (this.placedMasterMarkers.has(id)) {
        this.map.geoObjects.remove(this.placedMasterMarkers.get(id))
        this.placedMasterMarkers.delete(id)
      }
    })

    masters.forEach(({id, lat, lon}) => {
      const m = this.placedMasterMarkers.get(id)
      if (m) {
        m.geometry.setCoordinates([+lat, +lon])
      } else {
        const m = new ymaps.Placemark([+lat, +lon], {}, {
          iconLayout: 'default#imageWithContent',
          iconImageHref: '/assets/marker.svg',
          // iconImageSize: [39, 39],
          iconImageSize: [40, 40],
          iconImageOffset: [-20, -20],
          cursor: 'pointer',
        })
        this.placedMasterMarkers.set(id, m)
        this.map.geoObjects.add(m)
      }
    })
  }

  centerControl = () => {
    const controlLayout = ymaps.templateLayoutFactory.createClass([
      '<div>',
      '<div style="background-color: #fff; background-image: url(/assets/locate.svg); border: 2px solid #fff; cursor: pointer; margin-right: 10px; width: 40px" title="{{ data.title }}">',
      '<div style="color: rgb(25,25,25); font-family: Roboto,Arial,sans-serif; font-size: 16px; line-height: 38px; padding-left: 5px; padding-right: 5px; text-align: center;"> + ',
      '</div>',
      '</div>',
      '</div>',
    ].join(''))

    const controlBtn = new ymaps.control.Button({
      data: {
        title: 'Click to recenter the map',
      },
      options: {
        layout: controlLayout,
        position: {
          bottom: 150,
          right: 1,
        }
      },
    })

    controlBtn.events.add('click', (e) => {
      this.setUserGeoLocation()
    })

    return controlBtn
  }

  render() {
    const {className} = this.props

    return <div className={cx(css.map, className)} id={'map'}/>
  }
}

YaMap.propTypes = {
  className: T.string,
  searchText: T.string,
  masterMarkers: T.arrayOf(
    T.shape({
      first_name: T.string,
      id: T.number,
      last_name: T.string,
      lat: T.string,
      lon: T.string,
      on_work: T.bool,
      phone: T.string,
    })
  ),
  passAddress: T.func,
  changeStreetList: T.func,
  handleAddressError: T.func,
  isInputActive: T.bool,
  onMapUpdated: T.func,
}
YaMap.defaultProps = {
  className: '',
  searchText: '',
  masterMarkers: [],
  passAddress: () => {
  },
  changeStreetList: () => {
  },
  handleAddressError: () => {
  },
  onMapUpdated: () => {
  },
  isInputActive: false,
}

