import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import {
  getMasterGeoMarkers,
  getDistanceMaster,
} from 'flux/actions/order'

import { generateStateToProps, generateDispatchToProps } from 'flux/utils/orderFluxGenerator'

import { selectStatus, selectStatusMessage, composeAddress } from 'flux/selectors/order'

import { selectAuthToken } from 'flux/selectors/fetch/authConfirm'

//** component
import Component from './index'

const mapStateToProps = (state) => ({
  ...generateStateToProps(state),
  address: composeAddress(state),
  status: selectStatus(state),
  statusMessage: selectStatusMessage(state),
  token: selectAuthToken(state),
})

const mapDispatchToProps = (dispatch) => ({
  ...generateDispatchToProps(dispatch),
  getMasterGeoMarkers: () => dispatch(getMasterGeoMarkers()),
  getDistanceMaster: () => dispatch(getDistanceMaster()),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
)
