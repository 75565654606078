import React, {Fragment, useState, cloneElement} from 'react'

import {
  useFloating,
  useInteractions,
  useClick,
  useRole,
  useDismiss,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  // useId,
  FloatingPortal,
  FloatingOverlay,
  FloatingFocusManager
} from "@floating-ui/react-dom-interactions";

import cx from 'classnames'
import css from './index.styl'


export function Dialog({render, open: passedOpen = false, children, className}) {
  const [open, setOpen] = useState(passedOpen);

  const {reference, floating, context} = useFloating({
    open,
    onOpenChange: setOpen
  });

  // const id = useId();

  const {getReferenceProps, getFloatingProps} = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context, {bubbles: false})
  ]);

  return (<Fragment>
    {cloneElement(children, getReferenceProps({ref: reference, ...children.props}))}
    <FloatingPortal>
      {open && (
        <FloatingOverlay
          lockScroll
          style={{
            display: "grid",
            placeItems: "center",
            background: "rgba(25, 25, 25, 0.8)"
          }}
        >
          <FloatingFocusManager context={context}>
            <div
              {...getFloatingProps({
                ref: floating,
                className,
              })}
            >
              {render({
                close: () => setOpen(false)
              })}
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </FloatingPortal>
  </Fragment>);
}

export function Tooltip({children, label, placement = "top", className, element: Element = "div"}) {
  const [open, setOpen] = useState(false);

  const {x, y, reference, floating, strategy, context} = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [offset(5), flip(), shift({padding: 8})],
    whileElementsMounted: autoUpdate
  });

  const {getReferenceProps, getFloatingProps} = useInteractions([
    useHover(context),
    useFocus(context),
    useRole(context, {role: "tooltip"}),
    useDismiss(context)
  ]);

  return (<Fragment>
    {cloneElement(
      children,
      getReferenceProps({ref: reference, ...children.props})
    )}
    {open && (
      <Element
        {...getFloatingProps({
          ref: floating,
          className: className,
          style: {
            position: strategy,
            top: y ? y : "",
            left: x ? x : ""
          }
        })}
      >
        {label}
      </Element>
    )}
  </Fragment>)
}

