function format(number, props = {}) {
  const {
    decimalPrecision = 2,
    wholePart = 3,
    sectionDelimeter = ' ',
    decimalDelimeter = ',',
  } = props

  if (typeof number === 'string') {
    number = parseFloat(number)
  }

  const regex =
    '\\d(?=(\\d{' +
    (wholePart || 3) +
    '})+' +
    (decimalPrecision > 0 ? '\\D' : '$') +
    ')'
  const n = Math.max(0, ~~decimalPrecision)
  const num =
    number === null ? '0' : (Math.round(number * Math.pow(10, n)) / Math.pow(10, n)).toFixed(n)

  return (decimalDelimeter ? num.replace('.', decimalDelimeter) : num).replace(
    new RegExp(regex, 'g'),
    '$&' + (sectionDelimeter || ','),
  )
}

const NONE_RESULT = "-"

export function getPriceFormatted(price, decimalPrecision = 2) {

  if (!price) return NONE_RESULT
  return format(price, {decimalPrecision})
}