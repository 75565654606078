import momentTimeZone from 'moment-timezone'

import {
  getFullUrl,
  prepareBody,
  prepareHeaders,
  prepareUrl,
  finishUrl,
  appendHeader,
  handleErrors,
  toJson,
} from './utils'

let store = {}

export function setStore(s = {}) {
  store = s
}

export default function(props = {}) {
  const {
    url,
    fullUrl = false,
    params,
    body,
    mode = 'cors',
    method = 'GET',
    headers = {},
    noHeaders = false,
    contentType = 'application/json',
    token,
    version = null,
  } = props

  const { getState = () => {} } = store

  const state = getState()


  if (typeof body === "object") {
    body.timezone = momentTimeZone.tz.guess(true)
  }

  const options = {
    method,
    mode,
    body: prepareBody(body, method),
    headers: prepareHeaders(headers, method, contentType, noHeaders),
  }



  let totalUrl
  totalUrl = getFullUrl({ url, fullUrl, version })
  totalUrl = prepareUrl(totalUrl, method, params)
  totalUrl = finishUrl(totalUrl)

  const fetchWrap = () => {
    if (token) {
      appendHeader(options.headers, 'AUTHORIZATION', 'Token ' + token)
    }

    return fetch(totalUrl, options)
      .then(handleErrors) // handle request error
      .then(toJson)
      .catch((error) => {
        // handle network error like timeout
        if (error.status) {
          return Promise.reject(error)
        } else {
          const errorObject = {}
          errorObject.status = null
          errorObject.message = `Network error`
          errorObject.extra = `Error: ${error.message}`

          return Promise.reject(errorObject)
        }
      })
  }

  return fetchWrap()
}
