import { createSelector } from 'reselect'
import joinValues from 'helpers/strings/joinValues'
import { selectStore } from './fetch/authConfirm'

export const selectOrder = ({ order } = {}) => order

export const selectData = createSelector(
  selectOrder,
  ({ data }) => data
)

export const selectStatus = createSelector(
  selectData,
  ({ status }) => status
)

export const selectError = createSelector(
  selectOrder,
  ({ error }) => error // may be null
)

export const selectStatusMessage = createSelector(
  selectStatus,
  (status) => {
    switch (status) {
      case 'PLACED':
        return 'Размещен'
      case 'CONFIRMED':
      case 'IN_POOL':
        return 'Подтвержден'
      case 'MASTER_FOUND':
        return 'Мастер назначен'
      case 'AWAITING_MASTER':
        return 'Ожидание мастера'
      case 'IN_WORK':
        return 'В работе'
      case 'DONE':
        return 'Завершен'
      default:
        return status
    }
  }
)

export const selectOrderField = (field, state) =>
  createSelector(
    selectData,
    (data) => data[field]
  )(state)

export const composeAddress = (state) => {
  const geoCity = selectOrderField('geo_city', state)
  const geoStreet = selectOrderField('geo_street', state)
  const geoHouse = selectOrderField('geo_house', state)
  const geoBuilding = selectOrderField('geo_building', state)
  const geoHousing = selectOrderField('geo_housing', state)
  const geoApartments = selectOrderField('geo_apartments', state)
  const geoFloor = selectOrderField('geo_floor', state)

  return joinValues([
    geoCity,
    geoStreet,
    geoHouse && `д. ${geoHouse}`,
    geoHousing && `кор. ${geoHousing}`,
    geoBuilding && `стр. ${geoBuilding}`,
    geoApartments,
    geoFloor,
  ])
}
