import React from 'react'

import pageConnector from 'app/Pages/utils/pageConnector'
import {selectOrderField} from 'flux/selectors/order'

import Footer from 'app/Elements/Footer'
import Icon from 'app/Elements/Icon'
import SmartLink from 'app/Elements/SmartLink'

import css from './index.styl'

const CodexLink = ({link: {url, title} = {}} = {}) => {
  return (
    <SmartLink to={url} className={css.linkRow}>
      <Icon name="download_kodeks" size={22} className={css.linkIcon}/>
      <div className={css.linkText}>
        {title}
      </div>
    </SmartLink>
  )
}

function Codex(props) {
  const {client_metadata: {links = {}} = {}} = props

  return (
    <div className={css.wrap}>
      <div className={css.content}>
        <div className={css.p1}>
          <div className={css.titleRow}>
            <Icon name="doc" size={25} className={css.titleIcon}/>
            <div className={css.title}>ОБЩИЙ КОДЕКС</div>
          </div>
          <div className={css.linksWrap}>
            <CodexLink link={ { url: 'services', title: 'УСЛУГИ ЕЖЭК' } } />
            <CodexLink link={ { url: 'tariffs', title: 'ТАРИФЫ ЕЖЭК' } } />
            <CodexLink link={links['license_agreement_for_use_of_the_ezhek_program_for_mobile_devices']}/>
            <CodexLink link={ { url: 'AgreementTerms', title: 'СОГЛАШЕНИЕ ОБ УСЛОВИЯХ ИСПОЛЬЗОВАНИЯ СЕРВИСА ЕЖЭК, ПРАВИЛА ПОКУПКИ' } } />
            <CodexLink link={ { url: 'PrivacyPolicy', title: 'ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ' } } />
            <CodexLink link={ { url: 'tariffspolicies', title: 'ОСНОВЫ ТАРИФНОЙ ПОЛИТИКИ ЕЖЭК' } } />
            <CodexLink link={ { url: 'standards', title: 'СТАНДАРТЫ ЕЖЭК ПО ОБСЛУЖИВАНИЮ ЗАКАЗЧИКОВ' } } />
            <CodexLink link={links['contract_customer_master']}/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

const mapStateToProps = (state) => ({
  client_metadata: selectOrderField('client_metadata', state),
})

export default pageConnector(Codex, {
  mapStateToProps,
})
