import authConfirmRequest from 'fetch/requests/authConfirm'
import { generateActionFunc, generateActionClearErrorFunc } from './_generator'

const PREFIX = 'auth'
const request = ({ phone, code }) => authConfirmRequest({ phone, code })

export const types = {
  AUTH_CONFIRM_START: `${PREFIX}/AUTH_CONFIRM_START`,
  AUTH_CONFIRM_SUCCESS: `${PREFIX}/AUTH_CONFIRM_SUCCESS`,
  AUTH_CONFIRM_FAIL: `${PREFIX}/AUTH_CONFIRM_FAIL`,
  AUTH_CONFIRM_CLEAR_ERROR: `${PREFIX}/AUTH_CONFIRM_CLEAR_ERROR`,

  AUTH_RESET: `${PREFIX}/AUTH_RESET`,
}

export const authConfirm = generateActionFunc(
  {
    START: types.AUTH_CONFIRM_START,
    SUCCESS: types.AUTH_CONFIRM_SUCCESS,
    FAIL: types.AUTH_CONFIRM_FAIL,
  },
  request
)

export const authReset = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.AUTH_RESET,
    })
  }
}

export const clearAuthConfirmErrors = generateActionClearErrorFunc({
  CLEAR_ERROR: types.AUTH_CONFIRM_CLEAR_ERROR,
})