import React from 'react'
import PropTypes from 'prop-types'


export default function InfoIcon(props) {
    const {className, size, color, ...restProps} = props

    return (
        <svg fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={size} height={size} className={className} {...restProps}>
            <path d="M 12 2 C 6.4666667 2 2 6.4666667 2 12 C 2 17.533333 6.4666667 22 12 22 C 17.533333 22 22 17.533333 22 12 C 22 6.4666667 17.533333 2 12 2 z M 12 4 C 16.466667 4 20 7.5333333 20 12 C 20 16.466667 16.466667 20 12 20 C 7.5333333 20 4 16.466667 4 12 C 4 7.5333333 7.5333333 4 12 4 z M 11 8 L 11 10 L 13 10 L 13 8 L 11 8 z M 11 12 L 11 18 L 13 18 L 13 12 L 11 12 z"/>
        </svg>
    )
}

InfoIcon.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(['16', '24', '48', '96', '192']),
    color: PropTypes.string,
}

InfoIcon.defaultProps = {
    size: '24',
    color: '#000000'
}
