import React, {useEffect} from 'react'
import {withRouter} from 'react-router'
import authFast from "fetch/requests/authFast"

import {types} from 'flux/actions/fetch/authConfirm'
import {connect} from "react-redux"

function OrderIn(props) {
    const {
        fakeAuth,
        match: {params: {fastauthtoken} = {}} = {},
    } = props

    useEffect(() => {
        if (fastauthtoken) {
            authFast(fastauthtoken)
                .then(data => {
                    const {order_id, token} = data
                    fakeAuth({ token })
                    window.location.replace(`/order/${order_id}`)
                })
                .catch(() => {
                    window.location.replace("/")
                })
        }
    }, [])

    return null
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = dispatch => ({
    fakeAuth: data => dispatch({payload: { data }, type: types.AUTH_CONFIRM_SUCCESS}),
})

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OrderIn)
)