import React from 'react'
import T from 'prop-types'

import Icon from 'app/Elements/Icon'

import cx from 'classnames'
import css from './index.styl'

function withCircle(Component, props) {
  const {circleCl} = props

  return (
    <span className={cx(css.iconCircle, circleCl)}>
      {Component}
    </span>
  )
}

export default function InfoMessage(props) {
  const {
    message,
    iconName,
    size,
    shouldShowCircle,
    className,
    iconCl,
    circleCl,
    isRequiredLabel,
    ...iconProps
  } = props

  const IconComponent = Boolean(iconName)
    ? (
      <Icon
        name={iconName}
        size={size}
        className={cx(css.icon, iconCl)}
        {...iconProps}
      />
    ) : null

  return (
    <div className={cx(css.wrap, className)}>
      {shouldShowCircle
        ? withCircle(IconComponent, props)
        : IconComponent
      }
      {message}
      {isRequiredLabel && <div className={css.labelRequired}>{`* `}</div>}
    </div>    
  )
}

InfoMessage.propTypes = {
  message: T.node.isRequired,
  shouldShowCircle: T.bool,
  size: T.number,
  iconName: T.string,
  iconCl: T.string,
  className: T.string,
  circleCl: T.string,
}
InfoMessage.defaultProps = {
  shouldShowCircle: true,
  size: 16,
  iconCl: '',
  className: '',
  circleCl: '',
}
