import React, { createContext } from 'react'
import T from 'prop-types'

import omit from 'lodash/omit'

import cx from 'classnames'
import css from './index.styl'

const RadioContext = createContext()

const propsToOmit = ['isCustom', 'customInputCl', 'customDotCl', 'labelCl']

export default function Radio(props) {
  const {
    value,
    label,
    isCustom = true,
    customInputCl,
    customDotCl,
    labelCl,
  } = props

  return (
    <RadioContext.Consumer>
      {({name, selectedValue, onChange, className} = {}) => {
        const optional = {}
        const nativeProps = omit(props, propsToOmit)

        const radioCl = cx(css.radio, {[css.withLabel]: label})
        const labelWrapCl = cx({
          [css.labelWrap]: true,
          [className]: className,
        })
        const labelClassName = cx({
          [css.label]: true,
          [css.withCustom]: isCustom,
          [labelCl]: labelCl,
        })

        if (selectedValue !== undefined) {
          optional.checked = (value === selectedValue)
        }

        if (typeof onChange === 'function') {
          optional.onChange = onChange.bind(null, props.value)
        }

        const Wrap = ({children}) => label
          ? <label className={labelWrapCl}>
              {children}
              <span className={labelClassName}>{label}</span>
            </label>
          : children

        const customDotClassName = cx({
          [css.customDot]: true,
          [css.checked]: optional.checked,
          [customDotCl]: customDotCl,
        })

        const customWrapClassName = cx({
          [css.customWrap]: true,
          [customInputCl]: customInputCl,
        })

        return (
          <Wrap>
            {isCustom && (
              <div {...nativeProps} className={customWrapClassName}>
                <div className={customDotClassName} />
              </div>
            )}
            <input
              {...nativeProps}
              aria-checked={optional.checked}
              role="radio"
              type="radio"
              name={name}
              className={radioCl}
              {...optional}
            />
          </Wrap>
        )
      }}
    </RadioContext.Consumer>
  )
}

export function RadioGroup(props) {
  const {
    name,
    selectedValue,
    onChange,
    children,
    className,
  } = props

  const cl = cx({
    [className]: className,
  })

  const context = {
    name,
    selectedValue,
    onChange,
    className: cl,
  }

  return (
    <RadioContext.Provider value={context}>
      {children}
    </RadioContext.Provider>
  )
}

RadioGroup.propTypes = {
  name: T.string,
  selectedValue: T.oneOfType([
    T.string,
    T.number,
    T.bool,
  ]),
  onChange: T.func,
  children: T.node.isRequired,
  label: T.oneOfType([T.string, T.node]),
  className: T.string,
}
