import fetch from 'fetch'

export default ({ body, token }) => {
  return fetch({
    url: '/client/order/place/',
    version: 'v2',
    method: 'POST',
    body: { ...body },
    token,
  })
}
