import React, {useEffect} from 'react'

import Footer from 'app/Elements/Footer'
import ParagraphBlock from 'app/Elements/ParagraphBlock'
import SmartLink from 'app/Elements/SmartLink'
import Button from 'app/Elements/Button'

import css from './index.styl'

const p1Title = 'УСЛУГИ ЕЖЭК'
const p1Items = [
  'Состав услуг ЕЖЭК разработан специально таким образом, чтобы позволить ежедневно пользоваться услугами ЕЖЭК горожанам и жителям пригородов.',
]

// const p2Title = 'I. Приветливость:'
const p2Subtitle = 'Услуги ЕЖЭК подразделяются на следующие группы (в скобках указаны названия услуг в Приложении ЕЖЭК):'
const p2Items = [
  'Услуги по установке, снятию и ремонту сантехники и сантехнических коммуникаций («Починить», «Установить» или «Собрать»);',
  'Услуги по установке, демонтажу и ремонту электрической проводки, выключателей, розеток, осветительных, отопительных и других электрических приборов («Починить», «Установить» или «Собрать»);',
  'Услуги мелкого косметического ремонта помещений, включая малярные, плиточные и другие работы («Починить» или «Установить»);',
  'Услуги по сборке/ разборке и монтажу/ демонтажу, погрузке/ переноске мебели, полок, предметов декора («Установить», «Собрать» или «Переставить»);',
  'Услуги по уборке помещений, включая мытьё окон, духовок, микроволновок, холодильников, радиаторов, жалюзи и других трудоемких видов уборки труднодоступных и особо загрязненных мест («Убрать»);',
  'Услуги по уходу за комнатными и садовыми растениями, включая посадку/ пересадку/ удаление, обрезку, подкормку, полив, сезонное укрытие/ раскрытие, а также уборке придомовых территорий, включая мойку/ чистку/ гидрофобизацию (защиту от воздействия атмосферных осадков) мощения, фасадов частных домов, бассейнов, прудов и других водоемов, мелкий ремонт облицовки из плитки, настилов и конструкций из натурального и искусственного дерева, покраску отливов и отвесов и другие подобные работы разнорабочего («Убрать» или «Посадить»).',
]

const p3Items = [
  'Для обеспечения высокого качества работы каждый из мастеров ЕЖЭК <u>специализируется</u> в одном из указанных направлений. Однако, так как в быту часто нужны разнообразные услуги, а заказчики предпочитают одного исполнителя, многие мастера ЕЖЭК владеют несколькими специальностями. Некоторые мастера являются специалистами широкого профиля, способными выполнить каждую из перечисленных услуг. ЕЖЭК обеспечивает подбор оптимального мастера с необходимым набором навыков исходя из задачи, описанной Заказчиком при размещении заказа.'
]

export default function CodexServices(props) {
  useEffect(() => {
    document.title = 'УСЛУГИ ЕЖЭК'
  }, [props.title])

  return (
    <div className={css.wrap}>
      <div className={css.content}>
        <ParagraphBlock title={p1Title} items={p1Items} className={css.p1} />
        <ParagraphBlock subtitle={p2Subtitle} items={p2Items} itemsByList />
        <ParagraphBlock items={p3Items} />
      </div>
      <Footer />
    </div>
  )
}
