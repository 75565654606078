import React from 'react'

import SmartLink from 'app/Elements/SmartLink'

import css from './index.styl'

const shareTextTitle = 'ЕЖэк – это ЖЭК в твоём телефоне.'
const shareText = 'ЕЖэк – это ЖЭК в твоём телефоне. Вызвать мастера так же просто, как заказать такси.'
const shareTextLink = encodeURI('https://ezhek.ru')

const links = {
  sms: 'sms:?body=' + shareText + ' ' + shareTextLink,
  email: 'mailto:?subject=' + shareTextTitle + '&body=' + shareText + ' ' + shareTextLink,
  whatsapp: 'https://api.whatsapp.com/send?text=' + shareText + ' ' + shareTextLink,
  messenger: 'fb-messenger://share/?link=' + shareTextLink,
  viber: 'viber://forward?text=' + shareText + ' ' + shareTextLink,
  vk: 'http://vk.com/share.php?url=' + shareTextLink + '&title=' + shareTextTitle + '&comment=' + shareText,
  telegram: 'https://t.me/share/url?url=' + shareTextLink + '&text=' + shareText,
  fb: 'http://www.facebook.com/sharer/sharer.php?u=' + shareTextLink,
}

import message from './assets/message.png'
import email from './assets/email.png'
import whatsapp from './assets/whatsapp.png'
import fbmessenger from './assets/fbmessenger.png'
import viber from './assets/viber.png'
import vkontakte from './assets/vkontakte.png'
import telegram from './assets/telegram.png'
import facebook from './assets/facebook.png'

export default function ShareBlock(props) {

  return (
    <div className={css.block}>
      <div className={css.title}>
        Расскажите о нас
        <br />
        Вашим друзьям!
      </div>
      <div className={css.buttons}>
        <div className={css.buttonsRow}>
          <SmartLink className={css.button} to={links.sms} target="_blank" rel="noopener" aria-label="Share on Facebook">
            <img src={message} />
          </SmartLink>
          <SmartLink className={css.button} to={links.email} target="_blank" rel="noopener" aria-label="Share by E-mail">
            <img src={email} />
          </SmartLink>
          <SmartLink className={css.button} to={links.whatsapp} target="_blank" rel="noopener" aria-label="Share on WhatsApp">
            <img src={whatsapp} />
          </SmartLink>
          <SmartLink className={css.button} to={links.messenger} target="_blank" rel="noopener" aria-label="Share on Facebook Messenger">
            <img src={fbmessenger} />
          </SmartLink>
        </div>
        <div className={css.buttonsRow}>
          <SmartLink className={css.button} to={links.viber} target="_blank" rel="noopener" aria-label="Share on Viber">
            <img src={viber} />
          </SmartLink>
          <SmartLink className={css.button} to={links.vk} target="_blank" rel="noopener" aria-label="Share on VK">
            <img src={vkontakte} />
          </SmartLink>
          <SmartLink className={css.button} to={links.telegram} target="_blank" rel="noopener" aria-label="Share on Telegram">
            <img src={telegram} />
          </SmartLink>
          <SmartLink className={css.button} to={links.fb} target="_blank" rel="noopener" aria-label="Share on Facebook">
            <img src={facebook} />
          </SmartLink>
        </div>
      </div>
    </div>
  )
}
