import React, {Fragment, useState, useEffect} from 'react'
import T from 'prop-types'

import Input from 'app/Elements/Input'
import Icon from 'app/Elements/Icon'
import Button from 'app/Elements/Button'
import SmartLink from 'app/Elements/SmartLink'
import Checkbox from 'app/Elements/Checkbox'
import Attention from 'app/Components/Attention'

import {toPhonePattern, phonePlaceholder} from 'helpers/form/mask'

import cap from 'helpers/strings/capitalizeFirstLetter'

import cx from 'classnames'
import css from './index.styl'

const PHONE_LENGTH = 11

const errors = {
  SHORT_PHONE: 'Введите, пожалуйста, номер телефона полностью',
  INVALID_PHONE: 'Неправильно указан номер',
}

// smart
function handleAuthRequest(state, props) {
  return async function authRequestHandler() {
    const {requestAuth} = props
    const {phone, handleWasCodeSent, phoneError, handleIsLoading} = state

    handleIsLoading(true)
    let isRequestError = false

    await requestAuth({phone}).catch(error => {
      handleIsLoading(false)

      isRequestError = true

      if (phoneError) {
        return
      }

      if ((error.extra || {}).phone) {
        return handleError('phone', state)(errors.INVALID_PHONE)
      }

      if ((error.extra || {}).detail) {
        return handleError('phone', state)(error.extra.detail)
      } else {
        return handleError('phone', state)(error.message)
      }
    })

    handleIsLoading(false)
    !isRequestError && handleWasCodeSent(true)
  }
}

function handleOrderMaster(state, props) {
  return async function masterOrderHandler() {
    const {phone, code, handleIsLoading} = state
    const {confirmAuth} = props
    let isRequestError = false

    handleIsLoading(true)

    await confirmAuth({code, phone}).catch(() => {
      isRequestError = true

      handleIsLoading(false)
    })

    if (!isRequestError) {
      location.reload()
    }
  }
}

function handleInput(key, state) {
  return function handler(nextValue) {
    const inputHandler = state[`handle${cap(key)}`]
    const isError = state[`${key}Error`]
    const errorHandler = state[`handle${cap(key)}Error`]

    if (isError && errorHandler) errorHandler('')
    inputHandler(nextValue)
  }
}

function handleError(key, state) {
  return function handler(nextError) {
    const isError = state[`${key}Error`]
    const errorHandler = state[`handle${cap(key)}Error`]

    if (isError || !errorHandler) return
    errorHandler(nextError)
  }
}

const validators = {
  isEmpty: function(state, _, {key}) {
    const value = state[key]
    if (!value) return errors[`NO_${key.toUpperCase()}`]
  },
  isValidPhone: function(state) {
    const key = 'phone'
    const value = state[key]
    if (value.length < PHONE_LENGTH) return errors['SHORT_PHONE']
  },
}

const formValues = {
  phone: {
    initialState: '',
    errorRules: {
      isEmpty: validators.isEmpty,
      isValidPhone: validators.isValidPhone,
    },
  },
}

function useForm(props) {
  const state = {}
  const handlers = {}

  Object.keys(formValues).forEach(name => {
    const {initialState, errorRules = {}} = formValues[name]

    const stateField = useState(
      typeof initialState === 'function' ? initialState(props) : initialState,
    )
    state[name] = stateField[0]
    handlers[`handle${cap(name)}`] = stateField[1]

    if (Object.keys(errorRules).length > 0) {
      const stateErrorField = useState('')
      state[`${name}Error`] = stateErrorField[0]
      state[`handle${cap(name)}Error`] = stateErrorField[1]
    }
  })

  return [state, handlers]
}

function useControls() {
  const [isAgree, handleIsAgree] = useState(false)
  const [wasCodeSent, handleWasCodeSent] = useState(false)
  const [code, handleCode] = useState('')
  const [isLoading, handleIsLoading] = useState(false)
  const [isAuthRequestDisabled, handleIsAuthRequestDisabled] = useState(true)

  const state = {
    isAgree,
    wasCodeSent,
    code,
    isLoading,
    isAuthRequestDisabled,
  }

  const handlers = {
    handleIsAgree,
    handleWasCodeSent,
    handleCode,
    handleIsLoading,
    handleIsAuthRequestDisabled,
  }

  return [state, handlers]
}

function useOrder(props) {
  const [formState, formHandlers] = useForm(props)
  const [controlsState, controlsHandlers] = useControls()

  const state = {...formState, ...controlsState}
  const handlers = {...formHandlers, ...controlsHandlers}
  const {street, house, apartments, description, phone, isAgree} = state
  const {handleIsAuthRequestDisabled} = handlers

  useEffect(() => {
    isAgree && phone
      ? handleIsAuthRequestDisabled(false)
      : handleIsAuthRequestDisabled(true)
  }, [isAgree, street, house, apartments, description, phone])

  return [state, handlers]
}

// dumb
function renderWasCodeSentSection(state, props) {
  const {confirmationCode, authError} = props
  const {code, handleCode, isLoading} = state
  const isOrderMasterDisabled = (code || '').length !== 4

  return (
    <Fragment>
      <div className={css.code}>
        <div className={css.codeLabel}>Введите код</div>
        <Input
          className={css.codeWrap}
          inputClassName={css.codeInput}
          value={code}
          handleInput={handleCode}
        />
      </div>

      <div className={cx(css.notice, css.hint)}>
        {confirmationCode && `Подсказка: ${confirmationCode}`}
        {!!authError && <div className={css.errorSms}>Неверный код</div>}
      </div>
      <Button
        theme={'green'}
        disabled={isOrderMasterDisabled}
        className={css.callMasterButton}
        onClick={handleOrderMaster(state, props)}
        isBusy={isLoading}
      >
        Отправить код
      </Button>
    </Fragment>
  )
}

renderWasCodeSentSection.propTypes = {
  authError: T.object,
  confirmationCode: T.string,
}

export default function AuthorizationSms(props) {
  const [orderState, orderHandlers] = useOrder(props)
  const state = {...orderState, ...orderHandlers}

  const {
    isAgree,
    isLoading,
    wasCodeSent,
    handleIsAgree,
    isAuthRequestDisabled,
  } = state

  return (
    <Fragment>
      <div className={css.header}>
        <div className={css.headerIcon}>
          <Icon name="personFilled" width={11} height={14} size={0} />
        </div>
        Для доступа к заказу необходимо авторизоваться
      </div>

      <Input
        className={css.wideInputWrap}
        inputClassName={css.wideInput}
        placeholder={phonePlaceholder}
        handleInput={handleInput('phone', state)}
        value={toPhonePattern(state['phone'])}
        error={state[`phoneError`]}
        label={'Телефон'}
        name={'phone'}
        isRequiredLabel
        id={'phone'}
      />

      <Checkbox
        name={'isAgreeWithTerms'}
        checked={isAgree}
        handleChange={handleIsAgree}
        className={css.privacyCheckbox}
        label={
          <Fragment>
            Я согласен с&nbsp;
            <SmartLink
              theme={'dark'}
              isUnderlined
              to={'/codex'}
              className={css.privacyLink}
            >
              общими условиями деятельности&nbsp;
            </SmartLink>
            ЕЖэка и на обработку моих персональных данных
          </Fragment>
        }
      />

      <Button
        theme={'green'}
        className={css.sendCodeButton}
        disabled={isAuthRequestDisabled}
        // disabled={isAuthRequestDisabled || GLOBALS.ENV_PRESET === 'production'}
        // style={GLOBALS.ENV_PRESET === 'production' ? {pointerEvents: "none"} : {}}
        onClick={handleAuthRequest(state, props)}
        isBusy={!wasCodeSent && isLoading}
      >
        Получить код
      </Button>

      {wasCodeSent && renderWasCodeSentSection(state, props)}

      <Attention className={css.attention}>
        На ваш номер будет отправлено СМС с кодом подтверждения
      </Attention>

      <div className={css.mainPage}>
        <SmartLink to={'/'} theme={'green'} isUnderlined className={css.link}>
          На главную
        </SmartLink>
      </div>
    </Fragment>
  )
}

AuthorizationSms.propTypes = {
  requestAuth: T.func,
  confirmAuth: T.func,
}
