import React from 'react'

import Footer from 'app/Elements/Footer'
import ParagraphBlock from 'app/Elements/ParagraphBlock'
import SmartLink from 'app/Elements/SmartLink'
import Button from 'app/Elements/Button'

import ReviewFeed from 'app/Components/ReviewFeed/index.connector'

import css from './index.styl'

const p1Highlighted = 'Онлайн сервисов много, а заказы один дальше другого? Надоели долгие переговоры с заказчиками о времени выполнения и цене? Не хочется отдавать львиную долю заработанного посредникам?'
const p1Items = [
  '<b>Тогда ЕЖЭК – для вас! Он создан для тех мастеров, которые хотят получать справедливую плату за хорошо выполненную работу без потери времени на разъезды и переговоры.</b>',
]

const p2Title = 'Почему делать заказы через нас выгодно?'
const p2Items = [
  'Оплата работы по фиксированной почасовой ставке с вычетом минимальных комиссионных.',
  'Заказчики в вашем районе.',
  'Профессиональная помощь более опытных коллег – экспертов.',
  'При необходимости — поддержка службы безопасности и урегулирования споров.',
]

const p3Title = 'Как это работает?'
const p3Items = [
  'Вы проходите документальную проверку, собеседование и тестирование с экспертами ЕЖЭК',
  'Устанавливаете мобильное приложение ЕЖЭК',
  'Активируете статус "Готов работать"',
  'Получаете описание заказа с адресом',
  'Соглашаетесь на заказ и отправляетесь к заказчику',
  'Выполняете работу, сходив при необходимости за дополнительными материалами в ближайший магазин',
  'По окончании работы заказчик оплачивает фактически затраченное вами время; вы можете дать скидку или включить "чаевые"',
  'Получаете оплату за вычетом нашей комиссии; с чаевых комиссия не взимается',
  'Выставляете оценку заказчику, которая поможет нам мониторить условия оказания',
  'В конце месяца мы поможем вам оформить документы для налоговой отчетности. Все мастера ЕЖЭК работают легально, они — зарегистрированные "самозанятые" или индивидуальные предприниматели',
]

const p4Title = 'Кто может быть мастером ЕЖЭК?'
const p4Items = [
  'Профессионалы в одной или нескольких специальностях ЕЖЭК (мужчины и женщины) в возрасте – от 21 года, имеющие опыт по специальности не менее 2-х лет.',
  'Без медицинских противопоказаний.',
  'Граждане России, Беларуси, Казахстана и других стан таможенного союза.',
  'Без судимости и учета в ПНД.',
  'Без склонности к алкоголю и наркотикам.',
  'Готовые неукоснительно соблюдать законодательство РФ и правила оказания услуг ЕЖЭК.',
]

const p5Title = 'Что необходимо сделать, чтобы начать работу'
const p5Items = [
  <Button
    asLink
    noBlank
    href={'/masters/request/'}
    theme={'green'}
    className={css.button}
    contentClassName={css.buttonText}
  >
    Заполнить заявку
  </Button>,
  <span>Один из наших комендантов свяжется с вами в течение 24-х часов.</span>
]

const p6Title = 'Не верьте нам на слово:'
const p6Items = [
  'Читайте отзывы мастеров, которым ЕЖЭК уже сэкономил время на дорогу к заказам и переговоры и расчёты с заказчиками:',
]

export default function Masters(props) {

  return (
    <div className={css.wrap}>
      <div className={css.content}>
        <ParagraphBlock title={p5Title} items={p5Items} itemsNoInner className={css.p1}/>
        <ParagraphBlock highlighted={p1Highlighted} items={p1Items}/>
        <ParagraphBlock title={p2Title} items={p2Items}/>

        <ParagraphBlock title={p6Title} items={p6Items}/>
        <ParagraphBlock items={[<ReviewFeed masters/>]} itemsNoInner/>

        <ParagraphBlock title={p3Title} items={p3Items} itemsByList/>

        <iframe className={css.video} src="https://www.youtube.com/embed/uq4jIqixyLA" frameBorder="0" allowFullScreen/>

        <ParagraphBlock title={p4Title} items={p4Items}/>
      </div>
      <Footer/>
    </div>
  )
}
